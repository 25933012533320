import React, {
    useEffect,
    useState,
    useCallback,
    useRef,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import { usePostRequestSyncPromise } from "../../global/GlobalFetch";
import ReactPaginate from 'react-paginate';
import Slider from 'react-slick';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Select from 'react-select';
import { FaTimes } from 'react-icons/fa';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './TvShow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faTv,
    faHistory,
    faCheckCircle,
    faTimesCircle,
    faExclamationTriangle,
    faInfoCircle,
    faTimes,
    faArrowLeft,
    faStar,
    faPlay,
    faClosedCaptioning,
    faChevronLeft,
    faChevronRight,
    faSpinner,
    faClose
} from '@fortawesome/free-solid-svg-icons';
import * as signalR from '@microsoft/signalr';
import { toast } from 'react-toastify';
import { CONFIG } from '../../CONFIG';
import useIsMobile from '../../components/mobile/IsMobile';
import { UserContext } from '../../App';

/* Toast Fonksiyonları */
const showSuccessToast = (message) => {
    toast.dark(
        <div>
            <FontAwesomeIcon icon={faCheckCircle} />
            {message}
        </div>
    );
};
const showErrorToast = (message) => {
    toast.dark(
        <div>
            <FontAwesomeIcon icon={faTimesCircle} />
            {message}
        </div>
    );
};
const showWarningToast = (message) => {
    toast.dark(
        <div>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            {message}
        </div>
    );
};
const showInfoToast = (message) => {
    toast.dark(
        <div>
            <FontAwesomeIcon icon={faInfoCircle} />
            {message}
        </div>
    );
};

/* USER HISTORY POPUP */
const UserHistoryPopup = ({
    isOpen,
    toggle,
    allUserNames,
    allUsersHistory,
    setPopupVisible,
    setSelectedTvShow,
    postRequestSyncPromise,
    setAllUsersHistory,
    isMobile
}) => {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [userPages, setUserPages] = useState({});
    const [localHistories, setLocalHistories] = useState([]);
    const HISTORIES_PER_PAGE = 8;

    useEffect(() => {
        setLocalHistories(allUsersHistory);
        console.log("ALL USERS HISTORY : ", allUsersHistory);
    }, [allUsersHistory]);

    const handleUserChange = (selectedOptions) => {
        setSelectedUsers(selectedOptions ? selectedOptions.map(option => option.value) : []);
        setUserPages({});
    };

    const handlePageChange = (user, newPage) => {
        setUserPages(prevPages => ({
            ...prevPages,
            [user]: newPage
        }));
    };

    const handleCardClick = (tvShow) => {
        setSelectedTvShow(tvShow);
        setPopupVisible(true);
    };

    const handleDeleteHistory = (tvShowDetail, user) => {
        const userCode = localStorage.getItem('userCode');

        const baseRequest = {
            data: [{
                userName: userCode,
                id: tvShowDetail.id,
                historyType: 0
            }]
        };

        postRequestSyncPromise('UserHistory/DeleteUserHistory', baseRequest)
            .then(data => {
                if (data.type === 0) {
                    // Silme başarılı ise, gerisini SignalR handle edecek
                }
            })
            .catch(error => {
                console.error('Error deleting history:', error);
            });
    };

    /* Masaüstü versiyonu */
    const renderDesktopVersion = () => {
        const userHistories = selectedUsers.map(user => {
            const histories = localHistories
                .filter(history => history.owner === user && history.historyType === 0)
                .sort((a, b) => {
                    const dateA = new Date(a.updatedDate || a.createdDate);
                    const dateB = new Date(b.updatedDate || b.createdDate);
                    return dateB - dateA;
                });

            const totalPages = Math.ceil(histories.length / HISTORIES_PER_PAGE);
            const currentPage = userPages[user] || 1;

            const currentHistory = histories.slice(
                (currentPage - 1) * HISTORIES_PER_PAGE,
                currentPage * HISTORIES_PER_PAGE
            );

            return {
                user,
                history: currentHistory.map(history => history.tvShowDetail),
                totalPages,
                currentPage
            };
        });

        return (
            <CSSTransition in={isOpen} timeout={300} classNames="modal-animation" unmountOnExit>
                <Modal isOpen={isOpen} toggle={toggle} size="xl" centered className="custom-modal">
                    <ModalBody className="modal-body-custom">
                        <div className="sticky-select">
                            <Select
                                options={allUserNames.map(user => ({ value: user, label: user }))}
                                onChange={handleUserChange}
                                placeholder="Select users..."
                                isClearable
                                isMulti
                                styles={customStyles}
                                classNamePrefix="select"
                            />
                        </div>
                        <TransitionGroup component={Row} className="history-content">
                            {userHistories.map(({ user, history, totalPages, currentPage }) => (
                                <CSSTransition key={user} timeout={300} classNames="fade">
                                    <Col xs="12">
                                        <h5 className="history-header">
                                            {user}'s History
                                        </h5>
                                        {history.length > 0 ? (
                                            <div>
                                                <TransitionGroup component={Row}>
                                                    {history.map((tvShowDetail, idx) => (
                                                        <CSSTransition
                                                            key={`${user}-${idx}`}
                                                            timeout={300}
                                                            classNames="fade"
                                                        >
                                                            <Col xs="12" sm="6" md="4" lg="3" className="mb-3">
                                                                <div className="movie-card-wrapper">
                                                                    {user === localStorage.getItem('userCode') && (
                                                                        <TvShowCard
                                                                            tvShow={tvShowDetail}
                                                                            onClick={handleCardClick}
                                                                            onDelete={() => handleDeleteHistory(tvShowDetail, user)}
                                                                            isMobile={false}
                                                                        />
                                                                    )}

                                                                    {user !== localStorage.getItem('userCode') && (
                                                                        <TvShowCard
                                                                            tvShow={tvShowDetail}
                                                                            onClick={handleCardClick}
                                                                            isMobile={false}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </CSSTransition>
                                                    ))}
                                                </TransitionGroup>
                                                {totalPages > 1 && (
                                                    <Pagination className="justify-content-center custom-pagination">
                                                        <PaginationItem
                                                            className="animated-pagination-item"
                                                            disabled={currentPage === 1}
                                                        >
                                                            <PaginationLink
                                                                className="pagination-button"
                                                                previous
                                                                onClick={() => handlePageChange(user, currentPage - 1)}
                                                            />
                                                        </PaginationItem>
                                                        {[...Array(totalPages)].map((_, i) => (
                                                            <PaginationItem
                                                                active={i + 1 === currentPage}
                                                                key={`${user}-page-${i}`}
                                                                className={
                                                                    i + 1 === currentPage
                                                                        ? "active-page"
                                                                        : "animated-pagination-item"
                                                                }
                                                            >
                                                                <PaginationLink
                                                                    className="pagination-button"
                                                                    onClick={() => handlePageChange(user, i + 1)}
                                                                >
                                                                    {i + 1}
                                                                </PaginationLink>
                                                            </PaginationItem>
                                                        ))}
                                                        <PaginationItem
                                                            className="animated-pagination-item"
                                                            disabled={currentPage === totalPages}
                                                        >
                                                            <PaginationLink
                                                                className="pagination-button"
                                                                next
                                                                onClick={() => handlePageChange(user, currentPage + 1)}
                                                            />
                                                        </PaginationItem>
                                                    </Pagination>
                                                )}
                                            </div>
                                        ) : (
                                            <p className="no-history-message">No history available for {user}.</p>
                                        )}
                                    </Col>
                                </CSSTransition>
                            ))}
                        </TransitionGroup>
                    </ModalBody>
                </Modal>
            </CSSTransition>
        );
    };

    /* Mobil versiyonu */
    const renderMobileVersion = () => {
        const userHistories = selectedUsers.map(user => {
            const histories = localHistories
                .filter(history => history.owner === user && history.historyType === 0)
                .sort((a, b) => {
                    const dateA = new Date(a.updatedDate || a.createdDate);
                    const dateB = new Date(b.updatedDate || b.createdDate);
                    return dateB - dateA;
                });

            const totalPages = Math.ceil(histories.length / HISTORIES_PER_PAGE);
            const currentPage = userPages[user] || 1;

            const currentHistory = histories.slice(
                (currentPage - 1) * HISTORIES_PER_PAGE,
                currentPage * HISTORIES_PER_PAGE
            );

            return {
                user,
                history: currentHistory.map(history => history.tvShowDetail),
                totalPages,
                currentPage
            };
        });

        return (
            <div className="mobile-history-container">
                {/* Fixed Header */}
                <div className="mobile-history-header">
                    <button className="mobile-close-button" onClick={toggle}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                        <span>Watch History</span>
                    </button>

                    <div className="mobile-user-select">
                        <Select
                            options={allUserNames.map(user => ({ value: user, label: user }))}
                            onChange={handleUserChange}
                            placeholder="Select users..."
                            isClearable
                            isMulti
                            styles={customStyles}
                            classNamePrefix="select-mobile"
                        />
                    </div>
                </div>

                {/* Content Area */}
                <div className="mobile-history-content">
                    {userHistories.map(({ user, history, totalPages, currentPage }) => (
                        <div key={user} className="mobile-user-history">
                            <h3 className="mobile-user-title">{user}'s History</h3>

                            {history.length > 0 ? (
                                <div className="mobile-history-grid">
                                    {history.map((tvShowDetail, idx) => (
                                        <div key={`${user}-${idx}`} className="mobile-history-item">
                                            <TvShowCard
                                                tvShow={tvShowDetail}
                                                onClick={handleCardClick}
                                                onDelete={user === localStorage.getItem('userCode') ?
                                                    () => handleDeleteHistory(tvShowDetail, user) : undefined}
                                                isMobile={true}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="mobile-empty-state">
                                    <FontAwesomeIcon icon={faHistory} size="2x" />
                                    <p>No history available</p>
                                </div>
                            )}

                            {/* Bottom Pagination */}
                            {totalPages > 1 && (
                                <div className="mobile-pagination">
                                    <button
                                        className="mobile-page-button"
                                        disabled={currentPage === 1}
                                        onClick={() => handlePageChange(user, currentPage - 1)}
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                    </button>
                                    <span className="mobile-page-info">
                                        {currentPage} / {totalPages}
                                    </span>
                                    <button
                                        className="mobile-page-button"
                                        disabled={currentPage === totalPages}
                                        onClick={() => handlePageChange(user, currentPage + 1)}
                                    >
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    /* Mobil mi, masaüstü mü? */
    return isMobile ? renderMobileVersion() : renderDesktopVersion();
};

/* customStyles, mobil/desktop fark etmeden Select'e uygulanacak ortak stil */
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'transparent',
        borderColor: state.isFocused ? 'var(--glow-color)' : 'var(--border-color)',
        boxShadow: state.isFocused ? `0 0 8px var(--glow-color)` : 'none',
        '&:hover': {
            borderColor: 'var(--glow-color)',
        },
        borderRadius: '8px',
        padding: '5px',
        color: 'var(--text-color)',
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: 'var(--hover-gradient-2)',
        borderRadius: '4px',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: 'var(--text-color)',
        fontWeight: 'bold',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: 'var(--text-color)',
        '&:hover': {
            backgroundColor: 'var(--hover-gradient-1)',
            color: 'var(--text-color)',
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'rgba(255, 255, 255, 0.6)',
        fontStyle: 'italic',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'var(--glow-color)' : 'var(--text-color)',
        '&:hover': {
            color: 'var(--glow-color)',
        },
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: 'rgba(32, 43, 96, 0.9)',
        border: '1px solid var(--border-color)',
        boxShadow: `0 8px 16px var(--glow-color)`,
        borderRadius: '8px',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected
            ? 'var(--hover-gradient-2)'
            : state.isFocused
                ? 'var(--hover-gradient-1)'
                : 'transparent',
        color: 'var(--text-color)',
        '&:active': {
            backgroundColor: 'var(--primary-gradient-2)',
        },
    }),
};

/* FILTER TYPE SELECT */
const FilterTypeSelect = ({ filterType, setFilterType, isMobile }) => {
    const options = [
        { value: 'AND', label: 'AND' },
        { value: 'OR', label: 'OR' }
    ];

    /* Masaüstü versiyonu */
    const renderDesktopVersion = () => {
        return (
            <Select
                options={options}
                value={options.find(option => option.value === filterType)}
                onChange={(selectedOption) => setFilterType(selectedOption.value)}
                placeholder="Select Filter..."
                styles={customStyles}
                className="filter-type-select"
                classNamePrefix="select"
                isSearchable={false}
            />
        );
    };

    /* Mobil versiyonu */
    const renderMobileVersion = () => {
        return (
            <Select
                options={options}
                value={options.find(option => option.value === filterType)}
                onChange={(selectedOption) => setFilterType(selectedOption.value)}
                placeholder="Select Filter..."
                styles={customStyles}
                className="filter-type-select-mobile"
                classNamePrefix="select"
                isSearchable={false}
            />
        );
    };

    return isMobile ? renderMobileVersion() : renderDesktopVersion();
};

/* GENRE SELECTOR */
const GenreSelector = ({ allTvGenres, selectedGenres, setSelectedGenres, isMobile }) => {
    const genreOptions = allTvGenres.map(genre => ({
        value: genre.id,
        label: genre.name
    }));

    const handleChange = (selectedOptions) => {
        setSelectedGenres(selectedOptions || []);
    };

    /* Masaüstü versiyonu */
    const renderDesktopVersion = () => {
        return (
            <Select
                options={genreOptions}
                isMulti
                value={selectedGenres}
                onChange={handleChange}
                placeholder="Select Genres..."
                className="genre-select"
                styles={customStyles}
                classNamePrefix="select"
                closeMenuOnSelect={false}
            />
        );
    };

    /* Mobil versiyonu */
    const renderMobileVersion = () => {
        return (
            <Select
                options={genreOptions}
                isMulti
                value={selectedGenres}
                onChange={handleChange}
                placeholder="Select Genres..."
                className="genre-select-mobile"
                styles={customStyles}
                classNamePrefix="select"
                closeMenuOnSelect={false}
            />
        );
    };

    return isMobile ? renderMobileVersion() : renderDesktopVersion();
};

/* TV SHOW CARD */
const TvShowCard = ({ tvShow, onClick, onDelete, isMobile }) => {
    const imageUrl = `https://image.tmdb.org/t/p/w300_and_h450_bestv2${tvShow.poster_path}`;

    /* Masaüstü versiyonu */
    const renderDesktopVersion = () => {
        return (
            <div className="movie-card" onClick={() => onClick(tvShow)}>
                <div className="card-image">
                    <img src={imageUrl} alt={tvShow.name} loading='eager' />
                </div>
                <div className="card-content">
                    <h5 className="card-title">{tvShow.name}</h5>
                    {onDelete && (
                        <button
                            className="close-icon"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onDelete();
                            }}
                        >
                            <FaTimes />
                        </button>
                    )}
                </div>
            </div>
        );
    };

    /* Mobil versiyonu */
    const renderMobileVersion = () => {
        return (
            <div className="movie-card-mobile" onClick={() => onClick(tvShow)}>
                <div className="card-image-mobile">
                    <img src={imageUrl} alt={tvShow.name} loading='eager' />
                </div>
                <div className="card-content-mobile">
                    <h5 className="card-title-mobile">{tvShow.name}</h5>
                    {onDelete && (
                        <button
                            className="close-icon-mobile"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onDelete();
                            }}
                        >
                            <FaTimes />
                        </button>
                    )}
                </div>
            </div>
        );
    };

    return isMobile ? renderMobileVersion() : renderDesktopVersion();
};
TvShowCard.propTypes = {
    tvShow: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    isMobile: PropTypes.bool
};

/* TV SHOW DETAIL TAB */
const TvShowDetailTab = ({
    data: tvShowId,
    onClose,
    allUsersHistory,
    setAllUsersHistory,
    isMobile
}) => {
    const postRequestSyncPromise = usePostRequestSyncPromise();
    const [details, setDetails] = useState(null);
    const [isWatchPopupOpen, setWatchPopupOpen] = useState(false);
    const [watchUrl, setWatchUrl] = useState('');
    const [similars, setSimilars] = useState([]);
    const [currentTvShowId, setCurrentTvShowId] = useState(tvShowId);
    const [isLoading, setIsLoading] = useState(true);

    const fetchSimilars = (tvShowId) => {
        const baseReq = {
            data: [`${tvShowId}`],
        };
        postRequestSyncPromise('TvShow/GetSimilerTvShows', baseReq)
            .then(data => {
                setSimilars(data.data[0].results);
            })
            .catch(error => {
                console.error(error);
            });
    };

    useEffect(() => {
        if (tvShowId) {
            fetchTvShowDetails(tvShowId);
            fetchSimilars(tvShowId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tvShowId]);

    const fetchTvShowDetails = (tvShowId) => {
        const detailFilter = {
            id: `${tvShowId}`,
            language: 'tr-TR',
            append_to_response: 'videos'
        };

        const baseRequest = {
            requestId: 'unique_request_id',
            sender: 'client_tab_tvshow_details',
            data: [detailFilter]
        };

        postRequestSyncPromise('TvShow/TvShowDetail', baseRequest)
            .then(data => {
                setDetails(data.data[0]);
                setCurrentTvShowId(tvShowId);
            })
            .catch(error => console.error('Fetch error:', error));
    };

    const postUserHistory = (details) => {
        const userCode = localStorage.getItem('userCode');
        const existingHistory = allUsersHistory.find(
            history =>
                history.owner === userCode &&
                history?.tvShowDetail?.id === details.id
        );

        let historyData = {
            owner: userCode,
            historyType: 0,
            tvShowDetail: details
        };

        if (existingHistory) {
            historyData._id = existingHistory._id;
            historyData.createdDate = existingHistory.createdDate;
        }

        const baseRequest = { data: [historyData] };
        const endpoint = existingHistory
            ? 'UserHistory/UpdateUserHistory'
            : 'UserHistory/CreateUserHistory';

        postRequestSyncPromise(endpoint, baseRequest)
            .then(() => {
                // Başarılı işlem -> SignalR ile anlık güncelleme
            })
            .catch(error => {
                console.error(error);
            });
    };

    const renderItems = (items) => {
        return items && items.length > 0
            ? items
                .map((item, index) => (item.name || item.english_name))
                .reduce((prev, curr) => [prev, ', ', curr])
            : 'N/A';
    };

    const toggleWatchPopup = (type) => {
        const baseVideoUrl = `https://vidsrc.to/embed/tv/${currentTvShowId}`;
        const videoUrlWithSub = `https://vidsrc.xyz/embed/tv?tmdb=${currentTvShowId}&ds_langs=en,tr`;
        setWatchUrl(type === 'withSub' ? videoUrlWithSub : baseVideoUrl);
        setWatchPopupOpen(true);
        if (details) {
            postUserHistory(details);
        }
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        pauseOnHover: true
    };

    const handleSimilarTvShowClick = (similarTvShowId) => {
        fetchTvShowDetails(similarTvShowId);
        fetchSimilars(similarTvShowId);
    };

    /* Masaüstü versiyonu */
    const renderDesktopVersion = () => {
        return (
            <CSSTransition in={true} timeout={300} classNames="modal-animation" unmountOnExit>
                <Modal isOpen={true} toggle={onClose} size="xl" centered className="movie-detail-modal">
                    <ModalBody className="modal-body-custom">
                        {details ? (
                            <div>
                                <button className="modal-close-button" onClick={onClose}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                                <div className="movie-card-detail">
                                    <img
                                        src={`https://image.tmdb.org/t/p/w500${details.poster_path}`}
                                        alt={details.original_name}
                                        className="detail-poster"
                                        loading='eager'
                                    />
                                    <div className="detail-info">
                                        <h2 className="detail-title">{details.original_name}</h2>
                                        <p className="detail-overview">{details.overview || 'N/A'}</p>

                                        <div className="movie-details-grid">
                                            <div className="detail-section">
                                                <span className="detail-label">Genres:</span>
                                                <span className="detail-value">{renderItems(details.genres)}</span>
                                            </div>
                                            <div className="detail-section">
                                                <span className="detail-label">Episode Run Time:</span>
                                                <span className="detail-value">
                                                    {details.episode_run_time.join(', ') || 'N/A'} minutes
                                                </span>
                                            </div>
                                            <div className="detail-section">
                                                <span className="detail-label">Last Episode:</span>
                                                <span className="detail-value">
                                                    {details.last_episode_to_air ? details.last_episode_to_air.name : 'N/A'}
                                                </span>
                                            </div>
                                            <div className="detail-section">
                                                <span className="detail-label">Next Episode:</span>
                                                <span className="detail-value">
                                                    {details.next_episode_to_air ? details.next_episode_to_air.name : 'N/A'}
                                                </span>
                                            </div>
                                            <div className="detail-section">
                                                <span className="detail-label">Status:</span>
                                                <span className="detail-value">{details.status || 'N/A'}</span>
                                            </div>
                                            <div className="detail-section">
                                                <span className="detail-label">Vote Average:</span>
                                                <span className="detail-value">{details.vote_average || 'N/A'}</span>
                                            </div>
                                        </div>

                                        <div className="movie-detail-actions">
                                            <button className="stylish-button" onClick={() => toggleWatchPopup('noSub')}>
                                                Watch TV Show
                                            </button>
                                            <button className="stylish-button" onClick={() => toggleWatchPopup('withSub')}>
                                                Watch TV Show With Sub
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <h2 className="similar-movies-title">Similar TvShows</h2>
                                <Slider {...sliderSettings} className="similar-movies-slider">
                                    {similars.map((tvShow) => (
                                        <div
                                            key={tvShow.id}
                                            className="similar-movie-card"
                                            onClick={() => handleSimilarTvShowClick(tvShow.id)}
                                        >
                                            <img
                                                src={`https://image.tmdb.org/t/p/w200${tvShow.poster_path}`}
                                                alt={tvShow.name}
                                                className="similar-movie-poster"
                                                loading='eager'
                                            />
                                            <div className="similar-movie-info">
                                                <h6 className="similar-movie-title">{tvShow.name}</h6>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        ) : (
                            <div className="loading">Loading...</div>
                        )}
                    </ModalBody>

                    <Modal
                        isOpen={isWatchPopupOpen}
                        toggle={() => setWatchPopupOpen(false)}
                        size="xl"
                        centered
                        className="custom-modal"
                    >
                        <ModalBody className='modal-body-watch'>
                            <div className="video-wrapper">
                                <iframe
                                    src={`${watchUrl}?autoplay=1`}
                                    allow="autoplay; encrypted-media"
                                    allowFullScreen
                                    title="TV Show Watch"
                                    className="video-iframe"
                                />
                            </div>
                        </ModalBody>
                    </Modal>
                </Modal>
            </CSSTransition>
        );
    };

    /* Mobil versiyonu */
    const renderMobileVersion = () => {
        if (!details) {
            return <div className="loading-mobile">Loading...</div>;
        }

        const posterUrl = details.poster_path
            ? `https://image.tmdb.org/t/p/w500${details.poster_path}`
            : '/fallback-image.jpg';

        return (
            <div className="mobile-detail-container">
                {/* Header Banner */}
                <div
                    className="mobile-detail-banner"
                    style={{
                        backgroundImage: `url(${posterUrl})`,
                    }}
                >
                    <div className="mobile-detail-banner-overlay">
                        <button className="mobile-back-button" onClick={onClose}>
                            <FontAwesomeIcon icon={faClose} />
                        </button>
                    </div>
                </div>

                {/* Content Section */}
                <div className="mobile-detail-content">
                    {/* Quick Info */}
                    <div className="mobile-detail-header">
                        <h1 className="mobile-title">{details.original_name || 'N/A'}</h1>
                        <div className="mobile-meta">
                            <span className="mobile-rating">
                                <FontAwesomeIcon icon={faStar} /> {details.vote_average || 'N/A'}
                            </span>
                            <span className="mobile-year">
                                {details.first_air_date
                                    ? new Date(details.first_air_date).getFullYear()
                                    : 'N/A'}
                            </span>
                            <span className="mobile-episodes">
                                {details.number_of_episodes || 'N/A'} Episodes
                            </span>
                        </div>
                    </div>

                    {/* Genre Tags */}
                    <div className="mobile-genres">
                        {details.genres && details.genres.length > 0
                            ? details.genres.map((genre) => (
                                <span key={genre.id} className="mobile-genre-tag">
                                    {genre.name}
                                </span>
                            ))
                            : 'N/A'}
                    </div>

                    {/* Overview */}
                    <div className="mobile-overview">
                        <p>{details.overview || 'Overview not available.'}</p>
                    </div>

                    {/* Show Info */}
                    <div className="mobile-info-grid">
                        <div className="mobile-info-item">
                            <label>Status</label>
                            <span>{details.status || 'N/A'}</span>
                        </div>
                        <div className="mobile-info-item">
                            <label>Latest Episode</label>
                            <span>{details.last_episode_to_air?.name || 'N/A'}</span>
                        </div>
                        <div className="mobile-info-item">
                            <label>Next Episode</label>
                            <span>{details.next_episode_to_air?.name || 'N/A'}</span>
                        </div>
                    </div>
                </div>

                {/* Fixed Bottom Actions */}
                <div className="mobile-actions">
                    <button
                        className="mobile-action-button watch-primary"
                        onClick={() => toggleWatchPopup('withSub')}
                    >
                        <FontAwesomeIcon icon={faPlay} className="action-icon" />
                        <span>Watch</span>
                    </button>
                    {/* <button
                        className="mobile-action-button watch-secondary"
                        onClick={() => toggleWatchPopup('noSub')}
                    >
                        <FontAwesomeIcon icon={faClosedCaptioning} className="action-icon" />
                        <span>Without Subtitles</span>
                    </button> */}
                </div>

                {/* Watch Modal */}
                <Modal
                    isOpen={isWatchPopupOpen}
                    toggle={() => setWatchPopupOpen(false)}
                    size={isMobile ? "full" : "xl"}
                    centered={!isMobile}
                    className={`video-modal ${isMobile ? 'video-modal-mobile' : ''}`}
                >
                    <ModalBody className={`video-modal-body ${isMobile ? 'video-modal-body-mobile' : ''}`}>
                        <button
                            className="video-close-button"
                            onClick={() => setWatchPopupOpen(false)}
                        >
                            <FontAwesomeIcon icon={faClose} />
                        </button>
                        <div className="video-container-not">
                            {isLoading && (
                                <div className="video-loading">
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                </div>
                            )}
                            <iframe
                                src={`${watchUrl}&autoplay=1`}
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title="Tv Show Watch"
                                className="video-iframe"
                                onLoad={() => setIsLoading(false)}
                            />
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    };

    return isMobile ? renderMobileVersion() : renderDesktopVersion();
};
TvShowDetailTab.propTypes = {
    data: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    isMobile: PropTypes.bool
};

/* TV SHOW WATCH (örnek, proje içinde kullanılıyor olabilir) */
const TvShowWatch = ({ isOpen, toggle, tvShowId, isMobile }) => {
    const videoUrl = `https://vidsrc.to/embed/tv/${tvShowId}`;
    const videoUrlWithSub = `https://vidsrc.xyz/embed/tv?tmdb=${tvShowId}&ds_langs=en,tr`;

    const iframeRef1 = useRef(null);
    const iframeRef2 = useRef(null);

    useEffect(() => {
        const handleIframeEvents = (iframe) => {
            try {
                const iframeWindow = iframe.current?.contentWindow;
                if (iframeWindow) {
                    iframeWindow.addEventListener('beforeunload', (event) => {
                        console.warn('Redirect attempt detected in iframe');
                        event.preventDefault();
                    });
                    window.addEventListener('message', (event) => {
                        if (
                            event.origin === 'https://vidsrc.to' ||
                            event.origin === 'https://vidsrc.xyz'
                        ) {
                            console.warn('Message from iframe:', event.data);
                        }
                    });
                }
            } catch (error) {
                console.error('Error accessing iframe content:', error);
            }
        };
        handleIframeEvents(iframeRef1);
        handleIframeEvents(iframeRef2);

        return () => {
            window.removeEventListener('message', handleIframeEvents);
        };
    }, [isOpen]);

    /* Masaüstü versiyonu */
    const renderDesktopVersion = () => {
        return (
            <CSSTransition in={isOpen} timeout={300} classNames="modal-animation" unmountOnExit>
                <Modal isOpen={isOpen} toggle={toggle} size="xl" className="movie-watch-modal custom-modal" centered>
                    <ModalHeader toggle={toggle} className="modal-header-custom">
                        Watch TV Show
                    </ModalHeader>
                    <ModalBody className="modal-body-custom">
                        <div className="video-container-not">
                            <div className="iframe-wrapper">
                                <div className="video-wrapper">
                                    <iframe
                                        ref={iframeRef1}
                                        src={`${videoUrl}?autoplay=1`}
                                        allow="autoplay; encrypted-media"
                                        allowFullScreen
                                        title="TV Show Watch"
                                        className="video-iframe"
                                        referrerPolicy="origin"
                                    />
                                </div>
                                <div className="video-wrapper">
                                    <iframe
                                        ref={iframeRef2}
                                        src={`${videoUrlWithSub}&autoplay=1`}
                                        allow="autoplay; encrypted-media"
                                        allowFullScreen
                                        title="TV Show Watch With Subtitles"
                                        className="video-iframe"
                                        referrerPolicy="origin"
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </CSSTransition>
        );
    };

    /* Mobil versiyonu */
    const renderMobileVersion = () => {
        return (
            <CSSTransition in={isOpen} timeout={300} classNames="modal-animation" unmountOnExit>
                <Modal
                    isOpen={isOpen}
                    toggle={toggle}
                    size="xl"
                    className="movie-watch-modal-mobile custom-modal-mobile"
                    centered
                >
                    <ModalHeader toggle={toggle} className="modal-header-custom-mobile">
                        Watch TV Show
                    </ModalHeader>
                    <ModalBody className="modal-body-custom-mobile">
                        <div className="video-container-not-mobile">
                            <div className="iframe-wrapper-mobile">
                                <div className="video-wrapper-mobile">
                                    <iframe
                                        ref={iframeRef1}
                                        src={`${videoUrl}?autoplay=1`}
                                        allow="autoplay; encrypted-media"
                                        allowFullScreen
                                        title="TV Show Watch"
                                        className="video-iframe-mobile"
                                        referrerPolicy="origin"
                                    />
                                </div>
                                <div className="video-wrapper-mobile">
                                    <iframe
                                        ref={iframeRef2}
                                        src={`${videoUrlWithSub}&autoplay=1`}
                                        allow="autoplay; encrypted-media"
                                        allowFullScreen
                                        title="TV Show Watch With Subtitles"
                                        className="video-iframe-mobile"
                                        referrerPolicy="origin"
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </CSSTransition>
        );
    };

    return isMobile ? renderMobileVersion() : renderDesktopVersion();
};
TvShowWatch.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    tvShowId: PropTypes.number.isRequired,
    isMobile: PropTypes.bool
};

/* SLIDER CONTENT */
const SliderContent = ({
    tvShowId,
    postRequestSyncPromise,
    allUsersHistory,
    isMobile
}) => {
    const [tvShowDetails, setTvShowDetails] = useState(null);
    const [isWatchPopupOpen, setWatchPopupOpen] = useState(false);
    const [watchUrl, setWatchUrl] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const fetchTvShowDetails = useCallback(() => {
        const detailFilter = {
            id: `${tvShowId}`,
            language: 'tr-TR',
            append_to_response: 'videos'
        };
        const baseRequest = {
            requestId: 'unique_request_id',
            sender: 'client_tab_tvshow_details',
            data: [detailFilter]
        };
        postRequestSyncPromise('TvShow/TvShowDetail', baseRequest)
            .then(data => {
                setTvShowDetails(data.data[0]);
            })
            .catch(error => console.error('Fetch error:', error));
    }, [postRequestSyncPromise, tvShowId]);

    const postUserHistory = (details) => {
        const userCode = localStorage.getItem('userCode');
        const existingHistory = allUsersHistory.find(
            history =>
                history.owner === userCode &&
                history?.tvShowDetail?.id === details.id
        );

        let historyData = {
            owner: userCode,
            historyType: 0,
            tvShowDetail: details
        };

        if (existingHistory) {
            historyData._id = existingHistory._id;
            historyData.createdDate = existingHistory.createdDate;
        }

        const baseRequest = { data: [historyData] };
        const endpoint = existingHistory
            ? 'UserHistory/UpdateUserHistory'
            : 'UserHistory/CreateUserHistory';

        fetch(CONFIG.BaseUrl + endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(baseRequest)
        })
            .then(response => response.json())
            .then(data => {
                if (data.type === 0) {
                    console.log("User history posted successfully.");
                } else {
                    console.log("Error in posting user history:", data);
                }
            })
            .catch(error => {
                console.error("Error in posting user history:", error);
            });
    };

    useEffect(() => {
        fetchTvShowDetails();
    }, [fetchTvShowDetails]);

    const toggleWatchPopup = () => {
        const videoUrlWithSub = `https://vidsrc.xyz/embed/tv/${tvShowId}?ds_langs=en,tr`;
        setWatchUrl(videoUrlWithSub);
        setWatchPopupOpen(true);
        if (tvShowDetails) {
            postUserHistory(tvShowDetails);
        } else {
            console.error('tvShowDetails is not available.');
        }
    };

    if (!tvShowDetails) {
        return <div>Loading...</div>;
    }

    const renderItems = (items) => {
        return items && items.length > 0
            ? items.map((item) => item.name || item.english_name).join(', ')
            : 'N/A';
    };

    /* Masaüstü versiyonu */
    const renderDesktopVersion = () => {
        return (
            <div className="slider-content-wrapper">
                <div className="slider-content">
                    <div
                        className="slider-content__backdrop"
                        style={{
                            backgroundImage: `url(https://image.tmdb.org/t/p/original${tvShowDetails.backdrop_path})`
                        }}
                    >
                        <div className="slider-content__overlay">
                            <div className="slider-content__info">
                                <h2 className="slider-content__title">{tvShowDetails.name}</h2>
                                <div className="slider-content__meta">
                                    <span className="release-date">{tvShowDetails.first_air_date}</span>
                                    <span className="rating">
                                        <i className="fas fa-star"></i> {tvShowDetails.vote_average}
                                    </span>
                                    <span className="genres">{renderItems(tvShowDetails.genres)}</span>
                                </div>
                                <p className="slider-content__overview">{tvShowDetails.overview}</p>
                                <div className="slider-content__stats">
                                    <div className="stat">
                                        <span className="label">Sezon Sayısı</span>
                                        <span className="value">{tvShowDetails.number_of_seasons}</span>
                                    </div>
                                    <div className="stat">
                                        <span className="label">Bölüm Sayısı</span>
                                        <span className="value">{tvShowDetails.number_of_episodes}</span>
                                    </div>
                                    <div className="stat">
                                        <span className="label">Durum</span>
                                        <span className="value">{tvShowDetails.status}</span>
                                    </div>
                                </div>
                                <div className="slider-content__actions">
                                    <button className="watch-button" onClick={toggleWatchPopup}>
                                        <i className="fas fa-play"></i> İzle
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {isWatchPopupOpen && (
                        <Modal
                            isOpen={isWatchPopupOpen}
                            toggle={() => setWatchPopupOpen(false)}
                            size="xl"
                            centered
                            className="custom-modal"
                        >
                            <ModalBody className="modal-body-watch">
                                <div className="video-wrapper">
                                    <iframe
                                        src={`${watchUrl}&autoplay=1`}
                                        allow="autoplay; encrypted-media"
                                        allowFullScreen
                                        title="Tv Show Watch"
                                        className="video-iframe"
                                    />
                                </div>
                            </ModalBody>
                        </Modal>
                    )}
                </div>
            </div>
        );
    };

    /* Mobil versiyonu */
    const renderMobileVersion = () => {
        return (
            <div className="slider-content-wrapper-mobile">
                <div className="slider-content-mobile">
                    <div
                        className="slider-content-mobile__backdrop"
                        style={{
                            backgroundImage: `url(https://image.tmdb.org/t/p/original${tvShowDetails.backdrop_path})`
                        }}
                    >
                        <div className="slider-content-mobile__overlay">
                            <div className="slider-content-mobile__info">
                                <h2 className="slider-content-mobile__title">{tvShowDetails.name}</h2>
                                <div className="slider-content-mobile__meta">
                                    <span className="release-date-mobile">{tvShowDetails.first_air_date}</span>
                                    <span className="rating-mobile">
                                        <i className="fas fa-star"></i> {tvShowDetails.vote_average}
                                    </span>
                                    <span className="genres-mobile">{renderItems(tvShowDetails.genres)}</span>
                                </div>
                                <div className="slider-content-mobile__stats">
                                    <div className="stat-mobile">
                                        <span className="label-mobile">Sezon Sayısı</span>
                                        <span className="value-mobile">{tvShowDetails.number_of_seasons}</span>
                                    </div>
                                    <div className="stat-mobile">
                                        <span className="label-mobile">Bölüm Sayısı</span>
                                        <span className="value-mobile">{tvShowDetails.number_of_episodes}</span>
                                    </div>
                                    <div className="stat-mobile">
                                        <span className="label-mobile">Durum</span>
                                        <span className="value-mobile">{tvShowDetails.status}</span>
                                    </div>
                                </div>
                                <div className="slider-content-mobile__actions">
                                    <button className="watch-button-mobile" onClick={toggleWatchPopup}>
                                        <i className="fas fa-play"></i> İzle
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {isWatchPopupOpen && (
                        <Modal
                            isOpen={isWatchPopupOpen}
                            toggle={() => setWatchPopupOpen(false)}
                            size={isMobile ? "full" : "xl"}
                            centered={!isMobile}
                            className={`video-modal ${isMobile ? 'video-modal-mobile' : ''}`}
                        >
                            <ModalBody className={`video-modal-body ${isMobile ? 'video-modal-body-mobile' : ''}`}>
                                <button
                                    className="video-close-button"
                                    onClick={() => setWatchPopupOpen(false)}
                                >
                                    <FontAwesomeIcon icon={faClose} />
                                </button>
                                <div className="video-container-not">
                                    {isLoading && (
                                        <div className="video-loading">
                                            <FontAwesomeIcon icon={faSpinner} spin />
                                        </div>
                                    )}
                                    <iframe
                                        src={`${watchUrl}&autoplay=1`}
                                        allow="autoplay; encrypted-media"
                                        allowFullScreen
                                        title="Tv Show Watch"
                                        className="video-iframe"
                                        onLoad={() => setIsLoading(false)}
                                    />
                                </div>
                            </ModalBody>
                        </Modal>
                    )}
                </div>
            </div>
        );
    };

    return isMobile ? renderMobileVersion() : renderDesktopVersion();
};

/* ANA TV SHOW COMPONENT */
export default function TvShow() {

    const { allUsers } = useContext(UserContext);

    //! IS MOBILE
    const { isMobile, orientation } = useIsMobile();

    //! PAGE STATE
    const [pageCountInfo, setPageCountInfo] = useState(1);
    const [isSearchActive, setIsSearchActive] = useState(false);

    //! GLOBAL FETCH
    const postRequestSyncPromise = usePostRequestSyncPromise();

    //! TV SHOW SEARCH
    const [tvShowSearchTextboxValue, setTvShowSearchTextboxValue] = useState("");

    //! TV SHOW DATA
    const [searchedTvShowDataSource, setSearchedTvShowDataSource] = useState([]);
    const [topTvShows, setTopTvShows] = useState([]);
    const [selectedTvShow, setSelectedTvShow] = useState(null);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [allTvGenres, setAllTvGenres] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [filterType, setFilterType] = useState("AND");
    const [allUserNames, setAllUsers] = useState([]);
    const [allUsersHistory, setAllUsersHistory] = useState([]);
    const [isHistoryPopupVisible, setIsHistoryPopupVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const signalConnection = useRef(null);

    useEffect(() => {
        DiscoverTvShow(1);
        FetchTopTvShows();
        fetchTvGenres();
    }, []);

    useEffect(() => {
        setAllUsers(allUsers.map(user => user.userName));
    }, [allUsers]);

    //! PAGE FUNCTIONS
    const handlePageClick = (data) => {
        let selectedPage = data.selected + 1;
        setCurrentPage(selectedPage);
        setSearchedTvShowDataSource([]);
        if (isSearchActive) {
            SearchTvShow(selectedPage);
        } else {
            DiscoverTvShow(selectedPage);
        }
    };

    //! DISCOVER TV SHOW
    const DiscoverTvShow = useCallback((page = 1) => {
        if (page === 1) {
            setCurrentPage(1);
        }
        let pageInformation = page || 1;

        let withGenres = "";
        let genreIds = selectedGenres.map(genre => genre.value);
        if (filterType === "AND")
            withGenres = genreIds.join(',');
        else
            withGenres = genreIds.join('|');
        const discoverFilter = {
            include_adult: true,
            language: "tr-TR",
            page: pageInformation,
            with_genres: withGenres
        };

        const baseRequest = {
            RequestId: "unique_request_id",
            Sender: "client_discover_tvshow",
            Data: [discoverFilter]
        };

        postRequestSyncPromise("TvShow/DiscoverTvShowAsync", baseRequest)
            .then(data => {
                setSearchedTvShowDataSource(data.data[0].results);
                setPageCountInfo(data.data[0].total_pages || 500);
                window.scrollTo(0, 0);
            })
            .catch(error => {
                console.error(error);
            });
    }, [postRequestSyncPromise, selectedGenres, filterType]);

    //! SEARCH TV SHOW
    const SearchTvShow = useCallback((page = 1) => {
        if (page === 1) {
            setCurrentPage(1);
        }
        const searchFilter = {
            query: tvShowSearchTextboxValue,
            language: "tr-TR",
            page: page
        };

        const baseRequest = {
            RequestId: "unique_request_id",
            Sender: "client_search_tvshow",
            Data: [searchFilter]
        };

        postRequestSyncPromise("TvShow/SearchTvShow", baseRequest)
            .then(data => {
                setSearchedTvShowDataSource(data.data[0].results);
                setPageCountInfo(data.data[0].total_pages || 500);
                window.scrollTo(0, 0);
            })
            .catch(error => {
                console.error(error);
            });
    }, [tvShowSearchTextboxValue, postRequestSyncPromise]);

    //! FETCH TOP TV SHOWS
    const FetchTopTvShows = useCallback(() => {
        const discoverFilter = {
            include_adult: false,
            language: "tr-TR",
            page: 1
        };

        const baseRequest = {
            RequestId: "unique_request_id",
            Sender: "client_discover_tvshow",
            Data: [discoverFilter]
        };

        postRequestSyncPromise("TvShow/DiscoverTvShowAsync", baseRequest)
            .then(data => {
                const sortedTvShows = data.data[0].results.sort((a, b) => b.popularity - a.popularity);
                const topTen = sortedTvShows.slice(0, 10);
                setTopTvShows(topTen);
            })
            .catch(error => {
                console.error(error);
            });
    }, [postRequestSyncPromise]);

    const fetchTvGenres = useCallback(() => {
        postRequestSyncPromise('TvShow/GetAllTvGenres', {})
            .then(data => {
                setAllTvGenres(data.data[0].genres);
            })
            .catch(error => {
                console.error(error);
            });
    }, [postRequestSyncPromise]);

    useEffect(() => {
        initializeSignalR();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initializeSignalR = async () => {
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl("https://api.kaminarivi.com.tr/movieHub")
            .withAutomaticReconnect([1000, 2000, 3000, 5000, 10000])
            .build();

        async function start(connection) {
            try {
                await connection.start();
                console.log("SignalR Connected : ", connection.connectionId);
                localStorage.setItem("connectionId", connection.connectionId);
            } catch (error) {
                console.error(error);
                setTimeout(() => start(connection), 5000);
            }
        }
        start(newConnection);
        signalConnection.current = newConnection;
        newConnection.onreconnected(() => {
            showSuccessToast("Connection Reestablished.");
        });
        newConnection.onreconnected(() => {
            showSuccessToast("Connection Reestablished.");
        });
        newConnection.onclose(async (error) => {
            if (error) {
                console.error("SignalR connection closed with error: ", error);
            } else {
                console.log("SignalR connection closed.");
            }
        });
        newConnection.on("InsertUserHistory", (updatedUserHistory) => {
            setAllUsersHistory((prevHistories) => {
                if (updatedUserHistory.historyType !== 0) {
                    return prevHistories;
                }
                const existingIndex = prevHistories.findIndex(h => h._id === updatedUserHistory._id);
                if (existingIndex !== -1) {
                    const newHistories = [...prevHistories];
                    newHistories[existingIndex] = updatedUserHistory;
                    console.log("NEW HISTORIES : ", newHistories);
                    return newHistories;
                } else {
                    console.log("NEW HISTORIES : ", [...prevHistories, updatedUserHistory]);
                    return [...prevHistories, updatedUserHistory];
                }
            });
        });
        newConnection.on("UpdateUserHistory", (updatedUserHistory) => {
            setAllUsersHistory((prevHistories) => {
                if (updatedUserHistory.historyType !== 0) {
                    return prevHistories;
                }
                const existingIndex = prevHistories.findIndex(h => h._id === updatedUserHistory._id);
                if (existingIndex !== -1) {
                    const newHistories = [...prevHistories];
                    newHistories[existingIndex] = updatedUserHistory;
                    return newHistories;
                } else {
                    return [...prevHistories, updatedUserHistory];
                }
            });
        });
        newConnection.on("DeleteUserHistory", (deletedUserHistory) => {
            if (deletedUserHistory.historyType !== 0) {
                return;
            }
            setAllUsersHistory((prevHistories) => {
                const updatedHistories = prevHistories.filter(history => history._id !== deletedUserHistory._id);
                return updatedHistories;
            });
            showInfoToast(`History for "${deletedUserHistory.movieDetail?.title || 'Deleted'}" has been deleted.`);
        });
    };

    const fetchUserHistory = useCallback(() => {
        const baseRequest = {
            data: allUserNames
        };
        return postRequestSyncPromise('UserHistory/GetSelectedUsersHistory', baseRequest)
            .then(data => {
                setAllUsersHistory(data.data);
            })
            .catch(error => {
                console.error("Error fetching user history:", error);
            });
    }, [allUserNames, postRequestSyncPromise]);

    useEffect(() => {
        if (allUserNames.length > 0) {
            fetchUserHistory();
        }
    }, [allUserNames, fetchUserHistory]);

    //! HANDLE CARD CLICK
    const handleCardClick = (tvShow) => {
        setSelectedTvShow(tvShow);
        setPopupVisible(true);
    };

    //! CLOSE POPUP
    const closePopup = () => {
        setPopupVisible(false);
        setSelectedTvShow(null);
    };

    //! SLIDER SETTINGS
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: isMobile ? 1 : 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                }
            }
        ]
    };

    /* Ana sayfadaki slider */
    const renderSlider = () => {
        const sliderSettings = {
            ...settings,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            fade: true,
            arrows: !isMobile,
        };

        return (
            <Slider {...sliderSettings}>
                {topTvShows.map((tvShow) => (
                    <div key={tvShow.id}>
                        <SliderContent
                            tvShowId={tvShow.id}
                            postRequestSyncPromise={postRequestSyncPromise}
                            allUsersHistory={allUsersHistory}
                            isMobile={isMobile}
                        />
                    </div>
                ))}
            </Slider>
        );
    };

    const renderTvShowCards = () => {
        if (isMobile) {
            return (
                <div className="mobile-shows-container">
                    <div className="mobile-shows-scroll">
                        {searchedTvShowDataSource.map(tvShow => (
                            <TvShowCard
                                key={tvShow.id}
                                tvShow={tvShow}
                                onClick={handleCardClick}
                                isMobile={true}
                            />
                        ))}
                    </div>
                </div>
            );
        }

        return (
            <div className="movie-list">
                {searchedTvShowDataSource.map(tvShow => (
                    <TvShowCard
                        key={tvShow.id}
                        tvShow={tvShow}
                        onClick={handleCardClick}
                        isMobile={false}
                    />
                ))}
            </div>
        );
    };

    const renderPageContent = () => {
        /* Masaüstü arayüz */
        const renderDesktopVersion = () => {
            return (
                <div>
                    <div className="search-bar">
                        <div className="control-row">
                            <input
                                type="text"
                                value={tvShowSearchTextboxValue}
                                onChange={(e) => setTvShowSearchTextboxValue(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setIsSearchActive(true);
                                        SearchTvShow(1);
                                    }
                                }}
                                placeholder="Search TV Show"
                                className="movie-search-input"
                            />
                            <button
                                className="kaminari-button"
                                onClick={() => {
                                    setIsSearchActive(true);
                                    SearchTvShow(1);
                                }}
                                aria-label="Search TV Show"
                            >
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                            <button
                                className="kaminari-button"
                                onClick={() => setIsHistoryPopupVisible(true)}
                                aria-label="User History"
                            >
                                <FontAwesomeIcon icon={faHistory} />
                            </button>
                        </div>
                        <div className="control-row">
                            <GenreSelector
                                allTvGenres={allTvGenres}
                                selectedGenres={selectedGenres}
                                setSelectedGenres={setSelectedGenres}
                                className="genre-selector"
                                isMobile={false}
                            />
                            <FilterTypeSelect
                                filterType={filterType}
                                setFilterType={setFilterType}
                                className="filter-type-select"
                                isMobile={false}
                            />
                            <button
                                className="kaminari-button"
                                onClick={() => {
                                    setIsSearchActive(false);
                                    setTvShowSearchTextboxValue("");
                                    DiscoverTvShow(1);
                                }}
                                aria-label="Discover TV Shows"
                            >
                                <FontAwesomeIcon icon={faTv} />
                            </button>
                        </div>
                    </div>

                    <div className="movie-section">
                        <h2 className="section-title">Top 10 Popular TV Shows</h2>
                        {renderSlider()}
                    </div>

                    <div className="movie-section">
                        <h2 className="section-title">
                            {isSearchActive ? "Search Results" : "Discover TV Shows"}
                        </h2>
                        {renderTvShowCards()}
                    </div>
                </div>
            );
        };

        /* Mobil arayüz */
        const renderMobileVersion = () => {

            return (
                <div className="mobile-container">
                    {/* Floating Search Button */}
                    <button
                        className="mobile-search-fab"
                        onClick={() => setIsSearchVisible(!isSearchVisible)}
                    >
                        <FontAwesomeIcon icon={isSearchVisible ? faTimes : faSearch} />
                    </button>

                    {/* Collapsible Search Panel */}
                    <div className={`mobile-search-panel ${isSearchVisible ? 'visible' : ''}`}>
                        <div className="search-bar-mobile">
                            <div className="control-row-mobile">
                                <input
                                    type="text"
                                    value={tvShowSearchTextboxValue}
                                    onChange={(e) => setTvShowSearchTextboxValue(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            setIsSearchActive(true);
                                            SearchTvShow(1);
                                            setIsSearchVisible(false);
                                        }
                                    }}
                                    placeholder="Search TV Show"
                                    className="movie-search-input-mobile"
                                />
                                <button
                                    className="kaminari-button-mobile"
                                    onClick={() => {
                                        setIsSearchActive(true);
                                        SearchTvShow(1);
                                        setIsSearchVisible(false);
                                    }}
                                    aria-label="Search TV Show"
                                >
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                                <button
                                    className="kaminari-button-mobile"
                                    onClick={() => {
                                        setIsHistoryPopupVisible(true);
                                        setIsSearchVisible(false);
                                    }}
                                    aria-label="User History"
                                >
                                    <FontAwesomeIcon icon={faHistory} />
                                </button>
                            </div>
                            <div className="control-row-mobile">
                                <GenreSelector
                                    allTvGenres={allTvGenres}
                                    selectedGenres={selectedGenres}
                                    setSelectedGenres={setSelectedGenres}
                                    isMobile={true}
                                />
                                <FilterTypeSelect
                                    filterType={filterType}
                                    setFilterType={setFilterType}
                                    isMobile={true}
                                />
                                <button
                                    className="kaminari-button-mobile"
                                    onClick={() => {
                                        setIsSearchActive(false);
                                        setTvShowSearchTextboxValue("");
                                        DiscoverTvShow(1);
                                        setIsSearchVisible(false);
                                    }}
                                    aria-label="Discover TV Shows"
                                >
                                    <FontAwesomeIcon icon={faTv} />
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="mobile-content">
                        {/* Top Shows Slider */}
                        <section className="mobile-section">
                            <h2 className="mobile-section-title">
                                <span>Top 10 Popular TV Shows</span>
                            </h2>
                            {renderSlider()}
                        </section>

                        {/* Search Results or Discover */}
                        <section className="mobile-section">
                            <h2 className="mobile-section-title">
                                <span>{isSearchActive ? "Search Results" : "Discover TV Shows"}</span>
                                {searchedTvShowDataSource.length > 0 && isSearchActive && (
                                    <span className="results-count">
                                        ({searchedTvShowDataSource.length} results)
                                    </span>
                                )}
                            </h2>
                            {renderTvShowCards()}
                        </section>
                    </div>
                </div>
            );
        };

        return isMobile ? renderMobileVersion() : renderDesktopVersion();
    };

    return (
        <div className={isMobile ? "movie-container-mobile" : "movie-container"}>
            {renderPageContent()}
            <div className={isMobile ? "pagination-container-mobile" : "pagination-container"}>
                <ReactPaginate
                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
                    nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
                    breakLabel={'•'}
                    breakClassName={'break-me'}
                    pageCount={pageCountInfo}
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    containerClassName={'pagination-mobile'}
                    activeClassName={'active'}
                    forcePage={currentPage - 1}
                    pageClassName={'page-item-mobile'}
                    pageLinkClassName={'page-link-mobile'}
                    previousClassName={'prev-mobile'}
                    nextClassName={'next-mobile'}
                    previousLinkClassName={'nav-link-mobile'}
                    nextLinkClassName={'nav-link-mobile'}
                    disabledClassName={'disabled-mobile'}
                />
            </div>
            {isPopupVisible && selectedTvShow && (
                <TvShowDetailTab
                    data={selectedTvShow.id}
                    onClose={closePopup}
                    allUsersHistory={allUsersHistory}
                    setAllUsersHistory={setAllUsersHistory}
                    isMobile={isMobile}
                />
            )}
            {isHistoryPopupVisible && (
                <UserHistoryPopup
                    isOpen={isHistoryPopupVisible}
                    toggle={() => setIsHistoryPopupVisible(false)}
                    allUserNames={allUserNames}
                    allUsersHistory={allUsersHistory}
                    setPopupVisible={setPopupVisible}
                    setSelectedTvShow={setSelectedTvShow}
                    postRequestSyncPromise={postRequestSyncPromise}
                    setAllUsersHistory={setAllUsersHistory}
                    isMobile={isMobile}
                />
            )}
        </div>
    );
}