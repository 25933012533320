import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPhoneSlash, faMicrophone, faMicrophoneSlash, faHeadphones, faVolumeMute, faCheckCircle, faExclamationTriangle, faInfoCircle, faTimesCircle, faVideo, faVideoSlash, faBell, faBellSlash, faArrowDown, faDesktop, faGear, faComments,
    faMicrophone as faMicrophoneIcon,
    faPlus,
    faPen,
    faSearch,
    faTimes,
    faSignature,
    faEdit,
    faFolderOpen,
    faKeyboard,
    faToggleOn,
    faToggleOff,
    faUserPlus,
    faUser
} from '@fortawesome/free-solid-svg-icons';
import { FaEye, FaMicrophone, FaHeadphones, FaVideo, FaSync } from 'react-icons/fa';
import React, { useEffect, useState, useRef, useCallback, useMemo, useContext } from 'react';
import { Row, Col, InputGroup, Modal, ModalHeader, ModalBody } from 'reactstrap';
import 'react-resizable/css/styles.css';
import './KaminariCord.css';
import { usePostRequestSyncPromise } from '../../global/GlobalFetch';
import * as signalR from '@microsoft/signalr';
import { ToastContainer, toast } from 'react-toastify';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import 'react-quill/dist/quill.snow.css';
import 'react-toastify/dist/ReactToastify.css';
import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import { CONFIG } from '../../CONFIG';
import { UserContext } from '../../App';
import LocalStore from 'devextreme/data/local_store';
const showSuccessToast = (message) => {
    toast.dark(<div><FontAwesomeIcon icon={faCheckCircle} />{message}</div>);
};
const showErrorToast = (message) => {
    toast.dark(<div><FontAwesomeIcon icon={faTimesCircle} />{message}</div>);
};
const showWarningToast = (message) => {
    toast.dark(<div><FontAwesomeIcon icon={faExclamationTriangle} />{message}</div>);
};
const showInfoToast = (message) => {
    toast.dark(<div><FontAwesomeIcon icon={faInfoCircle} />{message}</div>);
};
const VOLUME_ANALYSIS_INTERVAL_MS = 250;

// ----- Quill mention highlight blot -----
const Inline = Quill.import('blots/inline');
class MentionBlot extends Inline {
    static create(userName) {
        let node = super.create();
        node.setAttribute('data-username', userName);
        node.style.color = '#7B61FF';
        node.style.fontWeight = 'bold';
        node.classList.add('mention-animate');
        return node;
    }
    static formats(node) {
        return node.getAttribute('data-username');
    }
}
MentionBlot.blotName = 'mention';
MentionBlot.tagName = 'span';
MentionBlot.className = 'mention';
Quill.register(MentionBlot);
//------------------------ MessagesPanel ------------------------------------------//
const MessagesPanel = ({ messages, onSendMessage, userImages, allUserNames, activeUsers, selectedTextChannel, channels }) => {
    const [messageContent, setMessageContent] = useState('');
    const messagesPanelRef = useRef(null);
    const quillRef = useRef(null);
    const suggestionListRef = useRef(null);
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [showScrollToBottom, setShowScrollToBottom] = useState(false);
    const [userSuggestions, setUserSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [caretPosition, setCaretPosition] = useState(0);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);
    const [suggestionPosition, setSuggestionPosition] = useState({ top: 0, left: 0 });
    const [visibleMessages, setVisibleMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const dateIndexRef = useRef(0);
    const currentUser = JSON.parse(localStorage.getItem('userData')).userName;
    const datesArray = useMemo(() => {
        const uniqueDates = [...new Set(messages.map(msg => new Date(msg.sentAt).toDateString()))];
        uniqueDates.sort((a, b) => new Date(b) - new Date(a));
        return uniqueDates;
    }, [messages]);
    const sanitizeMessageContent = (content) => {
        return content.replace(/<p>(<br>|\s)*<\/p>/g, '').trim();
    };
    const getMentionedUsernames = () => {
        const editor = quillRef.current.getEditor();
        const contents = editor.getContents();
        const mentionedUsernames = [];
        contents.ops.forEach(op => {
            if (op.insert && op.attributes && op.attributes.mention) {
                mentionedUsernames.push(op.attributes.mention);
            }
        });
        return mentionedUsernames;
    };
    const handleSuggestionInsert = (userName) => {
        const editor = quillRef.current.getEditor();
        const cursorPosition = editor.getSelection()?.index || 0;
        let startIndex = cursorPosition - 1;
        while (startIndex >= 0) {
            const char = editor.getText(startIndex, 1);
            if (/\s/.test(char) || char === '\n') break;
            startIndex--;
        }
        startIndex = Math.max(0, startIndex + 1);
        editor.deleteText(startIndex, cursorPosition - startIndex);
        editor.insertText(startIndex, `@${userName}`, { mention: userName });
        editor.formatText(startIndex + `@${userName}`.length, 1, 'mention', false);
        editor.insertText(startIndex + `@${userName}`.length, ' ', 'mention', false);
        editor.setSelection(startIndex + `@${userName}`.length + 1);
        setShowSuggestions(false);
        setSelectedSuggestionIndex(0);
        editor.focus();
    };
    const handleSendMessage = () => {
        const sanitizedContent = sanitizeMessageContent(messageContent);
        if (sanitizedContent === '') return;
        const MAX_MESSAGE_SIZE = 32000;
        const messageSize = new Blob([messageContent]).size;
        if (messageSize > MAX_MESSAGE_SIZE) {
            alert('Mesajınız çok büyük. Lütfen daha kısa bir mesaj gönderin.');
            return;
        }
        const mentionedUsers = [...messageContent.matchAll(/@(\w+)/g)].map((match) => match[1]);
        if (mentionedUsers.length > 0) {
            // Seçilen kanalı ve ilgili bilgileri al
            const channel = channels.find(ch => ch.roomId === selectedTextChannel);
            if (!channel) {
                console.error("Channel not found");
                return;
            }
            const currentUserName = localStorage.getItem("userCode");
            const selectedChannelName = channel.channelName;
            const allowedUsers = new Set(channel.allowedUsers);
            const ownerId = channel.ownerId;
            allowedUsers.add(ownerId);

            // Kanal izinleri çerçevesinde, etiketlenen ve kanalda yetkili kullanıcıları filtrele
            const allowedMentionedUsers = mentionedUsers.filter(u => allowedUsers.has(u));

            if (allowedMentionedUsers.length === 0) {
                showInfoToast('Etiketlenen kullanıcılar arasında kanala erisme yetkisi olan kimse yok.');
                return;
            }

            // Etiketlenen kullanıcıları aktif ve aktif olmayanlara ayır
            const activeUserNames = activeUsers.map(u => u.userName);
            const activeMentionedUsers = allowedMentionedUsers.filter(u => activeUserNames.includes(u));
            const nonActiveMentionedUsers = allowedMentionedUsers.filter(u => !activeUserNames.includes(u));

            // Aktif kullanıcılar için masaüstü bildirimi isteği gönder
            if (activeMentionedUsers.length > 0) {
                const requestObjectDekstop = {
                    requestId: "unique-request-id",
                    sender: "current-user",
                    data: [
                        {
                            senderUserName: currentUserName,
                            receiverUserNames: activeMentionedUsers,
                            channel: selectedChannelName,
                        },
                    ],
                    current: [{ Key: 'UserName', Value: currentUserName }],
                };
                fetch(CONFIG.BaseUrl + 'Notification/KaminariMessageNotificationViaDekstop', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: JSON.stringify(requestObjectDekstop),
                })
                    .then((response) => {
                        if (response.ok) {
                            showInfoToast('Masaüstü bildirimi gönderildi.');
                        } else {
                            showErrorToast('Masaüstü bildirimi gönderilirken bir hata oluştu.');
                        }
                    })
                    .catch((error) => {
                        showErrorToast('Masaüstü bildirimi gönderilirken bir hata oluştu.');
                        console.error('Error sending desktop notification:', error);
                    });
            }

            // Aktif olmayan kullanıcılar için e-posta bildirimi isteği gönder
            if (nonActiveMentionedUsers.length > 0) {
                const requestObjectEmail = {
                    requestId: "unique-request-id",
                    sender: "current-user",
                    data: [
                        {
                            senderUserName: currentUserName,
                            receiverUserNames: nonActiveMentionedUsers,
                        },
                    ],
                    current: [{ Key: 'UserName', Value: currentUserName }],
                };
                fetch(CONFIG.BaseUrl + 'Notification/SendNotificationViaEmail', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: JSON.stringify(requestObjectEmail),
                })
                    .then((response) => {
                        if (response.ok) {
                            showInfoToast('E-posta bildirimi gönderildi.');
                        } else {
                            showErrorToast('E-posta bildirimi gönderilirken bir hata oluştu.');
                        }
                    })
                    .catch((error) => {
                        showErrorToast('E-posta bildirimi gönderilirken bir hata oluştu.');
                        console.error('Error sending email notification:', error);
                    });
            }
        }
        if (isAtBottom) {
            scrollToBottom();
        }
        onSendMessage(messageContent.trimEnd());
        setMessageContent('');
    };
    const handleKeyDown = (e) => {
        if (showSuggestions) {
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                setSelectedSuggestionIndex((prevIndex) =>
                    prevIndex < userSuggestions.length - 1 ? prevIndex + 1 : 0
                );
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                setSelectedSuggestionIndex((prevIndex) =>
                    prevIndex > 0 ? prevIndex - 1 : userSuggestions.length - 1
                );
            } else if (e.key === 'Enter') {
                e.preventDefault();
                handleSuggestionInsert(userSuggestions[selectedSuggestionIndex]);
            } else if (e.key === 'Escape') {
                e.preventDefault();
                setShowSuggestions(false);
                setSelectedSuggestionIndex(0);
                quillRef.current.focus();
            }
        } else {
            if (e.key === 'Escape') {
                e.preventDefault();
                setShowSuggestions(false);
                setSelectedSuggestionIndex(0);
                quillRef.current.focus();
            } else if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
            }
        }
    };
    const handleKeyUp = (e) => {
        const editor = quillRef.current.getEditor();
        const position = editor.getSelection()?.index || 0;
        setCaretPosition(position);
        const content = editor.getText(0, position);
        const lastWord = content.split(/\s/).pop();
        if (lastWord.startsWith("@")) {
            const query = lastWord.slice(1).toLowerCase();
            const mentionedUsernames = getMentionedUsernames();
            const channel = channels.find(ch => ch.roomId === selectedTextChannel);
            const allowedUserSet = new Set(channel?.allowedUsers || []);
            if (currentUser !== channel?.ownerId) {
                allowedUserSet.add(channel.ownerId);
            }
            const suggestions = Array.from(allowedUserSet)
                .filter(name => name.toLowerCase().includes(query))
                .sort((a, b) => {
                    const aMentioned = mentionedUsernames.includes(a);
                    const bMentioned = mentionedUsernames.includes(b);
                    if (aMentioned && !bMentioned) return 1;
                    if (!aMentioned && bMentioned) return -1;
                    return 0;
                });
            setUserSuggestions(suggestions);
            setShowSuggestions(suggestions.length > 0);
            setSelectedSuggestionIndex(0);
            const cursorBounds = editor.getBounds(position);
            setSuggestionPosition({
                top: cursorBounds.bottom,
                left: cursorBounds.left,
            });
        } else {
            setShowSuggestions(false);
            setSelectedSuggestionIndex(0);
        }
    };
    useEffect(() => {
        if (showSuggestions && suggestionListRef.current) {
            suggestionListRef.current.focus();
        }
    }, [showSuggestions]);
    const handleSuggestionKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setSelectedSuggestionIndex((prevIndex) =>
                prevIndex < userSuggestions.length - 1 ? prevIndex + 1 : 0
            );
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedSuggestionIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : userSuggestions.length - 1
            );
        } else if (e.key === 'Enter') {
            e.preventDefault();
            handleSuggestionInsert(userSuggestions[selectedSuggestionIndex]);
        } else if (e.key === 'Escape') {
            setShowSuggestions(false);
            setSelectedSuggestionIndex(0);
            quillRef.current.focus();
        }
    };
    const handleSuggestionClick = (userName) => {
        handleSuggestionInsert(userName);
    };
    const loadInitialMessages = () => {
        const lastThreeDays = datesArray.slice(0, 3);
        const initialMessages = messages.filter(msg => {
            const msgDate = new Date(msg.sentAt).toDateString();
            return lastThreeDays.includes(msgDate);
        });
        setVisibleMessages(initialMessages);
        dateIndexRef.current = 3;
    };
    const loadMoreMessages = () => {
        if (dateIndexRef.current >= datesArray.length || loading) {
            return;
        }
        setLoading(true);
        const dateToLoad = datesArray[dateIndexRef.current];
        const newMessages = messages.filter(msg => {
            const msgDate = new Date(msg.sentAt).toDateString();
            return msgDate === dateToLoad;
        });
        setVisibleMessages(prevMessages => [...newMessages, ...prevMessages]);
        dateIndexRef.current += 1;
        setLoading(false);
    };
    useEffect(() => {
        if (messages.length === 0) {
            return;
        }
        loadInitialMessages();
        // eslint-disable-next-line
    }, [messages]);
    const handleScroll = () => {
        if (messagesPanelRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = messagesPanelRef.current;
            const threshold = 100;
            const atBottom = scrollHeight - (scrollTop + clientHeight) <= threshold;
            setIsAtBottom(atBottom);
            setShowScrollToBottom(!atBottom);
            if (scrollTop < threshold && !loading) {
                loadMoreMessages();
            }
        }
    };
    const scrollToBottom = () => {
        if (messagesPanelRef.current) {
            messagesPanelRef.current.scrollTop = messagesPanelRef.current.scrollHeight;
            setIsAtBottom(true);
            setShowScrollToBottom(false);
        }
    };
    useEffect(() => {
        if (isAtBottom) {
            scrollToBottom();
        }
    }, [visibleMessages, isAtBottom]);
    const groupMessagesByDate = (messages) => {
        const groups = {};
        messages.forEach(message => {
            const date = new Date(message.sentAt);
            const dateKey = date.toLocaleDateString('tr-TR', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                weekday: 'long'
            });
            if (!groups[dateKey]) {
                groups[dateKey] = [];
            }
            groups[dateKey].push(message);
        });
        return groups;
    };
    const DateSeparator = ({ date }) => (
        <div className="date-separator">
            <div className="date-line"></div>
            <div className="date-text">{date}</div>
            <div className="date-line"></div>
        </div>
    );
    const quillModules = {
        toolbar: [
            [{ 'font': [] }, { 'size': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'header': [1, 2, 3, false] }, { 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'video'],
            ['clean']
        ],
    };
    const quillFormats = [
        'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
        'color', 'background',
        'header', 'align',
        'list', 'bullet', 'indent',
        'link', 'video',
        'mention'
    ];
    return (
        <div className="kaminari-messages-container">
            <div
                className="kaminari-messages-panel"
                ref={messagesPanelRef}
                onScroll={handleScroll}
            >
                {loading && (
                    <div className="loading-indicator">
                        <div className="loading-spinner"></div>
                    </div>
                )}
                {Object.entries(groupMessagesByDate(visibleMessages)).map(([date, dateMessages]) => (
                    <div key={date}>
                        <DateSeparator date={date} />
                        {dateMessages.map((message, index) => {
                            const isOwnMessage = message.senderId === currentUser;
                            const userImage = userImages.find(img => img.userName === message.senderId);
                            const senderInitial = message.senderId
                                ? message.senderId.charAt(0).toUpperCase()
                                : '?';
                            const sentAt = message.sentAt
                                ? new Date(message.sentAt).toLocaleString('tr-TR', {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })
                                : 'Unknown';
                            return (
                                <div key={index} className={`message-row ${isOwnMessage ? 'own-message' : 'other-message'}`}>
                                    {!isOwnMessage && (
                                        <div className="user-circle">
                                            {userImage ? (
                                                <img
                                                    src={`data:image/jpeg;base64,${userImage.base64ImageString}`}
                                                    alt={message.senderId}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                            ) : (
                                                senderInitial
                                            )}
                                        </div>
                                    )}
                                    <div className="message-bubble-container">
                                        {!isOwnMessage && (
                                            <div className="sender-name">{message.senderId}</div>
                                        )}
                                        <div className="message-bubble">
                                            <div
                                                className={`message-content`}
                                                dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(message.messageContent, {
                                                        ADD_TAGS: ['iframe'],
                                                        ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'src', 'width', 'height', 'style'],
                                                        FORBID_TAGS: ['img'],
                                                        ALLOW_DATA_ATTR: false,
                                                    })
                                                }}
                                            />
                                            <div className="message-time">{sentAt}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
            {showScrollToBottom && (
                <button
                    className="scroll-to-bottom-button"
                    onClick={(e) => {
                        e.preventDefault();
                        scrollToBottom();
                    }}
                >
                    <FontAwesomeIcon icon={faArrowDown} />
                </button>
            )}
            <div className="message-input-container">
                {showSuggestions && (
                    <div
                        className="user-suggestions"
                        ref={suggestionListRef}
                        tabIndex="0"
                        onKeyDown={handleSuggestionKeyDown}
                        style={{ top: suggestionPosition.top, left: suggestionPosition.left }}
                    >
                        {userSuggestions.map((userName, index) => {
                            const userImage = userImages.find(img => img.userName === userName);
                            return (
                                <div
                                    key={index}
                                    className={`suggestion-item ${index === selectedSuggestionIndex ? 'selected' : ''}`}
                                    onClick={() => handleSuggestionClick(userName)}
                                    onMouseDown={(e) => {
                                        e.preventDefault();
                                        handleSuggestionClick(userName);
                                    }}
                                >
                                    <div className="suggestion-user-circle">
                                        {userImage ? (
                                            <img
                                                src={`data:image/jpeg;base64,${userImage.base64ImageString}`}
                                                alt={userName}
                                                className="suggestion-user-image"
                                            />
                                        ) : (
                                            <span className="suggestion-user-initial">
                                                {userName.charAt(0).toUpperCase()}
                                            </span>
                                        )}
                                    </div>
                                    <span className="suggestion-username">@{userName}</span>
                                </div>
                            );
                        })}
                    </div>
                )}
                <InputGroup>
                    <ReactQuill
                        ref={quillRef}
                        theme="snow"
                        value={messageContent}
                        onChange={(content) => {
                            const sanitizedContent = sanitizeMessageContent(content);
                            setMessageContent(sanitizedContent);
                        }}
                        onKeyUp={handleKeyUp}
                        onKeyDown={handleKeyDown}
                        className="message-input-editor"
                        modules={quillModules}
                        placeholder="Mesajınızı yazın..."
                        formats={quillFormats}
                    />
                </InputGroup>
            </div>
        </div>
    );
};
//------------------------ LeftPanel ------------------------------------------//
const LeftPanel = ({
    userImages,
    noiseSuppressionOn,
    setNoiseSuppressionOn,
    videoOn,
    setVideoOn,
    setRemoteStreams,
    peerConnections,
    localStream,
    micOn,
    setMicOn,
    soundOn,
    setSoundOn,
    channels,
    selectedTextChannel,
    setSelectedTextChannel,
    selectedVoiceChannel,
    setSelectedVoiceChannel,
    lastSelectedChannel,
    setLastSelectedChannel,
    user,
    userJoinChannelAction,
    userLeftChannelAction,
    voiceChannelUsers,
    fetchMessagesForChannel,
    screenSharingOn,
    setScreenSharingOn,
    setUserSettingsOpen,
    userSettingsOpen,

    onOpenCreateChannelModal,
    onOpenEditChannelModal,
}) => {

    const userName = user?.userName || '';
    const userAvatar = user?.avatarUrl || null;

    // -- Filtreleme: My Channels --
    const myChannels = useMemo(() => {
        return channels.filter((ch) => {
            if (ch.status === 2) return false; // Deleted
            return ch.ownerId === userName;
        });
    }, [channels, userName]);

    // -- Filtreleme: Other Channels --
    const otherChannels = useMemo(() => {
        return channels.filter((ch) => {
            if (ch.status === 2) return false;
            if (ch.ownerId === userName) return false;
            if (ch.status === 0) {
                if (Array.isArray(ch.allowedUsers) && ch.allowedUsers.includes(userName)) {
                    return true;
                }
                return false;
            }
            return false;
        });
    }, [channels, userName]);

    // -- Arama State'leri --
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    // Tüm kanallar (myChannels + otherChannels)
    const allChannels = useMemo(() => [...myChannels, ...otherChannels], [myChannels, otherChannels]);

    // Arama işlevi
    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        if (value.length > 1) {
            setIsSearching(true);
            const results = allChannels.filter(ch =>
                ch.channelName.toLowerCase().includes(value.toLowerCase()) ||
                (ch.channelDescription &&
                    ch.channelDescription.toLowerCase().includes(value.toLowerCase()))
            );
            setSearchResults(results);
            setShowSuggestions(true);
        } else {
            setIsSearching(false);
            setSearchResults([]);
            setShowSuggestions(false);
        }
    };

    // Suggestion item tıklama
    const handleSuggestionClick = (channel) => {
        setSearchTerm('');
        setIsSearching(false);
        setShowSuggestions(false);
        handleChannelClick(channel);
    };

    // Aramayı temizle
    const clearSearch = () => {
        setSearchTerm('');
        setIsSearching(false);
        setSearchResults([]);
        setShowSuggestions(false);
    };

    // Arama aktifse, My Channels'ı da filtreleyelim
    const filteredMyChannels = useMemo(() => {
        if (!isSearching || searchTerm.length < 2) {
            return myChannels;
        }
        return myChannels.filter(ch =>
            ch.channelName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (ch.channelDescription &&
                ch.channelDescription.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }, [myChannels, isSearching, searchTerm]);

    // Arama aktifse, Other Channels'ı da filtreleyelim
    const filteredOtherChannels = useMemo(() => {
        if (!isSearching || searchTerm.length < 2) {
            return otherChannels;
        }
        return otherChannels.filter(ch =>
            ch.channelName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (ch.channelDescription &&
                ch.channelDescription.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }, [otherChannels, isSearching, searchTerm]);

    // Kanal click
    const handleChannelClick = (channel) => {
        if (channel.channelType === 1) {
            // Voice
            if (selectedVoiceChannel === channel.roomId) {
                setLastSelectedChannel(channel);
            } else {
                if (selectedVoiceChannel) {
                    Object.values(peerConnections.current).forEach(pc => pc.close());
                    peerConnections.current = {};
                    setRemoteStreams({});
                    userLeftChannelAction(selectedVoiceChannel);
                }
                setSelectedVoiceChannel(channel.roomId);
                localStorage.setItem("LastSelectedVoiceChannel", channel.roomId);
                userJoinChannelAction(channel.roomId);
                setLastSelectedChannel(channel);
            }
        } else {
            // Text
            setSelectedTextChannel(channel.roomId);
            fetchMessagesForChannel(channel.roomId);
            setLastSelectedChannel(channel);
        }
    };

    // Kanal kartını render
    const renderChannelCard = (channel, isMyChannel) => {
        const isTextSelected = selectedTextChannel === channel.roomId;
        const isVoiceSelected = selectedVoiceChannel === channel.roomId;
        const isActive = lastSelectedChannel?.roomId === channel.roomId;
        const icon = channel.channelType === 1 ? faMicrophoneIcon : faComments;

        let cardClass = "channel-card";
        if (isTextSelected || isVoiceSelected || isActive) {
            cardClass += " channel-card-selected";
        }

        return (
            <div
                key={channel.roomId}
                className={cardClass}
                data-type={channel.channelType === 1 ? "voice" : "text"}
                onClick={() => handleChannelClick(channel)}
            >
                <div className="channel-card-header">
                    <div className="channel-header-left">
                        <FontAwesomeIcon icon={icon} className="channel-icon" />
                        <span className="channel-name">{channel.channelName}</span>
                    </div>
                    {isMyChannel && channel.ownerId === userName && (
                        <FontAwesomeIcon
                            icon={faPen}
                            className="channel-edit-icon"
                            title="Edit Channel"
                            onClick={(e) => {
                                e.stopPropagation();
                                onOpenEditChannelModal(channel);
                            }}
                        />
                    )}
                </div>

                {channel.channelDescription && (
                    <div className="channel-card-body">
                        <p className="channel-description">{channel.channelDescription}</p>
                    </div>
                )}

                {channel.channelType === 1 && (
                    <div className="channel-card-footer">
                        <div className="channel-footer-title">Active Users</div>
                        <div className="active-users-container">
                            {(voiceChannelUsers[channel.roomId] || []).length > 0 ? (
                                (voiceChannelUsers[channel.roomId] || []).map(({ userName }, idx) => {
                                    const userImage = userImages.find(img => img.userName === userName);
                                    return (
                                        <div key={idx} className="user-avatar-wrapper">
                                            {userImage ? (
                                                <img
                                                    src={`data:image/jpeg;base64,${userImage.base64ImageString}`}
                                                    alt={userName}
                                                    className="user-avatar-img"
                                                />
                                            ) : (
                                                <div className="user-avatar-placeholder">
                                                    {userName?.[0] || '?'}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="no-users">No active users</div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <Col md="3" className="kaminari-left modern-panel">
            {/* Üst Bar */}
            <div className="kaminari-top-bar compact-top-bar">
                {/* Arama çubuğu */}
                <div className="compact-search-section">
                    <FontAwesomeIcon
                        icon={faSearch}
                        className={`compact-search-icon ${isSearching ? 'searching' : ''}`}
                    />
                    <input
                        type="text"
                        placeholder="Search..."
                        className="compact-search-input"
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                    {searchTerm && (
                        <button className="compact-clear-search" onClick={clearSearch}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    )}
                    {showSuggestions && searchResults.length > 0 && (
                        <div className="compact-suggestions">
                            {searchResults.map((ch) => (
                                <div
                                    key={ch.roomId}
                                    className="compact-suggestion-item"
                                    onClick={() => handleSuggestionClick(ch)}
                                >
                                    <strong>{ch.channelName}</strong>
                                    {ch.channelDescription && (
                                        <span className="suggestion-desc"> - {ch.channelDescription}</span>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                    {showSuggestions && searchResults.length === 0 && (
                        <div className="compact-suggestions no-result">
                            <em>No matches found</em>
                        </div>
                    )}
                </div>

                {/* Yeni Kanal Butonu */}
                <button
                    className="compact-create-channel-btn"
                    onClick={onOpenCreateChannelModal}
                    title="Create Channel"
                >
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>

            {/* Kanalların Bulunduğu Scrollable Container */}
            <div className="channel-lists-container">
                {/* MY CHANNELS */}
                <div className="channel-section">
                    <h5 className="channel-section-title">My Channels</h5>
                    <div className="channel-section-content">
                        {/* TransitionGroup + CSSTransition => animasyonlu giriş/çıkış */}
                        <TransitionGroup component={null}>
                            {filteredMyChannels.map((ch) => (
                                <CSSTransition
                                    key={ch.roomId}
                                    timeout={300}
                                    classNames="fade"
                                >
                                    {renderChannelCard(ch, true)}
                                </CSSTransition>
                            ))}
                        </TransitionGroup>
                    </div>
                </div>

                {/* OTHER CHANNELS */}
                <div className="channel-section">
                    <h5 className="channel-section-title">Other Channels</h5>
                    <div className="channel-section-content">
                        <TransitionGroup component={null}>
                            {filteredOtherChannels.map((ch) => (
                                <CSSTransition
                                    key={ch.roomId}
                                    timeout={300}
                                    classNames="fade"
                                >
                                    {renderChannelCard(ch, false)}
                                </CSSTransition>
                            ))}
                        </TransitionGroup>
                    </div>
                </div>
            </div>

            {/* Alt butonlar */}
            <div className="kaminari-button-panel">
                <div
                    className="kaminari-button"
                    onClick={() => {
                        setNoiseSuppressionOn(!noiseSuppressionOn);
                    }}
                >
                    <FontAwesomeIcon icon={noiseSuppressionOn ? faBellSlash : faBell} />
                </div>

                <div
                    className={`kaminari-button ${micOn ? 'active' : ''}`}
                    onClick={() => {
                        setMicOn(!micOn);
                    }}
                >
                    <FontAwesomeIcon icon={micOn ? faMicrophoneIcon : faMicrophoneSlash} />
                </div>

                <div
                    className={`kaminari-button ${soundOn ? 'active' : ''}`}
                    onClick={() => {
                        document.querySelectorAll('audio').forEach(audio => {
                            audio.muted = !soundOn;
                        });
                        setSoundOn(!soundOn);
                    }}
                >
                    <FontAwesomeIcon icon={soundOn ? faHeadphones : faVolumeMute} />
                </div>

                <div
                    className={`kaminari-button ${videoOn ? 'active' : ''}`}
                    onClick={() => {
                        setVideoOn(!videoOn);
                    }}
                    disabled={screenSharingOn}
                    style={{ opacity: screenSharingOn ? 0.5 : 1 }}
                >
                    <FontAwesomeIcon icon={videoOn ? faVideo : faVideoSlash} />
                </div>

                <div
                    className={`kaminari-button ${screenSharingOn ? 'active' : ''}`}
                    onClick={() => {
                        setScreenSharingOn(!screenSharingOn);
                    }}
                    disabled={videoOn}
                    style={{ opacity: videoOn ? 0.5 : 1 }}
                >
                    <FontAwesomeIcon icon={faDesktop} />
                </div>

                <div
                    className="kaminari-button"
                    onClick={() => {
                        setUserSettingsOpen(!userSettingsOpen);
                    }}
                >
                    <FontAwesomeIcon icon={faGear} />
                </div>

                <div
                    className="kaminari-button kaminari-button-danger"
                    onClick={() => {
                        if (selectedVoiceChannel) {
                            userLeftChannelAction(selectedVoiceChannel);
                            setSelectedVoiceChannel(null);
                            localStorage.setItem("LastSelectedVoiceChannel", null);
                            Object.values(peerConnections.current).forEach(pc => pc.close());
                            peerConnections.current = {};
                            setRemoteStreams({});
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faPhoneSlash} />
                </div>
            </div>
        </Col>
    );
};

//------------------------ VideoPopup -----------------------------------------//
const VideoPopup = React.memo(function VideoPopup({
    stream,
    isMuted,
    finalVolume,
    onMuteToggle,
    onVolumeChange,
    userVolume,
    selectedAudioOutputDeviceId,
    userName
}) {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
        }
    }, [stream]);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.muted = isMuted;
            videoRef.current.volume = finalVolume;
        }
    }, [isMuted, finalVolume]);

    useEffect(() => {
        if (videoRef.current && selectedAudioOutputDeviceId) {
            if (typeof videoRef.current.sinkId !== 'undefined') {
                videoRef.current
                    .setSinkId(selectedAudioOutputDeviceId)
                    .catch((err) => {
                        console.warn('setSinkId error: ', err);
                    });
            }
        }
    }, [selectedAudioOutputDeviceId]);

    const handleVolumeChange = useCallback((e) => {
        const volume = parseInt(e.target.value, 10);
        // “userVolume” mapinde [userName] key'ine yazılacak.
        onVolumeChange(volume);
    }, [onVolumeChange]);

    const handleTogglePictureInPicture = useCallback(async () => {
        if (!videoRef.current) return;
        try {
            if (document.pictureInPictureElement) {
                await document.exitPictureInPicture();
            } else {
                await videoRef.current.requestPictureInPicture();
            }
        } catch (error) {
            console.error('Picture-in-Picture error:', error);
        }
    }, []);

    const handleToggleFullscreen = useCallback(() => {
        if (!videoRef.current) return;
        if (!document.fullscreenElement) {
            videoRef.current.requestFullscreen().catch((err) => {
                console.error('Fullscreen error:', err);
            });
        } else {
            document.exitFullscreen();
        }
    }, []);

    return (
        <div className="video-stream-wrapper">
            <video
                ref={videoRef}
                autoPlay
                playsInline
                className="stream-video"
            />
            <div className="stream-overlay">
                <div className="stream-controls">
                    <div className="control-group left">
                        <button
                            className={`control-btn ${isMuted ? 'active' : ''}`}
                            onClick={onMuteToggle}
                        >
                            <i className={`fas ${isMuted ? 'fa-volume-mute' : 'fa-volume-up'}`}></i>
                        </button>
                        <div className="volume-slider">
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={userVolume}
                                onChange={handleVolumeChange}
                                className="slider"
                            />
                        </div>
                    </div>
                    <div className="control-group right">
                        <button
                            className="control-btn"
                            onClick={handleTogglePictureInPicture}
                        >
                            <i className="fas fa-external-link-square-alt"></i>
                        </button>
                        <button
                            className="control-btn"
                            onClick={handleToggleFullscreen}
                        >
                            <i className="fas fa-expand"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});

//------------------------ VoicePanel -----------------------------------------//
const VoicePanel = React.memo(function VoicePanel({
    usersInVoiceChannel,
    remoteStreams,
    localStream,
    myConnectionId,
    userImages,
    onOpenVolumeMenu,
    mutedUsers,
    soundOn,
    userVolumes,
    detailedVolume,
    fineVolume,
    videoOn,
    setUserVolumes,
    setMutedUsers,
    selectedAudioOutputDeviceId,
    onOpenVideoPopup
}) {
    const userCount = usersInVoiceChannel.length;
    const gridClass = userCount <= 4 ? 'grid-2x2' : 'grid-3x3';

    // useMemo ile verileri oluşturuyoruz
    const voiceUsersData = useMemo(() => {
        return usersInVoiceChannel.map(({ userName, connectionId }) => {
            const isLocal = connectionId === myConnectionId;
            const stream = isLocal ? localStream : remoteStreams[connectionId];
            const userImageObj = userImages.find((img) => img.userName === userName) || null;
            return {
                userName,
                connectionId,
                stream,
                userImage: userImageObj?.base64ImageString || null,
                isLocal
            };
        });
    }, [usersInVoiceChannel, localStream, remoteStreams, myConnectionId, userImages]);

    // finalVolume ve isMuted hesaplamasını parent'ta da yapabilirsiniz,
    // ama genelde "VoiceUser" componenti her userName'i bilsin diye,
    // computeFinalVolume'u parent'tan prop olarak geçebiliriz.
    // Burada basitçe parent'taki mantığı kopyalıyoruz:
    const computeFinalVolume = (uName) => {
        const mainVolume = userVolumes[uName] ?? 100;  // userVolumes[userName] or 100
        const isUserMuted = mutedUsers[uName];         // mutedUsers[userName]
        if (!soundOn) return 0;                       // tüm sesi kapat seçeneği
        if (isUserMuted) return 0;
        if (mainVolume < 10 && detailedVolume) {
            return (mainVolume * fineVolume) / 100;
        }
        return mainVolume / 100;
    };

    return (
        <div className={`voice-panel-container ${gridClass}`}>
            {voiceUsersData.map((user) => {
                const { userName, connectionId, isLocal, stream, userImage } = user;
                // Final volume, userName’e göre hesaplanıyor:
                const finalVolume = computeFinalVolume(userName);
                const isMuted = mutedUsers[userName] || !soundOn || finalVolume === 0;

                return (
                    <VoiceUser
                        key={connectionId}
                        userName={userName}
                        connectionId={connectionId}
                        stream={stream}
                        isLocal={isLocal}
                        userImage={userImage}
                        videoOn={videoOn}
                        onOpenVolumeMenu={onOpenVolumeMenu}
                        isMuted={isMuted}
                        finalVolume={finalVolume}
                        selectedAudioOutputDeviceId={selectedAudioOutputDeviceId}
                        setMutedUsers={setMutedUsers}
                        onOpenVideoPopup={onOpenVideoPopup}
                    />
                );
            })}
        </div>
    );
});

//------------------------ VoiceUser ------------------------------------------//
const VoiceUser = React.memo(function VoiceUser({
    connectionId,
    userName,
    stream,
    isLocal,
    userImage,
    onOpenVolumeMenu,
    isMuted,
    finalVolume,
    videoOn,
    selectedAudioOutputDeviceId,
    setMutedUsers,
    onOpenVideoPopup,
}) {
    const videoRef = useRef(null);
    const [volume, setVolume] = useState(0);
    const [hasVideoState, setHasVideoState] = useState(false);

    const updateHasVideo = useCallback(() => {
        if (isLocal) {
            // Local user: videoOn state'ine bakıyoruz
            setHasVideoState(!!videoOn);
        } else if (stream) {
            const videoTracks = stream.getVideoTracks();
            const hasLiveVideo =
                videoTracks.length > 0 &&
                videoTracks.some((track) => track.readyState === 'live');
            setHasVideoState(hasLiveVideo);
        } else {
            setHasVideoState(false);
        }
    }, [isLocal, stream, videoOn]);

    useEffect(() => {
        updateHasVideo();
        if (stream && !isLocal) {
            const handleTrackAdded = () => updateHasVideo();
            const handleTrackRemoved = () => updateHasVideo();
            stream.addEventListener('addtrack', handleTrackAdded);
            stream.addEventListener('removetrack', handleTrackRemoved);
            return () => {
                stream.removeEventListener('addtrack', handleTrackAdded);
                stream.removeEventListener('removetrack', handleTrackRemoved);
            };
        }
    }, [stream, isLocal, updateHasVideo]);

    // Volume analiz
    useEffect(() => {
        if (!stream || stream.getAudioTracks().length === 0) {
            setVolume(0);
            return;
        }
        let audioContext = null;
        let analyser = null;
        let source = null;
        let dataArray = null;
        let intervalId = null;

        try {
            audioContext = new (window.AudioContext || window.webkitAudioContext)();
            analyser = audioContext.createAnalyser();
            source = audioContext.createMediaStreamSource(stream);
            source.connect(analyser);
            analyser.fftSize = 256;
            dataArray = new Uint8Array(analyser.frequencyBinCount);

            const detectVolume = () => {
                analyser.getByteFrequencyData(dataArray);
                const avgVolume =
                    dataArray.reduce((a, b) => a + b, 0) / dataArray.length;
                setVolume((prev) => {
                    if (Math.abs(prev - avgVolume) > 2) {
                        return avgVolume;
                    }
                    return prev;
                });
            };
            intervalId = setInterval(detectVolume, 250);
        } catch (error) {
            console.log(
                `[VoiceUser] Error setting up audio analysis for ${userName}, connId=${connectionId}`,
                error
            );
        }
        return () => {
            if (intervalId) clearInterval(intervalId);
            if (audioContext) {
                audioContext.close();
            }
        };
    }, [stream, userName, connectionId]);

    // Video stream'i videoRef'e bağlama
    useEffect(() => {
        if (!videoRef.current) return;
        if (stream && hasVideoState) {
            videoRef.current.srcObject = stream;
        } else {
            videoRef.current.srcObject = null;
        }
    }, [stream, hasVideoState]);

    // Mute / volume => videoRef'e uygula
    useEffect(() => {
        if (!videoRef.current) return;
        // Local user kendini duymayacak
        if (isLocal) {
            videoRef.current.muted = true;
            videoRef.current.volume = 0;
        } else {
            videoRef.current.muted = isMuted;
            videoRef.current.volume = finalVolume;
        }
    }, [isLocal, isMuted, finalVolume]);

    // Hoparlör seçimi (setSinkId)
    useEffect(() => {
        if (!isLocal && videoRef.current && selectedAudioOutputDeviceId) {
            if (typeof videoRef.current.sinkId !== 'undefined') {
                videoRef.current
                    .setSinkId(selectedAudioOutputDeviceId)
                    .catch((err) => {
                        console.warn('setSinkId error: ', err);
                    });
            }
        }
    }, [selectedAudioOutputDeviceId, isLocal]);

    const volumeLevel = Math.min(volume / 100, 1);

    // contextMenu olayı => userName üzerinden menü açıyoruz
    const handleContextMenu = useCallback(
        (e) => {
            e.preventDefault();
            onOpenVolumeMenu(e.clientX, e.clientY, userName);
        },
        [onOpenVolumeMenu, userName]
    );

    const handleOpenVideoPopup = useCallback(() => {
        onOpenVideoPopup(connectionId, userName);
    }, [connectionId, userName, onOpenVideoPopup]);

    return (
        <div
            className={`meet-user-container ${volumeLevel > 0.1 ? 'is-speaking' : ''}`}
            onContextMenu={handleContextMenu}
        >
            <div className="meet-user-wrapper">
                {hasVideoState ? (
                    <div className="meet-video-container">
                        <video
                            ref={videoRef}
                            autoPlay
                            playsInline
                            muted={isLocal || isMuted}
                            className="meet-video"
                        />
                        <div className="meet-video-overlay">
                            <div className="meet-user-controls">
                                {!isLocal && (
                                    <button className="meet-control-btn" onClick={handleOpenVideoPopup}>
                                        <FaEye />
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="meet-avatar-container">
                        {userImage ? (
                            <img
                                src={`data:image/jpeg;base64,${userImage}`}
                                alt={userName}
                                className="meet-avatar-image"
                            />
                        ) : (
                            <div className="meet-avatar-fallback">
                                {userName.charAt(0).toUpperCase()}
                            </div>
                        )}
                    </div>
                )}
                <div className="meet-user-footer">
                    <div className="meet-user-name">
                        {userName}
                        {isMuted && (
                            <span className="meet-mute-indicator">
                                <i className="fas fa-microphone-slash" />
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});

//------------------------ KaminariCord -----------------------------------------//
export default function KaminariCord() {
    const postRequestSyncPromise = usePostRequestSyncPromise();
    const { allUsers, activeUsers } = useContext(UserContext);

    // ----------------- STATE’LER ----------------- //
    const [micOn, setMicOn] = useState(true);
    const [soundOn, setSoundOn] = useState(true);
    const [channels, setChannels] = useState([]);
    const [selectedTextChannel, setSelectedTextChannel] = useState(null);
    const [selectedVoiceChannel, setSelectedVoiceChannel] = useState(null);
    const [lastSelectedChannel, setLastSelectedChannel] = useState(null);
    const [voiceChannelUsers, setVoiceChannelUsers] = useState({});
    const [isChannelsLoaded, setIsChannelsLoaded] = useState(false);
    const [noiseSuppressionOn, setNoiseSuppressionOn] = useState(false);
    const [messages, setMessages] = useState([]);

    // SignalR ve WebRTC
    const signalConnection = useRef(null);
    const peerConnections = useRef({});
    const [remoteStreams, setRemoteStreams] = useState({});

    // Kamera & Ekran Paylaşımı
    const [videoOn, setVideoOn] = useState(false);
    const [screenSharingOn, setScreenSharingOn] = useState(false);

    // Ekran paylaşım çözünürlük & FPS seçim state’leri
    const [selectedResolution, setSelectedResolution] = useState("1080p");
    const [selectedFrameRate, setSelectedFrameRate] = useState(30);
    const resolutionMap = {
        "4K": { width: 3840, height: 2160 },
        "2K": { width: 2560, height: 1440 },
        "1080p": { width: 1920, height: 1080 },
        "720p": { width: 1280, height: 720 },
        "480p": { width: 854, height: 480 },
        "360p": { width: 640, height: 360 }
    };

    // Cihazlar
    const [audioInputDevices, setAudioInputDevices] = useState([]);
    const [audioOutputDevices, setAudioOutputDevices] = useState([]);
    const [videoInputDevices, setVideoInputDevices] = useState([]);
    const [selectedAudioInputDeviceId, setSelectedAudioInputDeviceId] =
        useState("");
    const [selectedAudioOutputDeviceId, setSelectedAudioOutputDeviceId] =
        useState("");
    const [selectedVideoInputDeviceId, setSelectedVideoInputDeviceId] =
        useState("");

    // Local Track Referansları
    const localAudioTrackRef = useRef(null);
    const localVideoTrackRef = useRef(null);
    const localScreenTrackRef = useRef(null);
    const localStream = useRef(new MediaStream());

    // Kullanıcı & imaj & volume vb.
    const [userImages, setUserImages] = useState([]);
    const [allUserNames, setAllUserNames] = useState([]);
    // Volume & Mute bilgileri "userName" bazlı
    const [userVolumes, setUserVolumes] = useState({});
    const [mutedUsers, setMutedUsers] = useState({});

    // Context menü (ses ayarı)
    const [volumeMenuVisible, setVolumeMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    // Hangi kullanıcının context menüsünü açtığımızı userName olarak tutalım
    const [targetUserName, setTargetUserName] = useState(null);

    const [detailedVolume, setDetailedVolume] = useState(false);
    const [fineVolume, setFineVolume] = useState(1.0);

    // Settings Modal
    const [userSettingsOpen, setUserSettingsOpen] = useState(false);

    // Video Popup
    const [isVideoPopupOpen, setIsVideoPopupOpen] = useState(false);
    const [popupUserConnectionId, setPopupUserConnectionId] = useState(null);
    const [popupUserName, setPopupUserName] = useState("");

    // Kullanıcı
    const user = JSON.parse(localStorage.getItem("userData"));
    const userName = user?.userName || "";

    // Sesler
    const joinSoundRef = useRef(null);
    const leaveSoundRef = useRef(null);

    // ----------- CREATE CHANNEL / EDIT CHANNEL Modal State’leri ----------- //
    const [createChannelModalOpen, setCreateChannelModalOpen] = useState(false);

    // Edit channel
    const [editChannelModalOpen, setEditChannelModalOpen] = useState(false);
    const [channelBeingEdited, setChannelBeingEdited] = useState(null);

    // Create Channel Form State
    const [channelName, setChannelName] = useState("");
    const [channelDescription, setChannelDescription] = useState("");
    const [channelType, setChannelType] = useState("Text"); // "Text" veya "Voice"
    const [channelStatus, setChannelStatus] = useState("Active"); // "Active" / "Inactive"
    const [selectedAllowedUsers, setSelectedAllowedUsers] = useState([]);

    // Edit Channel Form State
    const [editChannelName, setEditChannelName] = useState("");
    const [editChannelDescription, setEditChannelDescription] = useState("");
    const [editChannelType, setEditChannelType] = useState("Text");
    const [editChannelStatus, setEditChannelStatus] = useState("Active");
    const [editAllowedUsers, setEditAllowedUsers] = useState([]);
    const [mergedUsers, setMergedUsers] = useState([]);

    // Container ref
    const containerRef = useRef(null);

    // LocalStorage’den ayarları yükledikten sonra `true` olacak.
    const [loadedLocalStorage, setLoadedLocalStorage] = useState(false);

    // ------------------------------------------------
    // LOCALSTORAGE’DAN KULLANICI AYARLARINI YÜKLE (Cihaz Ayarları)
    // ------------------------------------------------
    useEffect(() => {
        const deviceSettingsJSON = localStorage.getItem("kaminariDeviceSettings");
        if (deviceSettingsJSON) {
            console.log(
                "[KaminariCord] Found 'kaminariDeviceSettings' in localStorage : ",
                deviceSettingsJSON
            );
            try {
                const {
                    audioInputDeviceId,
                    audioOutputDeviceId,
                    videoInputDeviceId,
                    frameRate,
                    resolution
                } = JSON.parse(deviceSettingsJSON);

                if (audioInputDeviceId) {
                    console.log(
                        "[KaminariCord] Setting audio input device from localStorage:",
                        audioInputDeviceId
                    );
                    setSelectedAudioInputDeviceId(audioInputDeviceId);
                }
                if (audioOutputDeviceId) {
                    console.log(
                        "[KaminariCord] Setting audio output device from localStorage:",
                        audioOutputDeviceId
                    );
                    setSelectedAudioOutputDeviceId(audioOutputDeviceId);
                }
                if (videoInputDeviceId) {
                    setSelectedVideoInputDeviceId(videoInputDeviceId);
                }
                if (frameRate) {
                    setSelectedFrameRate(frameRate);
                }
                if (resolution) {
                    setSelectedResolution(resolution);
                }
            } catch (error) {
                console.warn(
                    "Could not parse 'kaminariDeviceSettings' from localStorage:",
                    error
                );
            }
        }
        // LocalStorage okunması bitti, cihazları enumerate edebiliriz
        setLoadedLocalStorage(true);
    }, []);

    // ------------------------------------------------
    // LOCALSTORAGE’DAN userVolumes ve mutedUsers yükle
    // ------------------------------------------------
    useEffect(() => {
        try {
            const lsVolumes = localStorage.getItem("kaminariUserVolumes");
            const lsMuted = localStorage.getItem("kaminariMutedUsers");
            if (lsVolumes) {
                setUserVolumes(JSON.parse(lsVolumes));
            }
            if (lsMuted) {
                setMutedUsers(JSON.parse(lsMuted));
            }
        } catch (err) {
            console.warn("Failed to parse volume/mute settings from localStorage", err);
        }
    }, []);

    // userVolumes veya mutedUsers her değiştiğinde localStorage'ye yaz
    useEffect(() => {
        localStorage.setItem("kaminariUserVolumes", JSON.stringify(userVolumes));
    }, [userVolumes]);

    useEffect(() => {
        localStorage.setItem("kaminariMutedUsers", JSON.stringify(mutedUsers));
    }, [mutedUsers]);

    // ------------------------------------------------
    // KULLANICI AYARLARI DEĞİŞTİKÇE (Cihaz) LOCALSTORAGE’A YAZ
    // ------------------------------------------------
    useEffect(() => {
        const deviceSettings = {
            audioInputDeviceId: selectedAudioInputDeviceId,
            audioOutputDeviceId: selectedAudioOutputDeviceId,
            videoInputDeviceId: selectedVideoInputDeviceId,
            frameRate: selectedFrameRate,
            resolution: selectedResolution
        };
        localStorage.setItem("kaminariDeviceSettings", JSON.stringify(deviceSettings));
    }, [
        selectedAudioInputDeviceId,
        selectedAudioOutputDeviceId,
        selectedVideoInputDeviceId,
        selectedFrameRate,
        selectedResolution
    ]);

    // Tüm kullanıcı ve kullanıcı resimlerini state’e alma
    useEffect(() => {
        setUserImages(
            allUsers.map((u) => ({
                base64ImageString: u.base64ImageString,
                userName: u.userName
            }))
        );
        setAllUserNames(allUsers.map((u) => u.userName));
    }, [allUsers]);

    // Bazı ses dosyalarını hazırlama
    useEffect(() => {
        joinSoundRef.current = new Audio("/sounds/UserJoined.mp3");
        joinSoundRef.current.volume = 0.5;
        leaveSoundRef.current = new Audio("/sounds/UserLeft.mp3");
        leaveSoundRef.current.volume = 0.5;
    }, []);

    // Kanal & kullanıcı imajları & userNames için istek (KANALLARI GETİR)
    useEffect(() => {
        const getAllChannels = async () => {
            try {
                const response = await postRequestSyncPromise(
                    "CordChannel/GetAllCordChannel",
                    null
                );
                if (response.type === 0) {
                    const dbChannels = response.data;
                    const updatedVoiceChannelUsers = {};
                    dbChannels.forEach((channel) => {
                        if (channel.roomId && channel.activeUsers) {
                            updatedVoiceChannelUsers[channel.roomId] = channel.activeUsers;
                        }
                    });
                    setVoiceChannelUsers(updatedVoiceChannelUsers);
                    setChannels(dbChannels);
                    setIsChannelsLoaded(true);
                }
            } catch (error) {
                console.error("[KaminariCord] getAllChannels => error:", error);
            }
        };
        getAllChannels();
    }, [postRequestSyncPromise]);

    // LocalStorage yüklendikten sonra cihazları enumerate et
    useEffect(() => {
        if (loadedLocalStorage) {
            getMediaDevices();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadedLocalStorage]);

    // Bileşen unmount olurken cleanup
    useEffect(() => {
        return () => {
            if (localStream.current) {
                localStream.current.getTracks().forEach((track) => {
                    console.log(
                        "[KaminariCord] Component unmount => stopping local track:",
                        track.id,
                        track.kind
                    );
                    track.stop();
                });
            }
            // Tüm peerconnectionları kapat
            Object.values(peerConnections.current).forEach((pc) => {
                console.log("[KaminariCord] Component unmount => closing peerConnection");
                pc.close();
            });
            peerConnections.current = {};

            if (signalConnection.current) {
                console.log("[KaminariCord] Component unmount => stopping signalConnection");
                signalConnection.current.stop();
            }
        };
    }, []);

    // Kanallar yüklendikten sonra SignalR başlat
    useEffect(() => {
        if (isChannelsLoaded) {
            console.log("[KaminariCord] isChannelsLoaded => initializing SignalR");
            initializeSignalR();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChannelsLoaded]);

    useEffect(() => {
        console.log("Selected Voice Channel changed to:", selectedVoiceChannel);
    }, [selectedVoiceChannel]);


    // ------------------------------------------------
    // getMediaDevices => enumerateDevices ve fallback
    // ------------------------------------------------
    const getMediaDevices = async () => {
        console.log("[KaminariCord] getMediaDevices => enumerating devices...");
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const audioInputs = devices.filter((d) => d.kind === "audioinput");
            const audioOutputs = devices.filter((d) => d.kind === "audiooutput");
            const videoInputs = devices.filter((d) => d.kind === "videoinput");

            setAudioInputDevices(audioInputs);
            setAudioOutputDevices(audioOutputs);
            setVideoInputDevices(videoInputs);

            // AUDIO INPUT fallback
            const hasAudioInput = audioInputs.some(
                (d) => d.deviceId === selectedAudioInputDeviceId
            );
            if (!hasAudioInput) {
                if (audioInputs.length > 0) {
                    setSelectedAudioInputDeviceId(audioInputs[0].deviceId);
                } else {
                    setSelectedAudioInputDeviceId("");
                }
            }

            // AUDIO OUTPUT fallback
            const hasAudioOutput = audioOutputs.some(
                (d) => d.deviceId === selectedAudioOutputDeviceId
            );
            if (!hasAudioOutput) {
                if (audioOutputs.length > 0) {
                    setSelectedAudioOutputDeviceId(audioOutputs[0].deviceId);
                } else {
                    setSelectedAudioOutputDeviceId("");
                }
            }

            // VIDEO INPUT fallback
            const hasVideoInput = videoInputs.some(
                (d) => d.deviceId === selectedVideoInputDeviceId
            );
            if (!hasVideoInput) {
                if (videoInputs.length > 0) {
                    setSelectedVideoInputDeviceId(videoInputs[0].deviceId);
                } else {
                    setSelectedVideoInputDeviceId("");
                }
            }
        } catch (err) {
            console.error("Error enumerating devices:", err);
        }
    };

    // ------------------------------------------------
    // CREATE CHANNEL (Modal)
    // ------------------------------------------------
    const handleOpenCreateChannelModal = () => {
        setChannelName("");
        setChannelDescription("");
        setChannelType("Text");
        setChannelStatus("Active");
        setSelectedAllowedUsers([]);
        setCreateChannelModalOpen(true);
    };

    const handleCreateChannelSubmit = async () => {
        console.log("[KaminariCord] handleCreateChannelSubmit =>", {
            ownerId: userName,
            channelName,
            channelDescription,
            channelType,
            channelStatus,
            selectedAllowedUsers
        });
        const reqData = {
            data: [
                {
                    ownerId: userName,
                    channelName: channelName.trim(),
                    channelDescription: channelDescription.trim(),
                    channelType: channelType === "Voice" ? 1 : 0, // enum
                    status: channelStatus === "Active" ? 0 : 1, // 0=Active,1=Inactive
                    allowedUsers: selectedAllowedUsers
                }
            ]
        };
        try {
            const response = await postRequestSyncPromise(
                "CordChannel/CreateCordChannel",
                reqData
            );
            if (response.type === 0) {
                toast.success("Channel created successfully!");
                setCreateChannelModalOpen(false);
                refreshChannels();
            } else {
                toast.error("Failed to create channel");
            }
        } catch (err) {
            console.error("[KaminariCord] handleCreateChannelSubmit => error:", err);
            toast.error("Error while creating channel");
        }
    };

    const refreshChannels = async () => {
        try {
            const response = await postRequestSyncPromise(
                "CordChannel/GetAllCordChannel",
                null
            );
            if (response.type === 0) {
                const chData = response.data;
                setChannels(chData);
                const updatedVoiceChannelUsers = {};
                chData.forEach((channel) => {
                    if (channel.roomId && channel.activeUsers) {
                        updatedVoiceChannelUsers[channel.roomId] = channel.activeUsers;
                    }
                });
                setVoiceChannelUsers(updatedVoiceChannelUsers);
            }
        } catch (error) {
            console.error("Error refreshing channels:", error);
        }
    };

    // ------------------------------------------------
    // EDIT CHANNEL (Modal)
    // ------------------------------------------------
    const handleOpenEditChannelModal = (channel) => {
        console.log("[KaminariCord] handleOpenEditChannelModal => channel:", channel);
        const mergedUsers = [
            ...new Set([
                ...(channel.allowedUsers || []),
                ...(allUserNames || [])
            ])
        ];
        setChannelBeingEdited(channel);
        setEditChannelName(channel.channelName || "");
        setEditChannelDescription(channel.channelDescription || "");
        setEditChannelType(channel.channelType === 1 ? "Voice" : "Text");
        setEditChannelStatus(channel.status === 0 ? "Active" : "Inactive");
        setEditAllowedUsers(channel.allowedUsers || []);
        setMergedUsers(mergedUsers || []);
        setEditChannelModalOpen(true);
    };

    const handleEditChannelSubmit = async () => {
        if (!channelBeingEdited) return;
        const reqData = {
            data: [
                {
                    _id: channelBeingEdited._id,
                    roomId: channelBeingEdited.roomId,
                    ownerId: channelBeingEdited.ownerId,
                    channelName: editChannelName.trim(),
                    channelDescription: editChannelDescription.trim(),
                    channelType: editChannelType === "Voice" ? 1 : 0,
                    status: editChannelStatus === "Active" ? 0 : 1,
                    allowedUsers: editAllowedUsers
                }
            ]
        };

        try {
            const response = await postRequestSyncPromise(
                "CordChannel/UpdateCordChannel",
                reqData
            );
            if (response.type === 0) {
                toast.success("Channel updated successfully!");
                setEditChannelModalOpen(false);
                refreshChannels();
            } else {
                toast.error("Failed to update channel");
            }
        } catch (err) {
            console.error("[KaminariCord] handleEditChannelSubmit => error:", err);
            toast.error("Error updating channel");
        }
    };

    // ------------------------------------------------
    // USER JOIN / LEAVE ACTIONS
    // ------------------------------------------------
    const userJoinChannelAction = (selectedRoomId) => {
        console.log("[KaminariCord] userJoinChannelAction => roomId:", selectedRoomId);
        const reqData = {
            data: [
                {
                    roomId: selectedRoomId,
                    userToJoin: userName,
                    userConnectionId: JSON.parse(localStorage.getItem("connectionId"))
                }
            ]
        };
        postRequestSyncPromise("CordChannel/UserJoinChannel", reqData)
            .then((response) => {
                if (response.type === 0) {
                    console.log("[KaminariCord] userJoinChannelAction => success");
                }
            })
            .catch((error) => {
                console.error("API Error (userJoinChannelAction):", error);
            });
    };

    const userLeftChannelAction = (selectedRoomId) => {
        console.log("[KaminariCord] userLeftChannelAction => roomId:", selectedRoomId);
        const reqData = {
            data: [
                {
                    roomId: selectedRoomId,
                    userToLeave: userName,
                    userConnectionId: JSON.parse(localStorage.getItem("connectionId"))
                }
            ]
        };
        postRequestSyncPromise("CordChannel/UserLeftChannel", reqData)
            .then((response) => {
                if (response.type === 0) {
                    console.log("[KaminariCord] userLeftChannelAction => success");
                }
            })
            .catch((error) => {
                console.error("API Error (userLeftChannelAction):", error);
            });
    };

    // ------------------------------------------------
    // MESSAGE FETCH
    // ------------------------------------------------
    const fetchMessagesForChannel = async (channelId) => {
        console.log("[KaminariCord] fetchMessagesForChannel => channelId:", channelId);
        const reqData = { data: [{ roomId: channelId }] };
        try {
            const response = await postRequestSyncPromise(
                "CordMessage/GetAllMessagesByChannel",
                reqData
            );
            if (response.type === 0 && response.data) {
                console.log(
                    "[KaminariCord] fetchMessagesForChannel => loaded messages:",
                    response.data.length
                );
                setMessages(response.data);
            } else {
                console.error("Failed to load messages");
            }
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    };

    // ------------------------------------------------
    // SIGNALR
    // ------------------------------------------------
    const showErrorToast = (msg) => {
        toast.error(msg);
    };
    const showSuccessToast = (msg) => {
        toast.success(msg);
    };

    const initializeSignalR = async () => {
        console.log("[KaminariCord] initializeSignalR => building HubConnection...");
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl("https://api.kaminarivi.com.tr/chatHub")
            .withAutomaticReconnect([1000, 2000, 3000, 5000, 10000])
            .build();

        async function start(connection) {
            try {
                await connection.start();
                console.log(
                    "[KaminariCord] SignalR connected with connectionId:",
                    connection.connectionId
                );
                const connectionId = connection.connectionId;
                localStorage.setItem("connectionId", connectionId);

                // Text channel odalarına katılalım
                const textChannelIds = channels
                    .filter((ch) => ch.channelType !== 1)
                    .map((ch) => ch.roomId);

                const joinChannelRequests = textChannelIds.map((roomId) => ({
                    connectionId: connectionId,
                    RoomId: roomId
                }));

                const baseRequest = { Data: joinChannelRequests };
                await connection
                    .invoke("JoinTextChannel", baseRequest)
                    .then(() => console.log("[KaminariCord] Joined text channels successfully."))
                    .catch((err) => console.error("JoinTextChannel error: ", err));
            } catch (err) {
                console.error("Connection error: ", err);
                setTimeout(() => start(connection), 5000);
            }
        }

        start(newConnection);
        signalConnection.current = newConnection;

        newConnection.onreconnecting(() => {
            console.log("[KaminariCord] SignalR onreconnecting");
            showErrorToast("Connection Lost. Reconnecting...");
        });

        newConnection.onreconnected(async () => {
            console.log("[KaminariCord] SignalR onreconnected");
            showSuccessToast("Connection Reestablished.");
            const newConnId = newConnection.connectionId;
            localStorage.setItem("connectionId", newConnId);
            // -------- BURADA ÖNEMLİ KISIM --------
            // Kullanıcının hangi kanalda (ör. voice kanalda) aktif olduğunu belirliyoruz.
            // 1) Eğer tek bir voice channel'a katılınıyorsa, selectedVoiceChannel yeterli olabilir
            // 2) Ya da voiceChannelUsers içinden userName'i arayarak da bulabilirsiniz.
            let lastSelectedVoiceChannel = localStorage.getItem("LastSelectedVoiceChannel");
            if (lastSelectedVoiceChannel) {
                // Bu örnekte sadece *tek* bir sesli kanalda olduğunu varsayıyoruz
                // userName (localStorage'den ya da state'ten) + roomId + newConnId => API'ye gönderiyoruz

                console.log("[KaminariCord] Updating user connectionId in DB...");
                const requestData = {
                    data: [
                        {
                            RoomId: lastSelectedVoiceChannel,
                            UserName: userName,
                            ConnectionId: newConnId
                        }
                    ]
                };

                try {
                    // UpdateUserConnectionId metodu CordChannel içindeki ActiveUsers dizisinde
                    // userName eşleşen kaydın ConnectionId değerini newConnId ile güncelleyecek.
                    const response = await postRequestSyncPromise(
                        "CordChannel/UpdateUserConnectionId",
                        requestData
                    );
                    console.log("[KaminariCord] UpdateUserConnectionId response:", response);
                    if (response.type === 0) {
                        console.log("[KaminariCord] Successfully updated connectionId in DB");
                    } else {
                        console.warn("[KaminariCord] Could not update user connectionId in DB");
                    }
                } catch (err) {
                    console.error("[KaminariCord] Error updating user connectionId:", err);
                }
            }
        });

        newConnection.onclose(async (error) => {
            if (error) {
                console.error("[KaminariCord] SignalR connection closed with error: ", error);
            } else {
                console.log("[KaminariCord] SignalR connection closed.");
            }
            showErrorToast("Connection Closed.");
            try {
                const response = await postRequestSyncPromise(
                    "CordChannel/ClearUserFromChannels",
                    {
                        data: [localStorage.getItem("userCode")]
                    }
                );
                if (response.status === 200) {
                    console.log("[KaminariCord] Cleared user from channels successfully.");
                } else {
                    console.log(
                        `[KaminariCord] Failed to clear user from channels. Status code: ${response.status}`
                    );
                }
            } catch (error) {
                console.error("Error clearing user from channels:", error);
            }
        });

        const servers = {
            iceServers: [
                { urls: "stun:stun.l.google.com:19302" },
                { urls: "stun:stun1.l.google.com:19302" },
                { urls: "stun:stun2.l.google.com:19302" },
                { urls: "stun:stun3.l.google.com:19302" },
                { urls: "stun:stun4.l.google.com:19302" },
                { urls: "stun:stun.stunprotocol.org:3478" },
                { urls: "stun:stun.voiparound.com" },
                { urls: "stun:stun.voipbuster.com" },
                { urls: "stun:stun.voipstunt.com" },
                { urls: "stun:stun.services.mozilla.com" }
            ]
        };

        const setupPeerConnection = (peerConnection, remoteConnectionId) => {
            peerConnection.onicecandidate = (event) => {
                if (event.candidate) {
                    newConnection.invoke(
                        "SendIceCandidate",
                        remoteConnectionId,
                        newConnection.connectionId,
                        event.candidate
                    );
                }
            };

            peerConnection.ontrack = (event) => {
                console.log(
                    `[KaminariCord] ontrack => remoteConnectionId:${remoteConnectionId}, track(s):`,
                    event.streams[0].getTracks().map((t) => t.id)
                );
                setRemoteStreams((prevStreams) => ({
                    ...prevStreams,
                    [remoteConnectionId]: event.streams[0]
                }));
            };

            peerConnection.onnegotiationneeded = async () => {
                try {
                    console.log(
                        `[KaminariCord] onnegotiationneeded => will createOffer to: ${remoteConnectionId}`
                    );
                    const offer = await peerConnection.createOffer({
                        offerToReceiveAudio: true,
                        offerToReceiveVideo: true
                    });
                    await peerConnection.setLocalDescription(offer);
                    newConnection.invoke(
                        "SendOffer",
                        remoteConnectionId,
                        newConnection.connectionId,
                        offer
                    );
                } catch (err) {
                    console.error("onnegotiationneeded error:", err);
                }
            };
        };

        newConnection.on("UpdateUserConnectionId", (updatedUser) => {
            console.log("[KaminariCord] UpdateUserConnectionId event =>", updatedUser);

            // 1) Bu kullanıcı odadaki "voiceChannelUsers" datasında var mı diye bakalım.
            const { RoomId, UserName, ConnectionId } = updatedUser;

            // "voiceChannelUsers" state’ini güncelleyeceğiz:
            // eğer voiceChannelUsers[RoomId] varsa, oradaki userName eşleşen kaydı bulacağız.
            setVoiceChannelUsers(prev => {
                // önce o odadaki kullanıcı listesini alalım
                const existingRoomUsers = prev[RoomId];
                if (!existingRoomUsers) {
                    // eğer o odada herhangi bir user kaydı yoksa, dokunmuyoruz.
                    return prev;
                }

                // yeni bir kopya oluşturalım
                const newRoomUsers = [...existingRoomUsers];
                const idx = newRoomUsers.findIndex(u => u.userName === UserName);

                if (idx !== -1) {
                    // Bulduysak connectionId’yi güncelliyoruz
                    const oldUser = newRoomUsers[idx];
                    newRoomUsers[idx] = {
                        ...oldUser,
                        connectionId: ConnectionId
                    };
                    console.log(`[KaminariCord] Updated connectionId for user=${UserName} in roomId=${RoomId}`);
                }
                else {
                    // Bulamadıysak, belki bu user odaya yeni eklenmiştir
                    // ama normalde "userJoinedToServer" eventinde ekleme yapıyorsunuz.
                    // Burada "eklemek" isteyip istemediğinize siz karar verin.
                    // newRoomUsers.push({ userName: UserName, connectionId: ConnectionId });
                    // console.log(`[KaminariCord] Inserted user=${UserName} to voiceChannelUsers for roomId=${RoomId}`);
                }

                // state’e geri döndürüyoruz
                return {
                    ...prev,
                    [RoomId]: newRoomUsers
                };
            });
        });

        // channelInserted
        newConnection.on("channelInserted", async (newChannel) => {
            console.log("[KaminariCord] channelInserted => newChannel:", newChannel);
            const userCode = localStorage.getItem("userCode");
            if (
                newChannel.allowedUsers.includes(userCode) ||
                newChannel.ownerId === userCode
            ) {
                console.log(
                    "[KaminariCord] channelInserted => user is allowed/owner for this channel"
                );
            } else {
                console.log(
                    "[KaminariCord] channelInserted => user is NOT allowed, but we'll store channel for voice sync"
                );
            }
            setChannels((prevChannels) => {
                const exists = prevChannels.some((ch) => ch.roomId === newChannel.roomId);
                if (!exists) {
                    return [...prevChannels, newChannel];
                }
                return prevChannels;
            });
        });

        // channelUpdated
        newConnection.on("channelUpdated", async (updatedChannel) => {
            console.log(
                "[channelUpdated] event triggered with updatedChannel:",
                updatedChannel
            );
            setChannels((prev) => {
                const index = prev.findIndex((ch) => ch.roomId === updatedChannel.roomId);
                if (index === -1) {
                    return [...prev, updatedChannel];
                }
                return prev.map((ch) => {
                    if (ch.roomId === updatedChannel.roomId) {
                        console.log("[channelUpdated] roomId matched, merging channel data");
                        return {
                            ...ch,
                            channelName: updatedChannel.channelName,
                            channelDescription: updatedChannel.channelDescription,
                            status: updatedChannel.status,
                            updatedAt: updatedChannel.updatedAt,
                            allowedUsers: updatedChannel.allowedUsers,
                            channelType: updatedChannel.channelType,
                            activeUsers: updatedChannel.activeUsers
                        };
                    }
                    return ch;
                });
            });

            const activeUsers = updatedChannel.activeUsers;
            console.log("[channelUpdated] activeUsers:", activeUsers);

            const userCode = localStorage.getItem("userCode");
            console.log("[channelUpdated] userCode:", userCode);

            if (
                !updatedChannel.allowedUsers.includes(userCode) &&
                updatedChannel.ownerId !== userCode
            ) {
                console.log(
                    "[channelUpdated] user not allowed and not owner, checking isActive..."
                );
                const isUserActive = activeUsers?.some((u) => u.userName === userCode);
                console.log("[channelUpdated] isUserActive:", isUserActive);

                if (isUserActive) {
                    console.log(
                        "[channelUpdated] user is active, leaving channel:",
                        updatedChannel.roomId
                    );
                    userLeftChannelAction(updatedChannel.roomId);
                    setSelectedVoiceChannel(null);
                    localStorage.setItem("LastSelectedVoiceChannel", null);
                    setLastSelectedChannel(null);
                }
            }
        });

        // userJoinedToServer
        newConnection.on("userJoinedToServer", async (joinedServerInfo) => {
            const { roomId, userToJoin, connectionId, allUsers } = joinedServerInfo;
            console.log(
                "[KaminariCord] userJoinedToServer => roomId:",
                roomId,
                " userToJoin:",
                userToJoin,
                " connectionId:",
                connectionId,
                " allUsers:",
                allUsers
            );
            setVoiceChannelUsers((prev) => ({
                ...prev,
                [roomId]: allUsers
            }));
            const myConnectionId = newConnection.connectionId;
            const amIInThisChannel = allUsers.some(
                (u) => u.connectionId === myConnectionId
            );
            if (amIInThisChannel && connectionId !== myConnectionId) {
                joinSoundRef.current
                    .play()
                    .catch((err) => console.error("Error playing join sound:", err));
            }

            if (connectionId === myConnectionId) {
                const otherUsers = allUsers.filter((u) => u.connectionId !== myConnectionId);
                if (otherUsers.length > 0) {
                    for (let usr of otherUsers) {
                        const remoteConnectionId = usr.connectionId;
                        console.log("[KaminariCord] Creating peerConnection for:", remoteConnectionId);
                        const pc = new RTCPeerConnection(servers);
                        setupPeerConnection(pc, remoteConnectionId);
                        localStream.current.getTracks().forEach((track) => {
                            pc.addTrack(track, localStream.current);
                        });
                        peerConnections.current[remoteConnectionId] = pc;
                    }
                }
            }
        });

        newConnection.on("ReceiveOffer", async (senderConnectionId, offer) => {
            console.log("[KaminariCord] ReceiveOffer => from:", senderConnectionId);
            let pc = peerConnections.current[senderConnectionId];
            if (!pc) {
                pc = new RTCPeerConnection(servers);
                peerConnections.current[senderConnectionId] = pc;
                setupPeerConnection(pc, senderConnectionId);
                localStream.current.getTracks().forEach((track) => {
                    pc.addTrack(track, localStream.current);
                });
            }
            try {
                await pc.setRemoteDescription(new RTCSessionDescription(offer));
                const answer = await pc.createAnswer();
                await pc.setLocalDescription(answer);
                newConnection.invoke(
                    "SendAnswer",
                    senderConnectionId,
                    newConnection.connectionId,
                    answer
                );
            } catch (err) {
                console.error("Error handling offer:", err);
            }
        });

        newConnection.on("ReceiveAnswer", async (senderConnectionId, answer) => {
            console.log("[KaminariCord] ReceiveAnswer => from:", senderConnectionId);
            const pc = peerConnections.current[senderConnectionId];
            if (pc) {
                try {
                    await pc.setRemoteDescription(new RTCSessionDescription(answer));
                    console.log(
                        "[KaminariCord] setRemoteDescription(ANSWER) OK for",
                        senderConnectionId
                    );
                } catch (error) {
                    console.error("Error setting remote description:", error);
                }
            }
        });

        newConnection.on("ReceiveIceCandidate", async (senderConnectionId, candidate) => {
            const pc = peerConnections.current[senderConnectionId];
            if (pc) {
                try {
                    await pc.addIceCandidate(new RTCIceCandidate(candidate));
                } catch (error) {
                    console.error("Error adding received ICE candidate", error);
                }
            }
        });

        newConnection.on("userLeftToServer", (leftServerInfo) => {
            const { roomId, connectionId, allUsers } = leftServerInfo;
            console.log(
                "[KaminariCord] userLeftToServer => roomId:",
                roomId,
                " connectionId:",
                connectionId,
                " allUsers:",
                allUsers
            );
            setVoiceChannelUsers((prev) => ({
                ...prev,
                [roomId]: allUsers
            }));
            const myConnectionId = newConnection.connectionId;
            const amIInThisChannel = allUsers.some((u) => u.connectionId === myConnectionId);
            if (amIInThisChannel && connectionId !== myConnectionId) {
                leaveSoundRef.current
                    .play()
                    .catch((err) => console.error("Error playing leave sound:", err));
            }
            if (peerConnections.current[connectionId]) {
                peerConnections.current[connectionId].close();
                delete peerConnections.current[connectionId];
            }
            setRemoteStreams((prev) => {
                const newStreams = { ...prev };
                delete newStreams[connectionId];
                return newStreams;
            });
        });

        newConnection.on("getConnectionId", (connectionId) => {
            console.log("[KaminariCord] getConnectionId =>", connectionId);
            localStorage.setItem("connectionId", JSON.stringify(connectionId));
        });

        newConnection.on("popError", (error) => {
            console.error("[KaminariCord] popError =>", error);
            showErrorToast(error);
        });

        newConnection.on("popSuccess", (message) => {
            console.log("[KaminariCord] popSuccess =>", message);
            showSuccessToast(message);
        });

        newConnection.on("sendMessageToGroup", (newMessage) => {
            console.log("[KaminariCord] sendMessageToGroup => newMessage:", newMessage);
            setMessages((prev) => [...prev, newMessage]);
        });
    };

    // ------------------------------------------------
    // MESAJ GÖNDER
    // ------------------------------------------------
    const sendMessageToGroup = async (messageContent) => {
        if (!signalConnection.current || !lastSelectedChannel) return;
        const message = {
            RoomId: lastSelectedChannel.roomId,
            SenderId: userName,
            MessageContent: messageContent,
            SentAt: new Date()
        };
        const request = { data: [message] };
        try {
            await signalConnection.current.invoke("SendMessageToGroup", request);
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    // ------------------------------------------------
    // MİKROFON
    // ------------------------------------------------
    useEffect(() => {
        if (!selectedAudioInputDeviceId) return;
        const handleMicrophoneUpdate = async () => {
            if (!localAudioTrackRef.current && micOn) {
                // ilk defa mic track
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: { exact: selectedAudioInputDeviceId },
                        noiseSuppression: noiseSuppressionOn,
                        echoCancellation: true,
                        autoGainControl: true
                    }
                });
                const newTrack = stream.getAudioTracks()[0];
                newTrack.enabled = true;
                localAudioTrackRef.current = newTrack;
                localStream.current.addTrack(newTrack);

                Object.entries(peerConnections.current).forEach(([connId, pc]) => {
                    pc.addTrack(newTrack, localStream.current);
                });
            } else if (localAudioTrackRef.current && micOn) {
                const settings = localAudioTrackRef.current.getSettings();
                if (settings.deviceId !== selectedAudioInputDeviceId) {
                    const stream = await navigator.mediaDevices.getUserMedia({
                        audio: {
                            deviceId: { exact: selectedAudioInputDeviceId },
                            noiseSuppression: noiseSuppressionOn,
                            echoCancellation: true,
                            autoGainControl: true
                        }
                    });
                    const newTrack = stream.getAudioTracks()[0];
                    newTrack.enabled = true;

                    Object.entries(peerConnections.current).forEach(([connId, pc]) => {
                        const sender = pc
                            .getSenders()
                            .find((s) => s.track && s.track.kind === "audio");
                        if (sender) sender.replaceTrack(newTrack);
                    });
                    localStream.current.removeTrack(localAudioTrackRef.current);
                    localAudioTrackRef.current.stop();

                    localAudioTrackRef.current = newTrack;
                    localStream.current.addTrack(newTrack);
                } else {
                    // sadece enabled
                    localAudioTrackRef.current.enabled = true;
                }
            } else if (localAudioTrackRef.current && !micOn) {
                // kapat
                localAudioTrackRef.current.enabled = false;
            }
        };
        handleMicrophoneUpdate();
    }, [micOn, selectedAudioInputDeviceId, noiseSuppressionOn]);

    // ------------------------------------------------
    // KAMERA
    // ------------------------------------------------
    useEffect(() => {
        const handleCameraUpdate = async () => {
            if (screenSharingOn) {
                // Ekran paylaşımı varsa kamerayı kapat
                if (localVideoTrackRef.current) {
                    removeVideoTrack(localVideoTrackRef.current);
                    localVideoTrackRef.current = null;
                }
                return;
            }
            if (videoOn) {
                if (!localVideoTrackRef.current) {
                    const stream = await navigator.mediaDevices.getUserMedia({
                        video: selectedVideoInputDeviceId
                            ? { deviceId: { exact: selectedVideoInputDeviceId } }
                            : true
                    });
                    const cameraTrack = stream.getVideoTracks()[0];
                    localVideoTrackRef.current = cameraTrack;
                    localStream.current.addTrack(cameraTrack);

                    Object.entries(peerConnections.current).forEach(([connId, pc]) => {
                        pc.addTrack(cameraTrack, localStream.current);
                    });
                } else {
                    const settings = localVideoTrackRef.current.getSettings();
                    if (settings.deviceId !== selectedVideoInputDeviceId) {
                        const stream = await navigator.mediaDevices.getUserMedia({
                            video: { deviceId: { exact: selectedVideoInputDeviceId } }
                        });
                        const newCameraTrack = stream.getVideoTracks()[0];

                        Object.entries(peerConnections.current).forEach(([connId, pc]) => {
                            const sender = pc
                                .getSenders()
                                .find((s) => s.track && s.track.kind === "video");
                            if (sender) sender.replaceTrack(newCameraTrack);
                        });

                        localStream.current.removeTrack(localVideoTrackRef.current);
                        localVideoTrackRef.current.stop();

                        localVideoTrackRef.current = newCameraTrack;
                        localStream.current.addTrack(newCameraTrack);
                    }
                }
            } else {
                if (localVideoTrackRef.current) {
                    removeVideoTrack(localVideoTrackRef.current);
                    localVideoTrackRef.current = null;
                }
            }
        };
        handleCameraUpdate();
    }, [videoOn, selectedVideoInputDeviceId, screenSharingOn]);

    const removeVideoTrack = (track) => {
        if (!track) return;
        localStream.current.removeTrack(track);
        track.stop();
        Object.entries(peerConnections.current).forEach(([connId, pc]) => {
            const sender = pc
                .getSenders()
                .find((s) => s.track && s.track.id === track.id);
            if (sender) pc.removeTrack(sender);
        });
    };

    // ------------------------------------------------
    // EKRAN PAYLAŞIMI
    // ------------------------------------------------
    useEffect(() => {
        const handleScreenShareUpdate = async () => {
            if (screenSharingOn) {
                if (localVideoTrackRef.current) {
                    removeVideoTrack(localVideoTrackRef.current);
                    localVideoTrackRef.current = null;
                }
                if (!localScreenTrackRef.current) {
                    try {
                        const { width, height } =
                            resolutionMap[selectedResolution] || { width: 1920, height: 1080 };
                        const chosenFrameRate = parseInt(selectedFrameRate, 10) || 30;
                        const screenStream = await navigator.mediaDevices.getDisplayMedia({
                            video: { width, height, frameRate: chosenFrameRate },
                            audio: true
                        });
                        const screenTrack = screenStream.getVideoTracks()[0];
                        localScreenTrackRef.current = screenTrack;
                        localStream.current.addTrack(screenTrack);

                        Object.entries(peerConnections.current).forEach(([connId, pc]) => {
                            pc.addTrack(screenTrack, localStream.current);
                        });

                        screenTrack.onended = () => {
                            setScreenSharingOn(false);
                        };
                    } catch (err) {
                        console.error("Error accessing display media:", err);
                        setScreenSharingOn(false);
                    }
                }
            } else {
                if (localScreenTrackRef.current) {
                    removeVideoTrack(localScreenTrackRef.current);
                    localScreenTrackRef.current = null;
                }
            }
        };
        handleScreenShareUpdate();
    }, [screenSharingOn, selectedResolution, selectedFrameRate]);

    // ------------------------------------------------
    // SES AYARI MENÜSÜ
    // ------------------------------------------------
    // userVolumes ve mutedUsers "userName" bazlı
    // => handleVolumeChange, handleMuteToggle vb. de "userName" alacak
    const onOpenVolumeMenu = (clientX, clientY, clickedUserName) => {
        if (containerRef.current) {
            const x = clientX - 625;
            const y = clientY - 100;
            setMenuPosition({ x, y });
            setTargetUserName(clickedUserName);
            setVolumeMenuVisible(true);
        }
    };

    const handleVolumeChange = (userName, newVolumePercent) => {
        setUserVolumes((prev) => ({
            ...prev,
            [userName]: newVolumePercent
        }));
    };

    const handleFineVolumeChange = (newFineValue) => {
        setFineVolume(newFineValue);
    };

    const handleMuteToggle = (userName) => {
        setMutedUsers((prev) => {
            const newValue = !prev[userName];
            return { ...prev, [userName]: newValue };
        });
    };

    const computeFinalVolume = useCallback(
        (uName) => {
            const mainVolume = userVolumes[uName] ?? 100;
            const isUserMuted = mutedUsers[uName];
            if (!soundOn) return 0; // Tüm ses kapalı seçeneği
            if (isUserMuted) return 0;
            if (mainVolume < 10 && detailedVolume) {
                return (mainVolume * fineVolume) / 100;
            }
            return mainVolume / 100;
        },
        [userVolumes, mutedUsers, soundOn, detailedVolume, fineVolume]
    );

    // ----------------- VIDEO POPUP ----------------- //
    const handleOpenVideoPopup = useCallback((connectionId, userName) => {
        setPopupUserConnectionId(connectionId);
        setPopupUserName(userName);
        setIsVideoPopupOpen(true);
    }, []);

    const handleCloseVideoPopup = useCallback(() => {
        setIsVideoPopupOpen(false);
        setPopupUserConnectionId(null);
        setPopupUserName("");
    }, []);

    // ------------------------------------------------
    // RENDER
    // ------------------------------------------------
    return (
        <div className="kaminari-container" ref={containerRef}>
            <Row className="kaminari-row" style={{ height: "100%" }}>
                <ToastContainer position="bottom-center" />

                {/* remoteStreams içindeki kullanıcıların audio render */}
                {Object.keys(remoteStreams).map((connectionId) => {
                    // Kendi yayınımız harici (myConnectionId) bir stream
                    if (connectionId === signalConnection.current?.connectionId) {
                        return null;
                    }

                    // Her connectionId için, userName'i bulalım (voiceChannelUsers verilerinden)
                    // voiceChannelUsers[lastSelectedChannel.roomId] => userName, connectionId pair
                    // Fakat tek bir channel'a sabit kalmak istemiyorsanız, global arama da yapabilirsiniz.
                    // Örnek basit yaklaşım: connectionId => userName'i bulmak için her room'daki users'a bakar.
                    let matchedUserName = "";
                    for (let roomId in voiceChannelUsers) {
                        const found = voiceChannelUsers[roomId]?.find(
                            (usr) => usr.connectionId === connectionId
                        );
                        if (found) {
                            matchedUserName = found.userName;
                            break;
                        }
                    }
                    // userName bulunamadıysa fallback
                    if (!matchedUserName) matchedUserName = `conn_${connectionId}`;

                    const finalVolume = computeFinalVolume(matchedUserName);
                    const isMuted = mutedUsers[matchedUserName] || !soundOn;
                    return (
                        <audio
                            key={connectionId}
                            autoPlay
                            playsInline
                            ref={(audio) => {
                                if (audio) {
                                    audio.srcObject = remoteStreams[connectionId];
                                    audio.muted = isMuted;
                                    audio.volume = finalVolume;
                                    if (
                                        typeof audio.sinkId !== "undefined" &&
                                        selectedAudioOutputDeviceId
                                    ) {
                                        audio
                                            .setSinkId(selectedAudioOutputDeviceId)
                                            .catch((err) => {
                                                console.warn("setSinkId error: ", err);
                                            });
                                    }
                                }
                            }}
                        />
                    );
                })}

                {/* Device Settings Modal */}
                <Modal
                    isOpen={userSettingsOpen}
                    toggle={() => setUserSettingsOpen(!userSettingsOpen)}
                    className="device-settings-modal"
                >
                    <ModalHeader toggle={() => setUserSettingsOpen(!userSettingsOpen)}>
                        Device Settings
                    </ModalHeader>
                    <ModalBody>
                        <div className="device-settings-container">
                            <div className="device-section">
                                <label>
                                    <FaMicrophone className="device-icon" />
                                    Microphone
                                </label>
                                <select
                                    className="device-select"
                                    value={selectedAudioInputDeviceId}
                                    onChange={(e) => setSelectedAudioInputDeviceId(e.target.value)}
                                >
                                    {audioInputDevices.map((device, idx) => (
                                        <option key={idx} value={device.deviceId}>
                                            {device.label || `Microphone ${idx + 1}`}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="device-section">
                                <label>
                                    <FaHeadphones className="device-icon" />
                                    Speaker/Output
                                </label>
                                <select
                                    className="device-select"
                                    value={selectedAudioOutputDeviceId}
                                    onChange={(e) => setSelectedAudioOutputDeviceId(e.target.value)}
                                >
                                    {audioOutputDevices.map((device, idx) => (
                                        <option key={idx} value={device.deviceId}>
                                            {device.label || `Speaker ${idx + 1}`}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="device-section">
                                <label>
                                    <FaVideo className="device-icon" />
                                    Camera
                                </label>
                                <select
                                    className="device-select"
                                    value={selectedVideoInputDeviceId}
                                    onChange={(e) => setSelectedVideoInputDeviceId(e.target.value)}
                                >
                                    {videoInputDevices.map((device, idx) => (
                                        <option key={idx} value={device.deviceId}>
                                            {device.label || `Camera ${idx + 1}`}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* ScreenShare Resolution */}
                            <div className="device-section">
                                <label>
                                    <i className="fas fa-tv device-icon" />
                                    Screen Share Resolution
                                </label>
                                <select
                                    className="device-select"
                                    value={selectedResolution}
                                    onChange={(e) => setSelectedResolution(e.target.value)}
                                >
                                    <option value="4K">4K (3840x2160)</option>
                                    <option value="2K">2K (2560x1440)</option>
                                    <option value="1080p">1080p (1920x1080)</option>
                                    <option value="720p">720p (1280x720)</option>
                                    <option value="480p">480p (854x480)</option>
                                    <option value="360p">360p (640x360)</option>
                                </select>
                            </div>
                            <div className="device-section">
                                <label>
                                    <i className="fas fa-history device-icon" />
                                    Screen Share FPS
                                </label>
                                <select
                                    className="device-select"
                                    value={selectedFrameRate}
                                    onChange={(e) => setSelectedFrameRate(e.target.value)}
                                >
                                    <option value="120">120</option>
                                    <option value="90">90</option>
                                    <option value="60">60</option>
                                    <option value="45">45</option>
                                    <option value="30">30</option>
                                    <option value="15">15</option>
                                    <option value="5">5</option>
                                </select>
                            </div>
                            <button className="refresh-button" onClick={getMediaDevices}>
                                <FaSync className="refresh-icon" /> Refresh Devices
                            </button>
                            <p className="info-text">
                                Changes will be applied automatically after closing the window.
                            </p>
                        </div>
                    </ModalBody>
                </Modal>

                {/* CREATE CHANNEL MODAL */}
                <Modal
                    isOpen={createChannelModalOpen}
                    toggle={() => setCreateChannelModalOpen(!createChannelModalOpen)}
                    className="kaminari-modal create-channel-modal"
                    backdrop="static"
                >
                    <div className="kaminari-modal-header">
                        <h2 className="kaminari-modal-title">Create a New Channel</h2>
                        <button
                            className="kaminari-modal-close"
                            onClick={() => setCreateChannelModalOpen(false)}
                        >
                            &times;
                        </button>
                    </div>
                    <div className="kaminari-modal-body">
                        <p className="kaminari-modal-description">
                            Please fill out the form below to create a new channel. Fields
                            marked with <span className="required-asterisk">*</span> are
                            required.
                        </p>
                        <div className="kaminari-form-group">
                            <label className="kaminari-label">Owner</label>
                            <input
                                type="text"
                                className="kaminari-input"
                                value={userName}
                                readOnly
                            />
                            <small className="kaminari-hint">
                                This is your username. You are the owner of this channel.
                            </small>
                        </div>
                        <div className="kaminari-form-group">
                            <label className="kaminari-label">
                                <FontAwesomeIcon icon={faSignature} className="kaminari-label-icon" />
                                Channel Name <span className="required-asterisk">*</span>
                            </label>
                            <input
                                type="text"
                                className="kaminari-input"
                                value={channelName}
                                onChange={(e) => setChannelName(e.target.value)}
                            />
                            <small className="kaminari-hint">
                                The name that will be visible to all users.
                            </small>
                        </div>
                        <div className="kaminari-form-group">
                            <label className="kaminari-label">
                                <FontAwesomeIcon icon={faEdit} className="kaminari-label-icon" />
                                Channel Description
                            </label>
                            <textarea
                                className="kaminari-textarea"
                                value={channelDescription}
                                onChange={(e) => setChannelDescription(e.target.value)}
                            />
                            <small className="kaminari-hint">
                                (Optional) A brief description of what this channel is about.
                            </small>
                        </div>
                        <div className="kaminari-form-group">
                            <label className="kaminari-label">
                                <FontAwesomeIcon icon={faFolderOpen} className="kaminari-label-icon" />
                                Channel Type <span className="required-asterisk">*</span>
                            </label>
                            <div className="kaminari-select-with-icon">
                                <select
                                    className="kaminari-select"
                                    value={channelType}
                                    onChange={(e) => setChannelType(e.target.value)}
                                >
                                    <option value="Text">Text</option>
                                    <option value="Voice">Voice</option>
                                </select>
                                <span className="kaminari-type-icon">
                                    {channelType === "Voice" ? (
                                        <FontAwesomeIcon icon={faMicrophone} />
                                    ) : (
                                        <FontAwesomeIcon icon={faKeyboard} />
                                    )}
                                </span>
                            </div>
                            <small className="kaminari-hint">
                                "Voice" channels allow real-time audio communication.
                            </small>
                        </div>
                        <div className="kaminari-form-group">
                            <label className="kaminari-label">
                                <FontAwesomeIcon icon={faToggleOn} className="kaminari-label-icon" />
                                Channel Status <span className="required-asterisk">*</span>
                            </label>
                            <div className="kaminari-select-with-icon">
                                <select
                                    className="kaminari-select"
                                    value={channelStatus}
                                    onChange={(e) => setChannelStatus(e.target.value)}
                                >
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                                <span className="kaminari-type-icon">
                                    {channelStatus === "Active" ? (
                                        <FontAwesomeIcon icon={faToggleOn} />
                                    ) : (
                                        <FontAwesomeIcon icon={faToggleOff} />
                                    )}
                                </span>
                            </div>
                            <small className="kaminari-hint">
                                "Inactive" channels are hidden from users except the owner.
                            </small>
                        </div>
                        <div className="kaminari-form-group">
                            <label className="kaminari-label">
                                <FontAwesomeIcon icon={faUserPlus} className="kaminari-label-icon" />
                                Allowed Users
                            </label>
                            <div className="kaminari-multi-user-selector">
                                {allUserNames.map((u, idx) => {
                                    const isChecked = selectedAllowedUsers.includes(u);
                                    const handleUserToggle = () => {
                                        if (isChecked) {
                                            const newList = selectedAllowedUsers.filter(
                                                (name) => name !== u
                                            );
                                            setSelectedAllowedUsers(newList);
                                        } else {
                                            setSelectedAllowedUsers([...selectedAllowedUsers, u]);
                                        }
                                    };
                                    return (
                                        <label key={idx} className="kaminari-user-checkbox">
                                            <input
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={handleUserToggle}
                                            />
                                            <div className="user-checkbox-content">
                                                <FontAwesomeIcon icon={faUser} className="user-avatar-icon" />
                                                <span>{u}</span>
                                            </div>
                                        </label>
                                    );
                                })}
                            </div>
                            <small className="kaminari-hint">
                                Allowed users can see and join the channel even if it's "Active" or
                                "Inactive".
                            </small>
                        </div>
                    </div>
                    <div className="kaminari-modal-footer">
                        <button
                            className="kaminari-button-secondary"
                            onClick={() => setCreateChannelModalOpen(false)}
                        >
                            Cancel
                        </button>
                        <button
                            className="kaminari-button-primary"
                            onClick={handleCreateChannelSubmit}
                        >
                            Create
                        </button>
                    </div>
                </Modal>

                {/* EDIT CHANNEL MODAL */}
                <Modal
                    isOpen={editChannelModalOpen}
                    toggle={() => setEditChannelModalOpen(!editChannelModalOpen)}
                    className="kaminari-modal edit-channel-modal"
                    backdrop="static"
                >
                    <div className="kaminari-modal-header">
                        <h2 className="kaminari-modal-title">Edit Channel</h2>
                        <button
                            className="kaminari-modal-close"
                            onClick={() => setEditChannelModalOpen(false)}
                        >
                            &times;
                        </button>
                    </div>
                    <div className="kaminari-modal-body">
                        {channelBeingEdited && (
                            <>
                                <p className="kaminari-modal-description">
                                    You can edit channel details below. To change channel status or allowed
                                    users, select the options accordingly.
                                </p>
                                <div className="kaminari-form-group">
                                    <label className="kaminari-label">
                                        <FontAwesomeIcon icon={faSignature} className="kaminari-label-icon" />
                                        Channel Name <span className="required-asterisk">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="kaminari-input"
                                        value={editChannelName}
                                        onChange={(e) => setEditChannelName(e.target.value)}
                                    />
                                    <small className="kaminari-hint">
                                        Update the name for the channel.
                                    </small>
                                </div>
                                <div className="kaminari-form-group">
                                    <label className="kaminari-label">
                                        <FontAwesomeIcon icon={faEdit} className="kaminari-label-icon" />
                                        Channel Description
                                    </label>
                                    <textarea
                                        className="kaminari-textarea"
                                        value={editChannelDescription}
                                        onChange={(e) => setEditChannelDescription(e.target.value)}
                                    />
                                    <small className="kaminari-hint">
                                        (Optional) A brief explanation of what this channel is used for.
                                    </small>
                                </div>
                                <div className="kaminari-form-group">
                                    <label className="kaminari-label">
                                        <FontAwesomeIcon icon={faFolderOpen} className="kaminari-label-icon" />
                                        Channel Type <span className="required-asterisk">*</span>
                                    </label>
                                    <div className="kaminari-select-with-icon">
                                        <select
                                            className="kaminari-select"
                                            value={editChannelType}
                                            onChange={(e) => setEditChannelType(e.target.value)}
                                        >
                                            <option value="Text">Text</option>
                                            <option value="Voice">Voice</option>
                                        </select>
                                        <span className="kaminari-type-icon">
                                            {editChannelType === "Voice" ? (
                                                <FontAwesomeIcon icon={faMicrophone} />
                                            ) : (
                                                <FontAwesomeIcon icon={faKeyboard} />
                                            )}
                                        </span>
                                    </div>
                                    <small className="kaminari-hint">
                                        "Text" channels are for messages, "Voice" channels for audio
                                        communication.
                                    </small>
                                </div>
                                <div className="kaminari-form-group">
                                    <label className="kaminari-label">
                                        <FontAwesomeIcon icon={faToggleOn} className="kaminari-label-icon" />
                                        Channel Status <span className="required-asterisk">*</span>
                                    </label>
                                    <div className="kaminari-select-with-icon">
                                        <select
                                            className="kaminari-select"
                                            value={editChannelStatus}
                                            onChange={(e) => setEditChannelStatus(e.target.value)}
                                        >
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </select>
                                        <span className="kaminari-type-icon">
                                            {editChannelStatus === "Active" ? (
                                                <FontAwesomeIcon icon={faToggleOn} />
                                            ) : (
                                                <FontAwesomeIcon icon={faToggleOff} />
                                            )}
                                        </span>
                                    </div>
                                    <small className="kaminari-hint">
                                        "Inactive" channels can only be seen by you and allowed users.
                                    </small>
                                </div>
                                <div className="kaminari-form-group">
                                    <label className="kaminari-label">
                                        <FontAwesomeIcon icon={faUserPlus} className="kaminari-label-icon" />
                                        Allowed Users
                                    </label>
                                    <div className="kaminari-multi-user-selector">
                                        {mergedUsers.map((u, idx) => {
                                            const isChecked = editAllowedUsers.includes(u);
                                            const handleUserToggle = () => {
                                                if (isChecked) {
                                                    const newList = editAllowedUsers.filter(
                                                        (name) => name !== u
                                                    );
                                                    setEditAllowedUsers(newList);
                                                } else {
                                                    setEditAllowedUsers([...editAllowedUsers, u]);
                                                }
                                            };
                                            return (
                                                <label key={idx} className="kaminari-user-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        checked={isChecked}
                                                        onChange={handleUserToggle}
                                                    />
                                                    <div className="user-checkbox-content">
                                                        <FontAwesomeIcon icon={faUser} className="user-avatar-icon" />
                                                        <span>{u}</span>
                                                    </div>
                                                </label>
                                            );
                                        })}
                                    </div>
                                    <small className="kaminari-hint">
                                        Hold Ctrl/Cmd to select multiple users (or just check/uncheck).
                                    </small>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="kaminari-modal-footer">
                        <button
                            className="kaminari-button-secondary"
                            onClick={() => setEditChannelModalOpen(false)}
                        >
                            Cancel
                        </button>
                        <button
                            className="kaminari-button-primary"
                            onClick={handleEditChannelSubmit}
                        >
                            Save
                        </button>
                    </div>
                </Modal>

                {/* SOL PANEL */}
                <LeftPanel
                    micOn={micOn}
                    setMicOn={setMicOn}
                    soundOn={soundOn}
                    setSoundOn={setSoundOn}
                    channels={channels}
                    selectedTextChannel={selectedTextChannel}
                    setSelectedTextChannel={setSelectedTextChannel}
                    selectedVoiceChannel={selectedVoiceChannel}
                    setSelectedVoiceChannel={setSelectedVoiceChannel}
                    lastSelectedChannel={lastSelectedChannel}
                    setLastSelectedChannel={setLastSelectedChannel}
                    user={user}
                    userJoinChannelAction={userJoinChannelAction}
                    userLeftChannelAction={userLeftChannelAction}
                    voiceChannelUsers={voiceChannelUsers}
                    fetchMessagesForChannel={fetchMessagesForChannel}
                    localStream={localStream}
                    peerConnections={peerConnections}
                    setRemoteStreams={setRemoteStreams}
                    videoOn={videoOn}
                    setVideoOn={setVideoOn}
                    noiseSuppressionOn={noiseSuppressionOn}
                    setNoiseSuppressionOn={setNoiseSuppressionOn}
                    userImages={userImages}
                    screenSharingOn={screenSharingOn}
                    setScreenSharingOn={setScreenSharingOn}
                    setUserSettingsOpen={setUserSettingsOpen}
                    userSettingsOpen={userSettingsOpen}
                    onOpenCreateChannelModal={handleOpenCreateChannelModal}
                    onOpenEditChannelModal={handleOpenEditChannelModal}
                />

                {/* SAĞ PANEL */}
                <Col md="9" className="kaminari-right" style={{ height: "100%" }}>
                    {!lastSelectedChannel && (
                        <div className="no-channel-selected">
                            <h1>Welcome to KaminariCord</h1>
                            <p>Select a channel to start chatting or join a voice channel to start talking.</p>
                        </div>
                    )}
                    {lastSelectedChannel && lastSelectedChannel.channelType !== 1 && (
                        <div className="text-channel-content">
                            <MessagesPanel
                                messages={messages}
                                onSendMessage={sendMessageToGroup}
                                currentUser={userName}
                                userImages={userImages}
                                allUserNames={allUserNames}
                                postRequestSyncPromise={postRequestSyncPromise}
                                activeUsers={activeUsers}
                                selectedTextChannel={selectedTextChannel}
                                channels={channels}
                            />
                        </div>
                    )}
                    {lastSelectedChannel && lastSelectedChannel.channelType === 1 && (
                        <div
                            className="voice-channel-selected"
                            style={{ height: "100%", width: "100%" }}
                        >
                            <VoicePanel
                                usersInVoiceChannel={
                                    voiceChannelUsers[lastSelectedChannel.roomId] || []
                                }
                                remoteStreams={remoteStreams}
                                localStream={localStream.current}
                                myConnectionId={signalConnection.current?.connectionId}
                                userImages={userImages}
                                onOpenVolumeMenu={onOpenVolumeMenu}
                                mutedUsers={mutedUsers}
                                soundOn={soundOn}
                                userVolumes={userVolumes}
                                detailedVolume={detailedVolume}
                                fineVolume={fineVolume}
                                videoOn={videoOn}
                                setUserVolumes={setUserVolumes}
                                setMutedUsers={setMutedUsers}
                                selectedAudioOutputDeviceId={selectedAudioOutputDeviceId}
                                onOpenVideoPopup={handleOpenVideoPopup}
                            />
                        </div>
                    )}
                    {volumeMenuVisible && targetUserName && (
                        <div
                            className="volume-menu"
                            style={{
                                position: "absolute",
                                top: menuPosition.y,
                                left: menuPosition.x
                            }}
                            onMouseLeave={() => setVolumeMenuVisible(false)}
                        >
                            <div className="volume-menu-item">
                                <label>Volume: {userVolumes[targetUserName] ?? 100}%</label>
                                <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={userVolumes[targetUserName] ?? 100}
                                    onChange={(e) =>
                                        handleVolumeChange(
                                            targetUserName,
                                            parseInt(e.target.value, 10)
                                        )
                                    }
                                />
                            </div>
                            <div className="menu-separator"></div>
                            <div
                                className="menu-option"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                <span>Detailed volume</span>
                                <input
                                    type="checkbox"
                                    checked={detailedVolume}
                                    onChange={(e) => {
                                        setDetailedVolume(e.target.checked);
                                        if (!e.target.checked) {
                                            setFineVolume(1.0);
                                        }
                                    }}
                                />
                            </div>
                            {detailedVolume && (userVolumes[targetUserName] ?? 100) < 10 && (
                                <>
                                    <div className="volume-menu-item">
                                        <label>Fine Tune</label>
                                        <input
                                            type="range"
                                            min="0"
                                            max="1"
                                            step="0.01"
                                            value={fineVolume}
                                            onChange={(e) =>
                                                handleFineVolumeChange(parseFloat(e.target.value))
                                            }
                                        />
                                        <span>{(fineVolume * 100).toFixed(0)}%</span>
                                    </div>
                                </>
                            )}
                            <div className="menu-separator"></div>
                            <div
                                className="menu-option"
                                onClick={() => handleMuteToggle(targetUserName)}
                            >
                                {mutedUsers[targetUserName] ? "Unmute User" : "Mute User"}
                            </div>
                        </div>
                    )}
                </Col>
            </Row>

            {/* Video Popup Modal */}
            {isVideoPopupOpen && popupUserConnectionId && (
                <Modal
                    isOpen={isVideoPopupOpen}
                    toggle={handleCloseVideoPopup}
                    className="stream-modal"
                    size="xl"
                >
                    <div className="stream-container">
                        <button className="close-stream" onClick={handleCloseVideoPopup}>
                            <i className="fas fa-times"></i>
                        </button>
                        <div className="stream-content">
                            <VideoPopup
                                stream={
                                    popupUserConnectionId === signalConnection.current?.connectionId
                                        ? localStream.current
                                        : remoteStreams[popupUserConnectionId]
                                }
                                userName={popupUserName}
                                isMuted={mutedUsers[popupUserName] || false}
                                finalVolume={computeFinalVolume(popupUserName)}
                                onMuteToggle={() => {
                                    handleMuteToggle(popupUserName);
                                }}
                                onVolumeChange={(volume) => {
                                    handleVolumeChange(popupUserName, volume);
                                }}
                                userVolume={userVolumes[popupUserName] ?? 100}
                                selectedAudioOutputDeviceId={selectedAudioOutputDeviceId}
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}