import React, { useState, useContext, useEffect } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {
  FaDiscord,
  FaFilm,
  FaTv,
  FaStickyNote,
  FaUsers,
  FaSignOutAlt,
  FaUserCircle,
  FaCog,
  FaBars,
  FaUser
} from 'react-icons/fa';
import { GiLightningStorm, GiKimono } from 'react-icons/gi';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { UserContext } from '../../App';
import './CustomNavbar.css';
import UserSettings from '../ProfileActions/Settings';
import useIsMobile from '../mobile/IsMobile';
import ActiveUsers from '../ActivityTracker/ActiveUsers';

function CustomNavbar() {
  const { isMobile } = useIsMobile();
  const { friendShipRequests } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isActiveUsersOpen, setIsActiveUsersOpen] = useState(false);
  const [closing, setClosing] = useState(false);


  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const toggle = () => setIsOpen(!isOpen);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const toggleSettingsModal = () => {
    setIsSettingsOpen(!isSettingsOpen);
    setDropdownOpen(false);
  };
  const toggleActiveUsersModal = () => {
    setIsActiveUsersOpen(!isActiveUsersOpen);
  }

  const toggleMobileMenu = () => {
    if (!isMobileMenuOpen) {
      setIsMobileMenuOpen(true);
    } else {
      setClosing(true);
      setTimeout(() => {
        setIsMobileMenuOpen(false);
        setClosing(false);
      }, 400); // match animation duration
    }
  };

  if (!isAuthenticated) {
    return null;
  }

  const userData = JSON.parse(localStorage.getItem('userData'));
  const { userName, avatarUrl } = userData;

  // Masaüstü Navbar
  const desktopNavbar = (
    <>
      <Navbar expand="md" className="kaminari-navbar">
        <div className="navbar-left">
          <NavbarToggler onClick={toggle} className="kaminari-navbar-toggler" />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="me-auto kaminari-nav" navbar>
              <NavItem>
                <NavLink
                  href="/anime"
                  className={`kaminari-nav-link ${location.pathname === '/anime' ? 'active' : ''
                    }`}
                >
                  <GiKimono className="nav-icon" />
                  AnimeList
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/kaminaricord"
                  className={`kaminari-nav-link ${location.pathname === '/kaminaricord' ? 'active' : ''
                    }`}
                >
                  <FaDiscord className="nav-icon" />
                  KaminariCord
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/movie"
                  className={`kaminari-nav-link ${location.pathname === '/movie' ? 'active' : ''
                    }`}
                >
                  <FaFilm className="nav-icon" />
                  Movie
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/tvshow"
                  className={`kaminari-nav-link ${location.pathname === '/tvshow' ? 'active' : ''
                    }`}
                >
                  <FaTv className="nav-icon" />
                  TV Show
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/notes"
                  className={`kaminari-nav-link ${location.pathname === '/notes' ? 'active' : ''
                    }`}
                >
                  <FaStickyNote className="nav-icon" />
                  Notes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/together"
                  className={`kaminari-nav-link ${location.pathname === '/together' ? 'active' : ''
                    }`}
                >
                  <FaUsers className="nav-icon" />
                  Watch Together
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </div>
        <div className="navbar-right">
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            className="user-dropdown"
          >
            <DropdownToggle caret className="user-dropdown-toggle">
              {
                avatarUrl ? (
                  <img
                    src={`data:image/png;base64,${avatarUrl}`}
                    alt="User Avatar"
                    className="user-avatar"
                  />
                ) : (
                  <FaUser className="user-avatar-fallback" />
                )
              }
              <span className="user-name">{userName}</span>
              {friendShipRequests && friendShipRequests.length > 0 && (
                <span className="friend-request-badge">
                  {friendShipRequests.length}
                </span>
              )}
            </DropdownToggle>
            <DropdownMenu
              right
              className={`dropdown-menu-custom ${!dropdownOpen ? 'hidden' : ''}`}
            >
              <DropdownItem onClick={() => navigate('/profile')}>
                <FaUserCircle className="dropdown-icon" /> Profile
                {friendShipRequests && friendShipRequests.length > 0 && (
                  <span className="friend-request-badge">
                    {friendShipRequests.length}
                  </span>
                )}
              </DropdownItem>
              <DropdownItem onClick={toggleSettingsModal}>
                <FaCog className="dropdown-icon" /> Profile Settings
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={toggleActiveUsersModal}>
                <FaUsers className="dropdown-icon" /> Active Users
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={handleLogout}>
                <FaSignOutAlt className="dropdown-icon" /> Logout
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </Navbar>
    </>
  );

  // Mobil Navbar
  const mobileNavbar = (
    <div className="kaminari-navbar mobile-navbar">
      {/* Ortada veya sağda profil dropdown */}
      <div className="mobile-center">
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggleDropdown}
          className="user-dropdown"
        >
          <DropdownToggle caret className="user-dropdown-toggle">
            {
              avatarUrl ? (
                <img
                  src={`data:image/png;base64,${avatarUrl}`}
                  alt="User Avatar"
                  className="user-avatar"
                />
              ) : (
                <FaUser className="user-avatar-fallback" />
              )
            }
            <span className="user-name">{userName}</span>
            {friendShipRequests && friendShipRequests.length > 0 && (
              <span className="friend-request-badge">
                {friendShipRequests.length}
              </span>
            )}
          </DropdownToggle>
          <DropdownMenu
            right
            className={`dropdown-menu-custom ${!dropdownOpen ? 'hidden' : ''}`}
          >
            <DropdownItem onClick={() => navigate('/profile')}>
              <FaUserCircle className="dropdown-icon" /> Profile
              {friendShipRequests && friendShipRequests.length > 0 && (
                <span className="friend-request-badge">
                  {friendShipRequests.length}
                </span>
              )}
            </DropdownItem>
            <DropdownItem onClick={toggleSettingsModal}>
              <FaCog className="dropdown-icon" /> Profile Settings
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={toggleActiveUsersModal}>
              <FaUsers className="dropdown-icon" /> Active Users
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={handleLogout}>
              <FaSignOutAlt className="dropdown-icon" /> Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>

      {/* Sağda menü ikonu */}
      <div className="mobile-right">
        <button className="mobile-menu-button" onClick={toggleMobileMenu}>
          <FaBars />
        </button>
      </div>

      {/* Üstten kayan menü (popup) */}
      {isMobileMenuOpen && (
        <div className={`mobile-menu-overlay ${closing ? 'closing' : ''}`} onClick={toggleMobileMenu}>
          <div className="mobile-menu-overlay-content" onClick={(e) => e.stopPropagation()}>
            <Nav className="mobile-nav-list" navbar>
              <NavItem>
                <NavLink
                  href="/anime"
                  className={`mobile-nav-link ${location.pathname === '/anime' ? 'active' : ''
                    }`}
                  onClick={toggleMobileMenu}
                >
                  <GiKimono className="nav-icon" />
                  AnimeList
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/kaminaricord"
                  className={`mobile-nav-link ${location.pathname === '/kaminaricord' ? 'active' : ''
                    }`}
                  onClick={toggleMobileMenu}
                >
                  <FaDiscord className="nav-icon" />
                  KaminariCord
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/movie"
                  className={`mobile-nav-link ${location.pathname === '/movie' ? 'active' : ''
                    }`}
                  onClick={toggleMobileMenu}
                >
                  <FaFilm className="nav-icon" />
                  Movie
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/tvshow"
                  className={`mobile-nav-link ${location.pathname === '/tvshow' ? 'active' : ''
                    }`}
                  onClick={toggleMobileMenu}
                >
                  <FaTv className="nav-icon" />
                  TV Show
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/notes"
                  className={`mobile-nav-link ${location.pathname === '/notes' ? 'active' : ''
                    }`}
                  onClick={toggleMobileMenu}
                >
                  <FaStickyNote className="nav-icon" />
                  Notes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/together"
                  className={`mobile-nav-link ${location.pathname === '/together' ? 'active' : ''
                    }`}
                  onClick={toggleMobileMenu}
                >
                  <FaUsers className="nav-icon" />
                  Watch Together
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
      {/* Ekran genişliğine göre render */}
      {isMobile ? mobileNavbar : desktopNavbar}

      {/* Profil Ayarları Modali */}
      {isSettingsOpen && (
        <UserSettings isOpen={isSettingsOpen} toggle={toggleSettingsModal} />
      )}

      {/* Aktif Kullanıcılar Modali */}
      {isActiveUsersOpen && (
        <ActiveUsers isOpen={isActiveUsersOpen} toggle={toggleActiveUsersModal} />
      )}
    </>
  );
}

export default CustomNavbar;