import React, {
    useEffect,
    useState,
    useCallback,
} from 'react';
import { Row, Col, Modal, ModalBody } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { usePostRequestSyncPromise } from '../../global/GlobalFetch';
import useIsMobile from '../../components/mobile/IsMobile'; // <-- Mobil dedektörü eklendi
import {
    FaSearch,
    FaSlidersH,
    FaSyncAlt,
    FaFilm,
    FaBroadcastTower,
    FaLeaf,
    FaTimes,
} from 'react-icons/fa';
import {
    faCheckCircle,
    faPlayCircle,
    faPauseCircle,
    faTimesCircle,
    faClock,
    faStar,
    faTimes as faTimesSolid,
    faPlay,
    faSearch,
    faArrowLeft,
    faSave,
    faFilter,
    faTimes,
    faClose,
    faPencilAlt,
    faSyncAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactSlider from 'react-slider';
import { CSSTransition } from 'react-transition-group';
import './anime.scss';
/********************************************************************************
 * AnimeRating Component (Masaüstü + Mobil versiyon)
 ********************************************************************************/
function AnimeRating({ anime, onClose, onSubmit, isMobile = false }) {
    const [selectedStatus, setSelectedStatus] = useState(anime.my_status || 'Plan to Watch');
    const [userScore, setUserScore] = useState(anime.my_score ? parseInt(anime.my_score) : 0);
    const [hoverScore, setHoverScore] = useState(0);

    const animeStatuses = [
        { value: 'Completed', label: 'Completed', icon: 'fa-check-circle' },
        { value: 'Watching', label: 'Watching', icon: 'fa-play-circle' },
        { value: 'On-Hold', label: 'On-Hold', icon: 'fa-pause-circle' },
        { value: 'Dropped', label: 'Dropped', icon: 'fa-times-circle' },
        { value: 'Plan to Watch', label: 'Plan to Watch', icon: 'fa-clock' },
    ];

    useEffect(() => {
        if (isMobile) {
            document.body.style.overflow = 'hidden';
            return () => {
                document.body.style.overflow = 'auto';
            };
        }
    }, [isMobile]);


    // Yorum: Masaüstü sürüm
    const renderDesktopVersion = () => {
        return (
            <div className="anime-rating-overlay">
                <div className="anime-rating-modal">
                    <div className="anime-rating-header">
                        <h2>{anime.title}</h2>
                        <button className="close-button" onClick={onClose}>
                            <FontAwesomeIcon icon={faTimesSolid} />
                        </button>
                    </div>
                    <div className="anime-rating-content">
                        <div className="anime-rating-image">
                            <img src={anime.images.jpg.large_image_url} alt={anime.title} />
                        </div>
                        <div className="anime-rating-controls">
                            {/* Status Buttons */}
                            <div className="status-selector">
                                {animeStatuses.map((status) => (
                                    <button
                                        key={status.value}
                                        className={`status-button ${selectedStatus === status.value ? 'active' : ''
                                            }`}
                                        onClick={() => setSelectedStatus(status.value)}
                                    >
                                        <i className={`fas ${status.icon}`}></i>
                                        <span>{status.label}</span>
                                    </button>
                                ))}
                            </div>

                            {/* Star Rating (1-10) */}
                            <div className="star-rating">
                                {[...Array(10)].map((_, index) => {
                                    index += 1;
                                    return (
                                        <button
                                            key={index}
                                            className={`star ${index <= (hoverScore || userScore) ? 'on' : 'off'}`}
                                            onClick={() => setUserScore(userScore === index ? 0 : index)}
                                            onMouseEnter={() => setHoverScore(index)}
                                            onMouseLeave={() => setHoverScore(0)}
                                        >
                                            <i className="fas fa-star"></i>
                                            <span className="star-label">{index}</span>
                                        </button>
                                    );
                                })}
                            </div>

                            {/* Save Button */}
                            <button className="submit-button" onClick={handleSubmit}>
                                <i className="fas fa-save"></i> Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    // Yorum: Mobil sürüm
    const renderMobileVersion = () => {
        return (
            <div className="anime-rating-modal-mobile" style={{ zIndex: 1009 }}>
                <div className="modal-content-mobile">
                    {/* Header */}
                    <div className="modal-header-mobile">
                        <button className="back-button-mobile" onClick={onClose}>
                            <FontAwesomeIcon icon={faClose} />
                        </button>
                    </div>

                    {/* Cover Image */}
                    <div className="cover-image-mobile">
                        <img src={anime.images.jpg.large_image_url} alt={anime.title} />
                        <div className="image-overlay-mobile">
                            <h2 className="anime-title-mobile">{anime.title}</h2>
                        </div>
                    </div>

                    {/* Rating Content */}
                    <div className="rating-content-mobile">
                        {/* Status Chips */}
                        <div className="status-chips-mobile">
                            {animeStatuses.map((status) => (
                                <button
                                    key={status.value}
                                    className={`status-chip-mobile ${selectedStatus === status.value ? 'active' : ''}`}
                                    onClick={() => setSelectedStatus(status.value)}
                                >
                                    <FontAwesomeIcon icon={status.icon} />
                                    <span>{status.label}</span>
                                </button>
                            ))}
                        </div>

                        {/* Star Rating */}
                        <div className="star-rating-mobile">
                            <h3>Your Rating</h3>
                            <div className="stars-container-mobile">
                                {[...Array(10)].map((_, index) => {
                                    const starValue = index + 1;
                                    const isActive = starValue <= (hoverScore || userScore);
                                    return (
                                        <button
                                            key={starValue}
                                            className={`star-mobile ${isActive ? 'active' : ''}`}
                                            onClick={() => setUserScore(userScore === starValue ? 0 : starValue)}
                                            onMouseEnter={() => setHoverScore(starValue)}
                                            onMouseLeave={() => setHoverScore(0)}
                                        >
                                            <FontAwesomeIcon icon={faStar} className="star-icon-mobile" />
                                            <span className="star-value-mobile">{starValue}</span>
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    {/* Save Button */}
                    <button
                        className="save-button-mobile"
                        onClick={handleSubmit}
                        disabled={!selectedStatus}
                    >
                        <FontAwesomeIcon icon={faSave} />
                        <span>Save Rating</span>
                    </button>
                </div>
            </div>
        );
    };

    // Kaydet handle
    const handleSubmit = () => {
        const updatedAnime = {
            ...anime,
            my_status: selectedStatus,
            my_score: userScore,
        };
        onSubmit(updatedAnime);
    };

    // Mobil mi, masaüstü mü?
    return isMobile ? renderMobileVersion() : renderDesktopVersion();
}

/********************************************************************************
 * AnimeDetailModal Component (Masaüstü + Mobil versiyon)
 ********************************************************************************/
function AnimeDetailModal({ anime, isOpen, toggle, isMobile = false }) {
    const [isTrailerPopupOpen, setTrailerPopupOpen] = useState(false);
    const [isSynopsisExpanded, setSynopsisExpanded] = useState(false);

    if (!anime) return null; // Yorum: anime objesi yoksa geriye null dön

    const {
        title,
        images,
        synopsis,
        score,
        genres,
        episodes,
        status,
        duration,
        rating,
        trailer,
        aired,
        source,
    } = anime;

    const formatList = (items) => {
        return items && items.length > 0
            ? items.map((item) => item.name).join(', ')
            : 'N/A';
    };

    const toggleTrailerPopup = () => {
        setTrailerPopupOpen(!isTrailerPopupOpen);
    };

    const toggleSynopsis = () => {
        setSynopsisExpanded(!isSynopsisExpanded);
    };

    const synopsisPreview = synopsis
        ? synopsis.length > 250 && !isSynopsisExpanded
            ? synopsis.substring(0, 250) + '...'
            : synopsis
        : 'No synopsis available.';

    // Masaüstü versiyon
    const renderDesktopVersion = () => {
        return (
            <>
                <CSSTransition in={isOpen} timeout={300} classNames="modal-animation" unmountOnExit>
                    <Modal isOpen={isOpen} toggle={toggle} className="anime-detail-modal" size="xl" centered>
                        <ModalBody className="modal-body-custom">
                            <button className="modal-close-button" onClick={toggle}>
                                <FontAwesomeIcon icon={faTimesSolid} />
                            </button>
                            <div className="anime-detail-content">
                                <div
                                    className="anime-detail-background"
                                    style={{
                                        backgroundImage: `url(${images.jpg.large_image_url})`,
                                    }}
                                ></div>
                                <div className="anime-detail-content__overlay">
                                    <div className="anime-detail-content__info">
                                        <h2 className="anime-detail-content__title">{title}</h2>
                                        <div className="anime-detail-content__meta">
                                            <span className="release-date">{aired?.string || 'N/A'}</span>
                                            <span className="rating">
                                                <FontAwesomeIcon icon={faStar} /> {score || 'N/A'}
                                            </span>
                                            <span className="genres">{formatList(genres)}</span>
                                        </div>
                                        <p
                                            className={`anime-detail-content__overview ${isSynopsisExpanded ? 'expanded' : ''
                                                }`}
                                        >
                                            {synopsisPreview}
                                        </p>
                                        {synopsis && synopsis.length > 250 && (
                                            <button className="read-more-button" onClick={toggleSynopsis}>
                                                {isSynopsisExpanded ? 'Read Less' : 'Read More'}
                                            </button>
                                        )}
                                        <div className="anime-detail-content__stats">
                                            <div className="stat">
                                                <span className="label">Episodes</span>
                                                <span className="value">{episodes || 'N/A'}</span>
                                            </div>
                                            <div className="stat">
                                                <span className="label">Duration</span>
                                                <span className="value">{duration || 'N/A'}</span>
                                            </div>
                                            <div className="stat">
                                                <span className="label">Status</span>
                                                <span className="value">{status || 'N/A'}</span>
                                            </div>
                                            <div className="stat">
                                                <span className="label">Source</span>
                                                <span className="value">{source || 'N/A'}</span>
                                            </div>
                                            <div className="stat">
                                                <span className="label">Rating</span>
                                                <span className="value">{rating || 'N/A'}</span>
                                            </div>
                                        </div>
                                        <div className="anime-detail-content__actions">
                                            {trailer?.embed_url && (
                                                <button className="details-button" onClick={toggleTrailerPopup}>
                                                    <FontAwesomeIcon icon={faPlay} /> Watch Trailers
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </CSSTransition>

                {isTrailerPopupOpen && (
                    <Modal isOpen={isTrailerPopupOpen} toggle={toggleTrailerPopup} className="trailer-popup-modal" centered>
                        <ModalBody className="trailer-popup-body">
                            <div className="trailer-popup-video-wrapper">
                                <iframe
                                    src={`${trailer.embed_url}?autoplay=1`}
                                    allow="autoplay; encrypted-media"
                                    allowFullScreen
                                    title="Anime Trailer"
                                    className="trailer-popup-video-iframe"
                                />
                            </div>
                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    };

    // Mobil versiyon
    const renderMobileVersion = () => {
        // Yorum: Mobilde modal yerine tam sayfa kaplama tasarımı
        if (!isOpen) return null;

        return (
            <div className="mobile-detail-container">
                {/* Header Banner */}
                <div
                    className="mobile-detail-banner"
                    style={{
                        backgroundImage: `url(${images.jpg.large_image_url})`,
                    }}
                >
                    <div className="mobile-detail-banner-overlay">
                        <button className="mobile-detail-close-button" onClick={toggle}>
                            <FontAwesomeIcon icon={faTimesSolid} />
                        </button>
                    </div>
                </div>

                {/* Content Area */}
                <div className="mobile-detail-content">
                    <h1 className="mobile-title">{title}</h1>
                    <div className="mobile-meta">
                        <span className="mobile-rating">
                            <FontAwesomeIcon icon={faStar} /> {score || 'N/A'}
                        </span>
                        <span className="mobile-year">
                            {aired?.string || 'N/A'}
                        </span>
                    </div>
                    <div className="mobile-genres">
                        {genres && genres.length > 0
                            ? genres.map((g) => (
                                <span key={g.mal_id} className="mobile-genre-tag">
                                    {g.name}
                                </span>
                            ))
                            : 'N/A'}
                    </div>

                    <div
                        className={`mobile-overview ${isSynopsisExpanded ? 'expanded' : ''}`}
                    >
                        {synopsisPreview}
                    </div>

                    {synopsis && synopsis.length > 250 && (
                        <button className="mobile-read-more" onClick={toggleSynopsis}>
                            {isSynopsisExpanded ? 'Read Less' : 'Read More'}
                        </button>
                    )}

                    <div className="mobile-info-grid">
                        <div className="mobile-info-item">
                            <label>Episodes</label>
                            <span>{episodes || 'N/A'}</span>
                        </div>
                        <div className="mobile-info-item">
                            <label>Duration</label>
                            <span>{duration || 'N/A'}</span>
                        </div>
                        <div className="mobile-info-item">
                            <label>Status</label>
                            <span>{status || 'N/A'}</span>
                        </div>
                        <div className="mobile-info-item">
                            <label>Source</label>
                            <span>{source || 'N/A'}</span>
                        </div>
                        <div className="mobile-info-item">
                            <label>Rating</label>
                            <span>{rating || 'N/A'}</span>
                        </div>
                    </div>

                    {trailer?.embed_url && (
                        <button className="mobile-trailer-button" onClick={toggleTrailerPopup}>
                            <FontAwesomeIcon icon={faPlay} /> Watch Trailer
                        </button>
                    )}
                </div>

                {/* Trailer popup mobil */}
                {isTrailerPopupOpen && (
                    <div className="mobile-trailer-popup">
                        <button className="mobile-trailer-close" onClick={toggleTrailerPopup}>
                            <FontAwesomeIcon icon={faTimesSolid} />
                        </button>
                        <div className="mobile-trailer-video">
                            <iframe
                                src={`${trailer.embed_url}?autoplay=1`}
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title="Anime Trailer"
                                className="mobile-iframe"
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return isMobile ? renderMobileVersion() : renderDesktopVersion();
}

/********************************************************************************
 * Ana sabitler
 ********************************************************************************/
const animeTypes = [
    { value: 'tv', label: 'TV' },
    { value: 'movie', label: 'Movie' },
    { value: 'ova', label: 'OVA' },
    { value: 'special', label: 'Special' },
    { value: 'ona', label: 'ONA' },
    { value: 'music', label: 'Music' },
];
const airingTypes = [
    { value: 'airing', label: 'Airing' },
    { value: 'complete', label: 'Complete' },
    { value: 'upcoming', label: 'Upcoming' },
];
const statusIcons = {
    Completed: faCheckCircle,
    Watching: faPlayCircle,
    'On-Hold': faPauseCircle,
    Dropped: faTimesCircle,
    'Plan to Watch': faClock,
};

/********************************************************************************
 * Ana Anime Component
 ********************************************************************************/
export default function Anime() {
    const { isMobile } = useIsMobile(); // mobil dedektörü
    // Arama / Filtre state'leri
    const [animeSearchTextboxValue, setAnimeSearchTextboxValue] = useState('');
    const [animeTypeSelectboxValue, setAnimeTypeSelectboxValue] = useState('');
    const [airingTypeSelectboxValue, setAiringTypeSelectboxValue] = useState('');
    const [rangeValues, setRangeValues] = useState([0, 10]);
    const [yearsAndSeasons, setYearsAndSeasons] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedSeason, setSelectedSeason] = useState(null);

    // Ekran / Panel state'leri
    const [isFilterPanelVisible, setIsFilterPanelVisible] = useState(true);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isSearchVisible, setIsSearchVisible] = useState(false); // Mobilde FAB ile açılacak panel

    // Sonuç data + sayfa
    const [searchedAnimeDataSource, setSearchedAnimeDataSource] = useState([]);
    const [pageCountInfo, setPageCountInfo] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    // Modallar
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedAnime, setSelectedAnime] = useState(null);
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const [detailAnime, setDetailAnime] = useState(null);

    const postRequestSyncPromise = usePostRequestSyncPromise();

    /********************************************************
     * AnimeRating Modal Aç / Kapat
     ********************************************************/
    const openRatingModal = (anime) => {
        setSelectedAnime(anime);
        setModalOpen(true);
    };
    const closeRatingModal = () => {
        setModalOpen(false);
        setSelectedAnime(null);
    };
    const handleRatingSubmit = (updatedAnime) => {
        const userId = localStorage.getItem('userId');
        const postData = {
            series_animedb_id: updatedAnime.mal_id,
            series_title: updatedAnime.title,
            series_type: updatedAnime.type,
            series_episodes: updatedAnime.episodes,
            my_score: updatedAnime.my_score.toString(),
            my_status: updatedAnime.my_status,
            my_old_status: updatedAnime.my_status,
            userId: userId,
        };
        postRequestSyncPromise('UserAnimeProfile/InsertUserAnime', postData)
            .then(() => {
                setSearchedAnimeDataSource((prevData) =>
                    prevData.map((item) => (item.mal_id === updatedAnime.mal_id ? updatedAnime : item))
                );
                closeRatingModal();
            })
            .catch((error) => {
                console.error('Error updating anime:', error);
            });
    };

    /********************************************************
     * AnimeDetail Modal Aç / Kapat
     ********************************************************/
    const openDetailModal = (anime) => {
        setDetailAnime(anime);
        setDetailModalOpen(true);
    };
    const closeDetailModal = () => {
        setDetailModalOpen(false);
        setDetailAnime(null);
    };

    /********************************************************
     * API - Arama / Sezon
     ********************************************************/
    const fetchAnimeSearch = useCallback(() => {
        setIsLoading(true);
        const searchFilter = {
            q: animeSearchTextboxValue,
            type: animeTypeSelectboxValue,
            status: airingTypeSelectboxValue,
            min_score: rangeValues[0],
            max_score: rangeValues[1],
            page: currentPage,
            userId: localStorage.getItem('userId'),
        };
        postRequestSyncPromise('Anime/SearchAnime', searchFilter)
            .then((data) => {
                setSearchedAnimeDataSource(data.data);
                setPageCountInfo(data.pagination.last_visible_page);
                setIsLoading(false);
                window.scrollTo(0, 0);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    }, [
        animeSearchTextboxValue,
        animeTypeSelectboxValue,
        airingTypeSelectboxValue,
        rangeValues,
        postRequestSyncPromise,
        currentPage,
    ]);

    const fetchSeasonAnimes = useCallback(() => {
        setIsLoading(true);
        const pageInformation = {
            year: selectedYear,
            season: selectedSeason,
            userId: localStorage.getItem('userId'),
            page: currentPage,
        };
        postRequestSyncPromise('Anime/SearchSeasons', pageInformation)
            .then((data) => {
                setSearchedAnimeDataSource(data.data);
                setPageCountInfo(data.pagination.last_visible_page);
                setIsLoading(false);
                window.scrollTo(0, 0);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    }, [postRequestSyncPromise, selectedYear, selectedSeason, currentPage]);

    const fetchSeasonNowAnimes = useCallback(() => {
        setIsLoading(true);
        const pageInformation = {
            page: currentPage,
            userId: localStorage.getItem('userId'),
        };
        postRequestSyncPromise('Anime/SeasonNowAnimes', pageInformation)
            .then((data) => {
                setSearchedAnimeDataSource(data.data);
                setPageCountInfo(data.pagination.last_visible_page);
                setIsLoading(false);
                window.scrollTo(0, 0);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    }, [postRequestSyncPromise, currentPage]);

    const fetchSeasons = useCallback(() => {
        postRequestSyncPromise('Anime/AllSeasons', {})
            .then((data) => {
                setYearsAndSeasons(data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [postRequestSyncPromise]);

    const handleSearchBySeason = () => {
        setIsSearchActive(false);
        setCurrentPage(1);
        fetchSeasonAnimes();
    };

    /********************************************************
     * Paginasyon
     ********************************************************/
    const handlePageClick = (data) => {
        let selectedPage = data.selected + 1;
        setCurrentPage(selectedPage);
    };

    /********************************************************
     * Panel Aç / Kapat (Arama vs.)
     ********************************************************/
    const toggleFilterPanel = () => {
        setIsFilterPanelVisible(!isFilterPanelVisible);
        setIsSearchActive(false);
        setCurrentPage(1);
        setSearchedAnimeDataSource([]);
        setAnimeSearchTextboxValue('');
        setAnimeTypeSelectboxValue('');
        setAiringTypeSelectboxValue('');
        setRangeValues([0, 10]);
        setSelectedYear(null);
        setSelectedSeason(null);
    };

    /********************************************************
     * Desktop Filtre Panel
     ********************************************************/
    const renderDesktopFilterPanel = () => {
        const customStyles = {
            control: (provided, state) => ({
                ...provided,
                backgroundColor: 'transparent',
                borderColor: state.isFocused ? 'var(--glow-color)' : 'var(--border-color)',
                boxShadow: state.isFocused ? `0 0 8px var(--glow-color)` : 'none',
                '&:hover': {
                    borderColor: 'var(--glow-color)',
                },
            }),
            singleValue: (provided) => ({
                ...provided,
                color: 'var(--text-color)',
            }),
            placeholder: (provided) => ({
                ...provided,
                color: 'rgba(255, 255, 255, 0.6)',
                fontStyle: 'italic',
            }),
            indicatorSeparator: () => ({
                display: 'none',
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
                color: state.isFocused ? 'var(--glow-color)' : 'var(--text-color)',
                '&:hover': {
                    color: 'var(--glow-color)',
                },
            }),
            menu: (provided) => ({
                ...provided,
                backgroundColor: 'rgba(32, 43, 96, 0.9)',
                border: '1px solid var(--border-color)',
                boxShadow: `0 8px 16px var(--glow-color)`,
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                    ? 'var(--hover-gradient-2)'
                    : state.isFocused
                        ? 'var(--hover-gradient-1)'
                        : 'transparent',
                color: 'var(--text-color)',
                '&:active': {
                    backgroundColor: 'var(--primary-gradient-2)',
                },
            }),
        };

        return (
            <div className="search-bar">
                <Row>
                    <Col xs="3">
                        <div className="input-with-icon">
                            <input
                                type="text"
                                className="search-input"
                                value={animeSearchTextboxValue}
                                onChange={(e) => setAnimeSearchTextboxValue(e.target.value)}
                                placeholder="Search Anime..."
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setIsSearchActive(true);
                                        setCurrentPage(1);
                                        fetchAnimeSearch();
                                    }
                                }}
                            />
                            <FaSearch className="input-icon" />
                        </div>
                    </Col>
                    <Col xs="3">
                        <Select
                            styles={customStyles}
                            options={animeTypes}
                            value={animeTypes.find((option) => option.value === animeTypeSelectboxValue)}
                            onChange={(selectedOption) =>
                                setAnimeTypeSelectboxValue(selectedOption ? selectedOption.value : '')
                            }
                            placeholder="Type"
                            isClearable
                            classNamePrefix="filter-select"
                            components={{
                                DropdownIndicator: () => <FaFilm className="select-icon" />,
                            }}
                        />
                    </Col>
                    <Col xs="3">
                        <Select
                            styles={customStyles}
                            options={airingTypes}
                            value={airingTypes.find((option) => option.value === airingTypeSelectboxValue)}
                            onChange={(selectedOption) =>
                                setAiringTypeSelectboxValue(selectedOption ? selectedOption.value : '')
                            }
                            placeholder="Status"
                            isClearable
                            classNamePrefix="filter-select"
                            components={{
                                DropdownIndicator: () => <FaBroadcastTower className="select-icon" />,
                            }}
                        />
                    </Col>
                    <Col xs="3">
                        <div className="slider-container">
                            <ReactSlider
                                className="dual-slider"
                                thumbClassName="dual-slider-thumb"
                                trackClassName="dual-slider-track"
                                min={0}
                                max={10}
                                step={1}
                                value={rangeValues}
                                onChange={(values) => setRangeValues(values)}
                                renderThumb={(props, state) => (
                                    <div {...props}>
                                        <div className="thumb-value">{state.valueNow}</div>
                                    </div>
                                )}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="filter-buttons">
                    <Col xs="6">
                        <button
                            className="filter-button"
                            onClick={() => {
                                setIsSearchActive(true);
                                setCurrentPage(1);
                                fetchAnimeSearch();
                            }}
                        >
                            <FaSearch />
                        </button>
                    </Col>
                    <Col xs="6">
                        <button className="filter-button" onClick={toggleFilterPanel}>
                            <FaSlidersH />
                        </button>
                    </Col>
                </Row>
            </div>
        );
    };

    /********************************************************
     * Desktop Sezon Panel
     ********************************************************/
    const renderDesktopSeasonPanel = () => {
        const customStyles = {
            control: (provided, state) => ({
                ...provided,
                backgroundColor: 'transparent',
                borderColor: state.isFocused ? 'var(--glow-color)' : 'var(--border-color)',
                boxShadow: state.isFocused ? `0 0 8px var(--glow-color)` : 'none',
                '&:hover': {
                    borderColor: 'var(--glow-color)',
                },
            }),
            singleValue: (provided) => ({
                ...provided,
                color: 'var(--text-color)',
            }),
            placeholder: (provided) => ({
                ...provided,
                color: 'rgba(255, 255, 255, 0.6)',
                fontStyle: 'italic',
            }),
            indicatorSeparator: () => ({
                display: 'none',
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
                color: state.isFocused ? 'var(--glow-color)' : 'var(--text-color)',
                '&:hover': {
                    color: 'var(--glow-color)',
                },
            }),
            menu: (provided) => ({
                ...provided,
                backgroundColor: 'rgba(32, 43, 96, 0.9)',
                border: '1px solid var(--border-color)',
                boxShadow: `0 8px 16px var(--glow-color)`,
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                    ? 'var(--hover-gradient-2)'
                    : state.isFocused
                        ? 'var(--hover-gradient-1)'
                        : 'transparent',
                color: 'var(--text-color)',
                '&:active': {
                    backgroundColor: 'var(--primary-gradient-2)',
                },
            }),
        };

        return (
            <div className="search-bar">
                <Row>
                    <Col xs="6">
                        <Select
                            styles={customStyles}
                            options={yearsAndSeasons.map((item) => ({
                                value: item.year,
                                label: item.year,
                            }))}
                            value={
                                selectedYear ? { value: selectedYear, label: selectedYear } : null
                            }
                            onChange={(selectedOption) => {
                                setSelectedYear(selectedOption ? selectedOption.value : null);
                                setSelectedSeason(null);
                            }}
                            placeholder="Year"
                            isClearable
                            classNamePrefix="filter-select"
                            components={{
                                DropdownIndicator: () => <FaSyncAlt className="select-icon" />,
                            }}
                        />
                    </Col>
                    <Col xs="6">
                        <Select
                            styles={customStyles}
                            options={
                                selectedYear
                                    ? yearsAndSeasons
                                        .find((item) => item.year === selectedYear)
                                        ?.seasons.map((season) => ({
                                            value: season,
                                            label: season,
                                        })) || []
                                    : []
                            }
                            value={
                                selectedSeason ? { value: selectedSeason, label: selectedSeason } : null
                            }
                            onChange={(selectedOption) =>
                                setSelectedSeason(selectedOption ? selectedOption.value : null)
                            }
                            placeholder="Season"
                            isClearable
                            isDisabled={!selectedYear}
                            classNamePrefix="filter-select"
                            components={{
                                DropdownIndicator: () => <FaLeaf className="select-icon" />,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="filter-buttons">
                    <Col xs="6">
                        <button
                            className="filter-button"
                            onClick={handleSearchBySeason}
                            disabled={!selectedYear || !selectedSeason}
                        >
                            <FaSearch />
                        </button>
                    </Col>
                    <Col xs="6">
                        <button className="filter-button" onClick={toggleFilterPanel}>
                            <FaSlidersH />
                        </button>
                    </Col>
                </Row>
            </div>
        );
    };
    /********************************************************
     * Mobil Arama Panel (Floating Action Button)
     ********************************************************/

    useEffect(() => {
        if (isSearchVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isSearchVisible]);

    /********************************************************
     * Masaüstü versiyon
     ********************************************************/
    const renderDesktopVersion = () => {
        return (
            <div className="anime-container">
                {isFilterPanelVisible ? renderDesktopFilterPanel() : renderDesktopSeasonPanel()}
                <br />
                {isLoading ? <div className="loading">Loading...</div> : renderAnimeCardsDesktop()}

                <div className="pagination-container">
                    <ReactPaginate
                        previousLabel={'back'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        pageCount={pageCountInfo}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        forcePage={currentPage - 1}
                    />
                </div>

                {/* Rating Modal */}
                {modalOpen && selectedAnime && (
                    <AnimeRating
                        anime={selectedAnime}
                        onClose={closeRatingModal}
                        onSubmit={handleRatingSubmit}
                        isMobile={false} // <-- masaüstü
                    />
                )}
                {/* Detay Modal */}
                {detailModalOpen && detailAnime && (
                    <AnimeDetailModal
                        anime={detailAnime}
                        isOpen={detailModalOpen}
                        toggle={closeDetailModal}
                        isMobile={false} // <-- masaüstü
                    />
                )}
            </div>
        );
    };

    /********************************************************
     * Filtreleri Sıfırla
     ********************************************************/
    const resetFilters = () => {
        // Arama filtrelerini sıfırla
        setAnimeSearchTextboxValue('');
        setAnimeTypeSelectboxValue('');
        setAiringTypeSelectboxValue('');
        setRangeValues([0, 10]);

        // Sezon filtrelerini sıfırla
        setSelectedYear(null);
        setSelectedSeason(null);
    };
    /********************************************************
 * Mobil versiyon
 ********************************************************/
    const renderMobileVersion = () => {
        return (
            <>
                <div>
                    {/* Floating Action Button */}
                    <button className="mobile-filter-fab" onClick={() => setIsSearchVisible(!isSearchVisible)}>
                        <FontAwesomeIcon icon={isSearchVisible ? faTimes : faFilter} />
                    </button>
    
                    {/* Mobil Filtre Paneli */}
                    <div className={`mobile-filter-overlay ${isSearchVisible ? 'visible' : ''}`}>
                        <div className="mobile-filter-sheet">
                            {/* Header */}
                            <div className="filter-header">
                                <h2 className="filter-title">Filter & Sort</h2>
                                <div className="header-buttons">
                                    {/* Reset Button */}
                                    <button
                                        className="reset-button"
                                        onClick={resetFilters}
                                    >
                                        <FontAwesomeIcon icon={faSyncAlt} /> Reset
                                    </button>
                                    {/* Close Button */}
                                    <button
                                        className="close-button"
                                        onClick={() => setIsSearchVisible(false)}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                </div>
                            </div>
    
                            {/* Toggle Buttons */}
                            <div className="filter-tabs">
                                <button
                                    className={`tab-button ${!isFilterPanelVisible ? 'active' : ''}`}
                                    onClick={() => {
                                        setIsFilterPanelVisible(false);
                                        resetFilters(); // Sezon filtresine geçişte arama filtrelerini sıfırla
                                    }}
                                >
                                    Seasons
                                </button>
                                <button
                                    className={`tab-button ${isFilterPanelVisible ? 'active' : ''}`}
                                    onClick={() => {
                                        setIsFilterPanelVisible(true);
                                        resetFilters(); // Arama filtresine geçişte sezon filtrelerini sıfırla
                                    }}
                                >
                                    Filters
                                </button>
                            </div>
    
                            {/* Content */}
                            <div className="filter-content">
                                {isFilterPanelVisible ? (
                                    <>
                                        {/* Search Filters */}
                                        <div className="search-bar">
                                            <FontAwesomeIcon icon={faSearch} />
                                            <input
                                                type="text"
                                                value={animeSearchTextboxValue}
                                                onChange={(e) => setAnimeSearchTextboxValue(e.target.value)}
                                                placeholder="Search anime..."
                                            />
                                        </div>
    
                                        <div className="filter-section">
                                            <label>Type</label>
                                            <div className="chip-group">
                                                {animeTypes.map((type) => (
                                                    <button
                                                        key={type.value}
                                                        className={`filter-chip ${animeTypeSelectboxValue === type.value ? 'active' : ''}`}
                                                        onClick={() => setAnimeTypeSelectboxValue(type.value)}
                                                    >
                                                        {type.label}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
    
                                        <div className="filter-section">
                                            <label>Status</label>
                                            <div className="chip-group">
                                                {airingTypes.map((status) => (
                                                    <button
                                                        key={status.value}
                                                        className={`filter-chip ${airingTypeSelectboxValue === status.value ? 'active' : ''}`}
                                                        onClick={() => setAiringTypeSelectboxValue(status.value)}
                                                    >
                                                        {status.label}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
    
                                        <div className="filter-section">
                                            <label>Score Range: {rangeValues[0]} - {rangeValues[1]}</label>
                                            <ReactSlider
                                                className="range-slider"
                                                thumbClassName="range-thumb"
                                                trackClassName="range-track"
                                                min={0}
                                                max={10}
                                                step={1}
                                                value={rangeValues}
                                                onChange={setRangeValues}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div className="season-selector">
                                        {/* Season Filters */}
                                        <div className="filter-section">
                                            <label>Year</label>
                                            <select
                                                value={selectedYear || ''}
                                                onChange={(e) => {
                                                    setSelectedYear(e.target.value || null);
                                                    setSelectedSeason(null);
                                                }}
                                                className="select-input"
                                            >
                                                <option value="">Select Year</option>
                                                {yearsAndSeasons.map((item) => (
                                                    <option key={item.year} value={item.year}>
                                                        {item.year}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
    
                                        <div className="filter-section">
                                            <label>Season</label>
                                            <select
                                                value={selectedSeason || ''}
                                                onChange={(e) => setSelectedSeason(e.target.value || null)}
                                                className="select-input"
                                                disabled={!selectedYear}
                                            >
                                                <option value="">Select Season</option>
                                                {selectedYear &&
                                                    yearsAndSeasons
                                                        .find((item) => String(item.year) === String(selectedYear))
                                                        ?.seasons.map((season) => (
                                                            <option key={season} value={season}>
                                                                {season}
                                                            </option>
                                                        ))}
                                            </select>
                                        </div>
                                    </div>
                                )}
                            </div>
    
                            {/* Apply Button */}
                            <button
                                className="apply-button"
                                onClick={() => {
                                    if (isFilterPanelVisible) {
                                        setCurrentPage(1);
                                        fetchAnimeSearch();
                                    } else {
                                        handleSearchBySeason();
                                    }
                                }}
                            >
                                Apply Filters
                            </button>
                        </div>
                    </div>
                </div>
    
                {/* Anime List and Pagination */}
                <div className="mobile-content">
                    {isLoading ? <div className="loading">Loading...</div> : renderAnimeCardsMobile()}
                </div>
    
                <div className="pagination-container-mobile">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        pageCount={pageCountInfo}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination-mobile'}
                        activeClassName={'active'}
                        forcePage={currentPage - 1}
                    />
                </div>
    
                {/* Rating Modal */}
                {modalOpen && selectedAnime && (
                    <AnimeRating
                        anime={selectedAnime}
                        onClose={closeRatingModal}
                        onSubmit={handleRatingSubmit}
                        isMobile={true}
                        style={{ zIndex: 9999 }}
                    />
                )}
    
                {/* Detail Modal */}
                {detailModalOpen && detailAnime && (
                    <AnimeDetailModal
                        anime={detailAnime}
                        isOpen={detailModalOpen}
                        toggle={closeDetailModal}
                        isMobile={true}
                    />
                )}
            </>
        );
    };

    /********************************************************
     * Anime Kartlarını render et (Desktop)
     ********************************************************/
    const renderAnimeCardsDesktop = () => {
        return (
            <div className="anime-list">
                {searchedAnimeDataSource.map((anime) => (
                    <div key={anime.mal_id} className="anime-card">
                        {/* 
                Yorum: Tıklanabilir alanı 
                "anime-card-inner" yapısıyla text tıklamasına engel olmamak için 
                butonun z-index ayarlarına CSS tarafından dikkat edeceğiz.
              */}
                        <div
                            className="anime-card-inner"
                            onClick={() => openDetailModal(anime)}
                        >
                            <div className="anime-image-container">
                                <img
                                    src={anime.images.jpg.large_image_url}
                                    alt={anime.title}
                                    className="anime-image"
                                />
                                <div className="anime-score-overlay">
                                    <FontAwesomeIcon icon={faStar} /> {anime.score}
                                </div>
                                <button
                                    className="update-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openRatingModal(anime);
                                    }}
                                >
                                    <i className="fa fa-pencil-alt"></i>
                                </button>
                            </div>
                            {(anime.my_status || (anime.my_score && anime.my_score > 0)) && (
                                <div
                                    className={`anime-user-score ${anime.my_score > 0
                                        ? getScoreClass(anime.my_score)
                                        : getStatusClass(anime.my_status)
                                        } ${anime.my_score > 0 ? 'has-score' : ''}`}
                                >
                                    {anime.my_status ? (
                                        <FontAwesomeIcon icon={statusIcons[anime.my_status]} />
                                    ) : anime.my_score > 0 ? (
                                        <FontAwesomeIcon icon={faStar} />
                                    ) : null}
                                    {anime.my_score > 0 && <span>{anime.my_score}</span>}
                                </div>
                            )}
                            <div className="anime-card-content">
                                <h5 className="anime-title">{anime.title}</h5>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    /********************************************************
     * Anime Kartlarını render et (Mobil)
     ********************************************************/
    const renderAnimeCardsMobile = () => {
        return (
            <div className="mobile-anime-cards">
                {searchedAnimeDataSource.map((anime) => (
                    <div
                        key={anime.mal_id}
                        className="mobile-anime-card"
                    >
                        <div className="mobile-anime-image-wrapper">
                            <img src={anime.images.jpg.large_image_url} alt={anime.title} />

                            {/* Update Button (kalem) */}
                            <button
                                className="mobile-update-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openRatingModal(anime);
                                }}
                            >
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </button>

                            {/* Status / Score Badge */}
                            {(anime.my_status || (anime.my_score && anime.my_score > 0)) && (
                                <div
                                    className={`anime-user-score-mobile ${anime.my_score > 0
                                        ? getScoreClass(anime.my_score)
                                        : getStatusClass(anime.my_status)
                                        } ${anime.my_score > 0 ? 'has-score' : ''}`}
                                >
                                    {anime.my_status ? (
                                        <FontAwesomeIcon icon={statusIcons[anime.my_status]} />
                                    ) : anime.my_score > 0 ? (
                                        <FontAwesomeIcon icon={faStar} />
                                    ) : null}
                                    {anime.my_score > 0 && <span>{anime.my_score}</span>}
                                </div>
                            )}

                            {/* Tıklanabilir Layer */}
                            <div
                                className="mobile-card-overlay"
                                onClick={() => openDetailModal(anime)}
                            ></div>
                        </div>

                        <div className="mobile-anime-info">
                            <h5 className="mobile-anime-title">{anime.title}</h5>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    /********************************************************
     * score + status helper
     ********************************************************/
    const getScoreClass = (score) => {
        const numScore = parseInt(score);
        if (numScore >= 8) return 'score-excellent';
        if (numScore >= 6) return 'score-good';
        if (numScore >= 4) return 'score-average';
        return 'score-poor';
    };
    const getStatusClass = (status) => {
        switch (status) {
            case 'Completed':
                return 'status-completed';
            case 'Watching':
                return 'status-watching';
            case 'On-Hold':
                return 'status-onhold';
            case 'Dropped':
                return 'status-dropped';
            case 'Plan to Watch':
                return 'status-plantowatch';
            default:
                return 'status-default';
        }
    };

    /********************************************************
     * useEffect / İlk Yük
     ********************************************************/
    useEffect(() => {
        if (currentPage > 0) {
            setIsLoading(true);

            // Mobil ve Masaüstü için filtre kontrolü
            if (isMobile) {
                // Mobilde sezon veya arama filtresi kontrolü
                if (selectedYear && selectedSeason) {
                    // Sezon filtresi doluysa
                    fetchSeasonAnimes();
                } else if (
                    animeSearchTextboxValue.trim() !== '' ||
                    animeTypeSelectboxValue !== '' ||
                    airingTypeSelectboxValue !== '' ||
                    (rangeValues[0] !== 0 || rangeValues[1] !== 10)
                ) {
                    // Arama filtresi doluysa
                    fetchAnimeSearch();
                } else {
                    // Hiçbir filtre dolu değilse
                    fetchSeasonNowAnimes();
                }
            } else {
                // Masaüstü için kontrol
                if (isFilterPanelVisible) {
                    if (isSearchActive) {
                        fetchAnimeSearch();
                    } else {
                        fetchSeasonNowAnimes();
                    }
                } else if (selectedYear && selectedSeason) {
                    fetchSeasonAnimes();
                }
            }
        }
    }, [
        currentPage
    ]);

    useEffect(() => {
        if (!isFilterPanelVisible && yearsAndSeasons.length === 0) {
            fetchSeasons();
        }
    }, [isFilterPanelVisible, yearsAndSeasons.length, fetchSeasons]);

    /********************************************************
     * Render
     ********************************************************/
    return isMobile ? renderMobileVersion() : renderDesktopVersion();
}