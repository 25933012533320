import React, { useContext, useEffect, useRef, useState } from 'react';
import './profile.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faCheck,
  faTimes,
  faUserPlus,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../App';
import { AuthContext } from '../../context/AuthContext';
import { usePostRequestSyncPromise } from '../../global/GlobalFetch';
import useIsMobile from '../../components/mobile/IsMobile';

function Profile() {
  const { allUsers, friendShipRequests } = useContext(UserContext);
  const { isAuthenticated } = useContext(AuthContext);
  const { isMobile, orientation } = useIsMobile();
  const userName = localStorage.getItem('userCode') || '';

  const postRequestSyncPromise = usePostRequestSyncPromise();

  const [newFriendUserName, setNewFriendUserName] = useState('');

  // Arkadaşlık isteğini kabul etme
  const handleAcceptRequest = (request) => {
    const baseReq = {
      data: [
        {
          senderUserName: request.senderUserName,
          receiverUserName: request.receiverUserName
        }
      ]
    };
    postRequestSyncPromise('Auth/InsertFriendsToUser', baseReq)
      .then((resp) => {
        // SignalR event’leri ile listeler otomatik güncellenecek
      })
      .catch((err) => console.error('Arkadaşlık isteği kabul hatası:', err));
  };

  // Arkadaşlık isteğini reddetme
  const handleDeclineRequest = (request) => {
    const baseReq = {
      data: [
        {
          senderUserName: request.senderUserName,
          receiverUserName: request.receiverUserName
        }
      ]
    };
    postRequestSyncPromise('Friendship/DeleteFriendshipRequest', baseReq)
      .then((resp) => {
        // SignalR event’leri ile listeler otomatik güncellenecek
      })
      .catch((err) => console.error('Arkadaşlık isteği reddetme hatası:', err));
  };

  // Arkadaş silme
  const handleRemoveFriend = (friendUserName) => {
    const baseReq = {
      data: [
        {
          senderUserName: userName,
          receiverUserName: friendUserName
        }
      ]
    };
    postRequestSyncPromise('Auth/RemoveFriendsFromUser', baseReq)
      .then((resp) => {
        // SignalR event’leri ile listeler otomatik güncellenecek
      })
      .catch((err) => console.error('Arkadaş silme hatası:', err));
  };

  // Yeni arkadaşlık isteği oluşturma
  const handleSendFriendRequest = () => {
    if (userName === newFriendUserName) {
      console.error('Kendinize arkadaşlık isteği gönderemezsiniz.');
      return;
    }
    if (allUsers.some(user => user.userName === newFriendUserName)) {
      console.error('Seçtiğiniz kullanıcı zaten arkadaş listenizde.');
      return;
    }
    const baseReq = {
      data: [
        {
          senderUserName: userName,
          receiverUserName: newFriendUserName
        }
      ]
    };
    postRequestSyncPromise('Friendship/InsertFriendshipRequest', baseReq)
      .then((resp) => {
        setNewFriendUserName('');
      })
      .catch((err) => console.error('Arkadaşlık isteği gönderme hatası:', err));
  };

  // Kullanıcı login değilse basit bir mesaj
  if (!isAuthenticated) {
    return <div>Lütfen giriş yapınız.</div>;
  }

  // Masaüstü versiyonunu render eden fonksiyon
  const renderDesktopVersion = () => {
    return (
      <div className="profile-root">
        <div className="profile-container">
          <h1 className="profile-title">Profil & Arkadaşlar</h1>

          {/* Arkadaşlarım */}
          <section className="content-section">
            <div className="section-header">
              <h2>Arkadaşlarım</h2>
              <div className="section-line"></div>
            </div>
            {allUsers.length === 0 ? (
              <div className="empty-state">
                <span className="empty-icon">👥</span>
                <p>Henüz arkadaşın yok</p>
              </div>
            ) : (
              <div className="cards-grid">
                {allUsers.map((friend) => (
                  <div key={friend.userName} className="modern-card">
                    <div className="card-image">
                      {friend.ImageUrl ? (
                        <img
                          src={friend.ImageUrl}
                          alt={friend.userName}
                        />
                      ) : (
                        <div className="fallback-icon-container">
                          <FontAwesomeIcon icon={faUser} className="avatar-icon-fallback" />
                        </div>
                      )}
                      <div className="card-overlay">
                        <button
                          className="modern-button danger"
                          onClick={() => handleRemoveFriend(friend.userName)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </div>
                    </div>
                    <div className="card-content">
                      <h3>{friend.userName}</h3>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </section>

          {/* Arkadaşlık İstekleri */}
          <section className="content-section">
            <div className="section-header">
              <h2>Arkadaşlık İstekleri</h2>
              <div className="section-line"></div>
            </div>
            {friendShipRequests.length === 0 ? (
              <div className="empty-state">
                <span className="empty-icon">✉️</span>
                <p>Bekleyen istek yok</p>
              </div>
            ) : (
              <div className="requests-container">
                {friendShipRequests.map((req, idx) => (
                  <div key={idx} className="request-card">
                    <div className="request-content">
                      <div className="user-avatars">
                        {req.senderUserImageUrl ? (
                          <img
                            src={req.senderUserImageUrl}
                            alt={req.senderUserName}
                            className="avatar-img"
                          />
                        ) : (
                          <FontAwesomeIcon icon={faUser} className="avatar-icon-fallback" />
                        )}
                        <div className="arrow-icon">→</div>
                        {req.receiverUserImageUrl ? (
                          <img
                            src={req.receiverUserImageUrl}
                            alt={req.receiverUserName}
                            className="avatar-img"
                          />
                        ) : (
                          <FontAwesomeIcon icon={faUser} className="avatar-icon-fallback" />
                        )}
                      </div>
                      <div className="request-users">
                        <span>{req.senderUserName}</span>
                        <span>{req.receiverUserName}</span>
                      </div>
                    </div>
                    {!req.amISender ? (
                      <div className="request-actions">
                        <button
                          className="modern-button success"
                          onClick={() => handleAcceptRequest(req)}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button
                          className="modern-button secondary"
                          onClick={() => handleDeclineRequest(req)}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </div>
                    ) : (
                      <div className="pending-status">Beklemede</div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </section>

          {/* Yeni Arkadaş Ekle */}
          <section className="content-section">
            <div className="section-header">
              <h2>Yeni Arkadaş Ekle</h2>
              <div className="section-line"></div>
            </div>
            <div className="search-container">
              <input
                type="text"
                placeholder="Kullanıcı ara..."
                value={newFriendUserName}
                onChange={(e) => setNewFriendUserName(e.target.value)}
                className="search-input"
              />
              <button className="modern-button primary" onClick={handleSendFriendRequest}>
                <FontAwesomeIcon icon={faUserPlus} />
              </button>
            </div>
          </section>
        </div>
      </div>
    );
  };

  // Mobil versiyonunu render eden fonksiyon
  const renderMobileVersion = () => {
    return (
      <div className="profile-root mobile">
        <div className="profile-container-mobile">
          <h1 className="profile-title">Profil & Arkadaşlar (Mobil)</h1>

          {/* Arkadaşlarım */}
          <section className="content-section">
            <div className="section-header">
              <h2>Arkadaşlarım</h2>
              <div className="section-line"></div>
            </div>
            {allUsers.length === 0 ? (
              <div className="empty-state">
                <span className="empty-icon">👥</span>
                <p>Henüz arkadaşın yok</p>
              </div>
            ) : (
              <div className="cards-grid-mobile">
                {allUsers.map((friend) => (
                  <div key={friend.userName} className="modern-card-mobile">
                    <div className="card-image">
                      {friend.ImageUrl ? (
                        <img
                          src={friend.ImageUrl}
                          alt={friend.userName}
                        />
                      ) : (
                        <div className="fallback-icon-container">
                          <FontAwesomeIcon icon={faUser} className="avatar-icon-fallback" />
                        </div>
                      )}
                      <div className="card-overlay">
                        <button
                          className="modern-button danger"
                          onClick={() => handleRemoveFriend(friend.userName)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </div>
                    </div>
                    <div className="card-content">
                      <h3>{friend.userName}</h3>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </section>

          {/* Arkadaşlık İstekleri */}
          <section className="content-section">
            <div className="section-header">
              <h2>Arkadaşlık İstekleri</h2>
              <div className="section-line"></div>
            </div>
            {friendShipRequests.length === 0 ? (
              <div className="empty-state">
                <span className="empty-icon">✉️</span>
                <p>Bekleyen istek yok</p>
              </div>
            ) : (
              <div className="requests-container">
                {friendShipRequests.map((req, idx) => (
                  <div key={idx} className="request-card">
                    <div className="request-content">
                      <div className="user-avatars">
                        {req.senderUserImageUrl ? (
                          <img
                            src={req.senderUserImageUrl}
                            alt={req.senderUserName}
                            className="avatar-img"
                          />
                        ) : (
                          <FontAwesomeIcon icon={faUser} className="avatar-icon-fallback" />
                        )}
                        <div className="arrow-icon">→</div>
                        {req.receiverUserImageUrl ? (
                          <img
                            src={req.receiverUserImageUrl}
                            alt={req.receiverUserName}
                            className="avatar-img"
                          />
                        ) : (
                          <FontAwesomeIcon icon={faUser} className="avatar-icon-fallback" />
                        )}
                      </div>
                      <div className="request-users">
                        <span>{req.senderUserName}</span>
                        <span>{req.receiverUserName}</span>
                      </div>
                    </div>
                    {!req.amISender ? (
                      <div className="request-actions">
                        <button
                          className="modern-button success"
                          onClick={() => handleAcceptRequest(req)}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button
                          className="modern-button secondary"
                          onClick={() => handleDeclineRequest(req)}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </div>
                    ) : (
                      <div className="pending-status">Beklemede</div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </section>

          {/* Yeni Arkadaş Ekle */}
          <section className="content-section">
            <div className="section-header">
              <h2>Yeni Arkadaş Ekle</h2>
              <div className="section-line"></div>
            </div>
            <div className="search-container">
              <input
                type="text"
                placeholder="Kullanıcı ara..."
                value={newFriendUserName}
                onChange={(e) => setNewFriendUserName(e.target.value)}
                className="search-input"
              />
              <button className="modern-button primary" onClick={handleSendFriendRequest}>
                <FontAwesomeIcon icon={faUserPlus} />
              </button>
            </div>
          </section>
        </div>
      </div>
    );
  };

  // Ekran boyutuna göre render
  return isMobile ? renderMobileVersion() : renderDesktopVersion();
}

export default Profile;
