import React, { useContext, useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaUser, FaWindowMaximize, FaTimes } from 'react-icons/fa';
import { UserContext } from '../../App';
import useIsMobile from '../mobile/IsMobile';

import './ActiveUsers.css';

export default function ActiveUsers({ isOpen, toggle }) {
    const { activeUsers } = useContext(UserContext);
    const isMobile = useIsMobile();
    const [expandedUser, setExpandedUser] = useState(null);
    const [modalSizeStyle, setModalSizeStyle] = useState({
        width: '80%',
        height: '80%'
    });

    useEffect(() => {
        const newSize = isMobile
            ? { width: '95%', height: '85%' }
            : activeUsers.length <= 2
                ? { width: '50%', height: '50%' }
                : activeUsers.length <= 6
                    ? { width: '70%', height: '70%' }
                    : { width: '90%', height: '80%' };

        setModalSizeStyle((prev) =>
            prev.width !== newSize.width || prev.height !== newSize.height ? newSize : prev
        );
    }, [activeUsers, isMobile]);


    const handleMouseEnter = (userName) => {
        if (!isMobile) {
            setExpandedUser(userName);
        }
    };
    const handleMouseLeave = (userName) => {
        if (!isMobile) {
            setExpandedUser((prev) => (prev === userName ? null : prev));
        }
    };

    const handleClickCard = (userName) => {
        if (isMobile) {
            setExpandedUser((prev) => (prev === userName ? null : userName));
        }
    };
    const fewUsers = activeUsers.length <= 4;
    const renderUsers = () => {
        if (activeUsers.length === 0) {
            return (
                <div className="empty-state-active-users">
                    <FaUser className="empty-icon-active-users" />
                    <p>No active users</p>
                </div>
            );
        }

        return (
            <div
                className={`users-grid-active-users ${fewUsers ? 'few-users-active-users' : 'many-users-active-users'
                    }`}
            >
                {activeUsers.map((user) => {
                    const isExpanded = expandedUser === user.userName;

                    return (
                        <div
                            key={user.userName}
                            className={`user-profile-card-active-users ${isExpanded ? 'expanded-card-active-users' : ''
                                }`}
                            onMouseEnter={() => handleMouseEnter(user.userName)}
                            onMouseLeave={() => handleMouseLeave(user.userName)}
                            onClick={() => handleClickCard(user.userName)}
                        >
                            <div
                                className={`card-content-active-users ${isExpanded ? 'blurred-active-users' : ''
                                    }`}
                            >
                                <div className="user-avatar-active-users">
                                    <FaUser />
                                </div>
                                <h3 className="user-name-active-users">{user.userName}</h3>
                                <div className="sessions-indicator-active-users">
                                    <span className="dot-active-users active"></span>
                                    <span className="sessions-count-active-users">
                                        {user.sessions.length}
                                    </span>
                                </div>
                            </div>

                            {isExpanded && (
                                <div className="sessions-overlay-active-users">
                                    <div className="sessions-content-active-users">
                                        {user.sessions.map((session) => (
                                            <div
                                                key={session.sessionId}
                                                className="session-item-active-users"
                                            >
                                                <FaWindowMaximize />
                                                <span>{session.location}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggle}
            style={{
                content: {
                    ...modalSizeStyle,
                    background: 'transparent',
                    border: 'none',
                    inset: 'auto',
                    zIndex: 1300,
                },
                overlay: {},
            }}
            className="active-users-modal-content-active-users"
            overlayClassName="active-users-modal-overlay-active-users"
            ariaHideApp={false}
        >
            <button className="close-modal-button-active-users" onClick={toggle}>
                <FaTimes />
            </button>

            <div
                className={`active-users-container-active-users ${isMobile ? 'active-users-mobile' : 'active-users-desktop'
                    }`}
            >
                <h2 className="active-users-title-active-users">Active Users</h2>
                {renderUsers()}
            </div>
        </Modal>
    );
}
