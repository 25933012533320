import { useState, useEffect } from 'react';

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [orientation, setOrientation] = useState(window.screen.orientation?.type || 'landscape-primary');

    useEffect(() => {
        // User Agent ile mobil cihaz tespiti
        const checkIfMobile = () => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            return /android|avantgo|blackberry|iphone|ipad|ipod|iemobile|opera mini|mobile|tablet/i.test(userAgent);
        };

        // İlk yükleme sırasında kontrol et
        setIsMobile(checkIfMobile());

        // Orientation değişikliklerini izleme
        const handleOrientationChange = () => {
            setOrientation(window.screen.orientation?.type || 'landscape-primary');
        };

        window.addEventListener('orientationchange', handleOrientationChange);

        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, []);

    return { isMobile, orientation };
};

export default useIsMobile;