import React, { useEffect, useState, useRef, useContext } from 'react';
import Modal from 'react-modal';
import { usePostRequestSyncPromise } from "../../global/GlobalFetch";
import moment from 'moment';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faDoorOpen,
  faSync,
  faSignInAlt,
  faUsers,
  faArrowCircleLeft,
  faVideo,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faCheckCircle,
  faTimesCircle,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import './Together.css';
import * as signalR from '@microsoft/signalr';
import { ToastContainer, toast } from 'react-toastify';
import Draggable from 'react-draggable';
import YouTube from 'react-youtube';
import useIsMobile from '../../components/mobile/IsMobile';
import { UserContext } from '../../App';

// Modal ayarı
Modal.setAppElement('#root');

// Toast Helper
const showSuccessToast = (message) => {
  toast.dark(<div><FontAwesomeIcon icon={faCheckCircle} /> {message}</div>);
};
const showErrorToast = (message) => {
  toast.dark(<div><FontAwesomeIcon icon={faTimesCircle} /> {message}</div>);
};
const showWarningToast = (message) => {
  toast.dark(<div><FontAwesomeIcon icon={faExclamationTriangle} /> {message}</div>);
};
const showInfoToast = (message) => {
  toast.dark(<div><FontAwesomeIcon icon={faInfoCircle} /> {message}</div>);
};

// Sub-component: Oda içindeki kullanıcıları gösteren basit component
function RoomUsers({ currentRoom, userImages }) {
  if (!currentRoom) return null;
  const { activeUsers } = currentRoom;
  if (!activeUsers || activeUsers.length === 0) return null;

  // distinct user & userImage
  const distinctUsers = [];
  activeUsers.forEach((userName) => {
    if (!distinctUsers.some((u) => u.userName === userName)) {
      distinctUsers.push({
        userName,
        userImage: userImages.find((u) => u.userName === userName)?.base64ImageString,
      });
    }
  });

  return (
    <div className="room-users-panel">
      <h4 className="room-users-title">Room Users</h4>
      <div className="active-users-panel">
        {distinctUsers.map((user) => (
          <div key={user.userName} className="user-circle">
            {user.userImage ? (
              <img
                src={`data:image/jpeg;base64,${user.userImage}`}
                alt={user.userName}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            ) : (
              user.userName.charAt(0).toUpperCase()
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

// Ana Component
export default function Together() {

  const { allUsers } = useContext(UserContext);

  const postRequestSyncPromise = usePostRequestSyncPromise();
  const isMobile = useIsMobile();

  //! UI States
  const [showIFramePanel, setShowIFramePanel] = useState(false);
  const [isYoutubePopupVisible, setYoutubePopupVisible] = useState(false);

  //! Rooms & Current Room
  const [togetherRooms, setTogetherRooms] = useState([]);
  const [currentRoom, setCurrentRoom] = useState(null);

  //! YouTube Search
  const [searchYoutube, setSearchYoutube] = useState("");
  const [youtubeSearchResults, setYoutubeSearchResults] = useState([]);
  const [nextPageToken, setNextPageToken] = useState("");
  const [prevPageToken, setPrevPageToken] = useState("");
  const [isUsersPanelVisible, setIsUsersPanelVisible] = useState(true);

  //! SignalR
  const signalConnection = useRef(null);

  //! Oda giriş input
  const [userInput, setUserInput] = useState("");
  const [isURLValid, setIsURLValid] = useState(true);

  //! Video State
  const [currentVideoId, setCurrentVideoId] = useState("");
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  // Player'ı useRef ile saklıyoruz
  const playerRef = useRef(null);

  // Senkron takip / Seek management
  const previousTimeRef = useRef(0);
  const isSyncingRef = useRef(false);

  // Debounce timer (seek için)
  const seekDebounceTimerRef = useRef(null);

  // Sunucudan gelen en son “receiveVideoSeek” timestamp’i
  const lastReceivedSeekTimestampRef = useRef(0);

  // Sunucudan gelen en son “receiveVideoPlayPause” timestamp’i
  const lastReceivedPlayPauseTimestampRef = useRef(0);

  //! User Images
  const [userImages, setUserImages] = useState([]);

  // ============== INIT ================
  useEffect(() => {
    async function init() {
      console.log("[UI] Initializing...");
      await initializeSignalR();
      await GetAllRooms();
    }
    init();
  }, []);

  useEffect(() => {
    setUserImages(
      allUsers.map(user => ({
        base64ImageString: user.imageUrl,
        userName: user.userName
      }))
    );
  }, [allUsers]);

  // ========== SIGNALR INIT ============
  const initializeSignalR = async () => {
    console.log("[UI] Starting SignalR connection...");
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.kaminarivi.com.tr/togetherhub") // Backend URL
      .withAutomaticReconnect([1000, 2000, 3000, 5000, 10000])
      .build();

    // Start connection
    async function start(connection) {
      try {
        await connection.start();
        console.log("[UI] SignalR Connected: ", connection.connectionId);
        localStorage.setItem("connectionId", connection.connectionId);
      } catch (error) {
        console.error("[UI] SignalR start error:", error);
        setTimeout(() => start(connection), 5000);
      }
    }
    await start(newConnection);

    signalConnection.current = newConnection;

    // onreconnected
    newConnection.onreconnected(async () => {
      console.log("[UI] Connection Reestablished -> connectionId:", newConnection.connectionId);
      showSuccessToast("Connection Reestablished.");

      // Tekrar EnterRoom yapmak önemli
      const userName = localStorage.getItem("userCode") || "Anon";
      const roomString = localStorage.getItem("roomConnectionString");
      if (roomString) {
        console.log("[UI] onreconnected -> re-entering room:", roomString, "with user:", userName);
        try {
          await newConnection.invoke("EnterRoom", userName, roomString);
          console.log("[UI] Re-EnterRoom success");
        } catch (error) {
          console.error("[UI] Re-EnterRoom error:", error);
        }
      }
    });

    // onclose
    newConnection.onclose(async (error) => {
      if (error) {
        console.error("[UI] SignalR connection closed with error: ", error);
      } else {
        console.log("[UI] SignalR connection closed (no error).");
      }
    });

    // popError event
    newConnection.on("popError", (error) => {
      console.log("[UI] popError event => ", error);
      showErrorToast(error);
    });

    // userEnteredTheRoom
    newConnection.on("userEnteredTheRoom", (object) => {
      console.log("[UI] userEnteredTheRoom event => ", object);
      setTogetherRooms((prevRooms) => {
        const updatedRooms = prevRooms.map((room) => {
          if (room.roomConnectionString === object.roomConnectionString) {
            const activeUsers = room.activeUsers || [];
            const updatedActiveUsers = [...new Set([...activeUsers, object.userName])];
            return { ...room, activeUsers: updatedActiveUsers };
          }
          return room;
        });
        return updatedRooms;
      });

      // currentRoom aynı oda ise güncelle
      setCurrentRoom((prevRoom) => {
        if (prevRoom && prevRoom.roomConnectionString === object.roomConnectionString) {
          const updatedActiveUsers = [...(prevRoom.activeUsers || []), object.userName];
          return { ...prevRoom, activeUsers: [...new Set(updatedActiveUsers)] };
        }
        return prevRoom;
      });
    });

    // userLeftTheRoom
    newConnection.on("userLeftTheRoom", (object) => {
      console.log("[UI] userLeftTheRoom event => ", object);
      showInfoToast(`${object.userName} left the room.`);

      setTogetherRooms((prevRooms) => {
        const updatedRooms = prevRooms.map((room) => {
          if (room.roomConnectionString === object.roomConnectionString) {
            const activeUsers = room.activeUsers || [];
            const filteredUsers = activeUsers.filter((u) => u !== object.userName);
            return { ...room, activeUsers: filteredUsers };
          }
          return room;
        });
        return updatedRooms;
      });

      setCurrentRoom((prevRoom) => {
        if (prevRoom && prevRoom.roomConnectionString === object.roomConnectionString) {
          const filteredUsers = prevRoom.activeUsers?.filter((u) => u !== object.userName);
          return { ...prevRoom, activeUsers: filteredUsers };
        }
        return prevRoom;
      });
    });

    // receiveChangeVideo
    newConnection.on("receiveChangeVideo", (data) => {
      console.log("[UI] receiveChangeVideo event => ", data);
      setCurrentVideoId(data.videoId);
      // 0'dan başlat
      if (playerRef.current) {
        isSyncingRef.current = true;
        playerRef.current.seekTo(0, true);
        playerRef.current.playVideo();
        setTimeout(() => {
          isSyncingRef.current = false;
        }, 2000);
      }
    });

    // receiveVideoPlayPause
    newConnection.on("receiveVideoPlayPause", (data) => {
      console.log("[UI] receiveVideoPlayPause event => ", data);

      // Timestamp kontrolü
      if (data.timestamp <= lastReceivedPlayPauseTimestampRef.current) {
        console.warn("[UI] receiveVideoPlayPause: eski paket => yok sayılıyor. incoming:", data.timestamp, "last:", lastReceivedPlayPauseTimestampRef.current);
        return;
      }
      lastReceivedPlayPauseTimestampRef.current = data.timestamp;

      setIsVideoPlaying(data.isPlaying);

      if (playerRef.current) {
        isSyncingRef.current = true;
        // Önce doğru saniyeye git
        playerRef.current.seekTo(data.time, true);

        // Ardından play/pause durumunu ayarla
        if (data.isPlaying) {
          playerRef.current.playVideo();
        } else {
          playerRef.current.pauseVideo();
        }

        setTimeout(() => {
          isSyncingRef.current = false;
        }, 2000);
      }
    });

    // receiveVideoSeek
    newConnection.on("receiveVideoSeek", (data) => {
      console.log("[UI] receiveVideoSeek event => ", data);

      // Timestamp kontrolü
      const incomingTimestamp = data.timestamp || 0;
      if (incomingTimestamp <= lastReceivedSeekTimestampRef.current) {
        // Eski paket => yoksay
        console.warn("[UI] receiveVideoSeek: eski paket, yok sayılıyor. incoming:", incomingTimestamp, "last:", lastReceivedSeekTimestampRef.current);
        return;
      }
      // Yeni paket => timestamp güncelle
      lastReceivedSeekTimestampRef.current = incomingTimestamp;

      if (playerRef.current) {
        isSyncingRef.current = true;
        console.log("[UI] (receiveVideoSeek) -> seeking to:", data.time);
        playerRef.current.seekTo(data.time, true);
        setTimeout(() => {
          isSyncingRef.current = false;
        }, 2000);
      }
    });

    // >>> SNAPHOT EVENT (3-5 saniyede bir sunucu otomatik atar) <<<
    newConnection.on("receiveRoomSnapshot", (snapshot) => {
      console.log("[UI] receiveRoomSnapshot => ", snapshot);

      // Örnek: snapshot = { videoId, isPlaying, currentPosition }
      if (!playerRef.current) return;

      // 1) VideoId farkı varsa, güncelle
      if (snapshot.videoId && snapshot.videoId !== currentVideoId) {
        console.log("[UI] Snapshot -> videoId farkı var, güncelle");
        setCurrentVideoId(snapshot.videoId);
      }

      // 2) Local time vs snapshot time
      const localTime = playerRef.current.getCurrentTime();
      const diff = Math.abs(localTime - snapshot.currentPosition);

      // 2-3sn üzeri fark varsa resync edelim
      if (diff > 2) {
        console.log("[UI] Snapshot -> time farkı büyük, resync =>", snapshot.currentPosition);
        isSyncingRef.current = true;
        playerRef.current.seekTo(snapshot.currentPosition, true);
        setTimeout(() => {
          isSyncingRef.current = false;
        }, 2000);
      }

      // 3) Oynatma durumu eşleşiyor mu?
      if (snapshot.isPlaying && !isVideoPlaying) {
        console.log("[UI] Snapshot -> server is playing, local is paused => play");
        isSyncingRef.current = true;
        playerRef.current.playVideo();
        setIsVideoPlaying(true);
        setTimeout(() => {
          isSyncingRef.current = false;
        }, 2000);
      }
      else if (!snapshot.isPlaying && isVideoPlaying) {
        console.log("[UI] Snapshot -> server is paused, local is playing => pause");
        isSyncingRef.current = true;
        playerRef.current.pauseVideo();
        setIsVideoPlaying(false);
        setTimeout(() => {
          isSyncingRef.current = false;
        }, 2000);
      }
    });
  };

  // ============ GET ALL ROOMS ============
  const GetAllRooms = async () => {
    console.log("[UI] GetAllRooms invoked");
    try {
      const data = await postRequestSyncPromise("Together/GetAllRooms");
      setTogetherRooms(data.data);
      console.log("[UI] Rooms fetched => ", data.data);
    } catch (error) {
      console.error("[UI] GetAllRooms error:", error);
    }
  };

  // =========== CREATE ROOM ===========
  function CreateRoom() {
    console.log("[UI] CreateRoom button clicked");
    const createRoomRequest = {
      share: "https://www.youtube.com/watch?v=f_WuRfuMXQw",
      bg_color: "#363640",
      bg_opacity: "50",
    };

    const baseRequest = {
      RequestId: "unique_request_id",
      Sender: "client_create_room_request",
      Data: [createRoomRequest],
    };

    postRequestSyncPromise("Together/CreateRoom", baseRequest)
      .then(() => {
        console.log("[UI] CreateRoom success => fetching rooms");
        GetAllRooms();
      })
      .catch((error) => {
        console.error("[UI] CreateRoom error:", error);
      });
  }

  // ============ ENTER ROOM ============
  function enterRoom(roomConnectionString) {
    console.log("[UI] enterRoom =>", roomConnectionString);
    localStorage.setItem("roomConnectionString", roomConnectionString);
    const userCode = localStorage.getItem("userCode") || "Anon";

    signalConnection.current
      .invoke("EnterRoom", userCode, roomConnectionString)
      .then(() => {
        console.log("[UI] EnterRoom invoked successfully -> user:", userCode, "room:", roomConnectionString);
      })
      .catch((err) => console.error("[UI] EnterRoom error:", err));

    const selectedRoom = togetherRooms.find(
      (r) => r.roomConnectionString === roomConnectionString
    );
    setCurrentRoom(selectedRoom || null);
    setShowIFramePanel(true);
  }

  // ============ LEAVE ROOM ============
  function closeIFramePanel() {
    console.log("[UI] closeIFramePanel called");
    const roomConnectionString = localStorage.getItem("roomConnectionString");
    const userCode = localStorage.getItem("userCode") || "Anon";

    signalConnection.current
      .invoke("LeaveRoom", userCode, roomConnectionString)
      .then(() => {
        console.log("[UI] LeaveRoom invoked => user:", userCode, "room:", roomConnectionString);
      })
      .catch((err) => console.error("[UI] LeaveRoom error:", err));

    setShowIFramePanel(false);
    setCurrentRoom(null);
  }

  // ============ SEARCH YOUTUBE ============
  function SearchYoutube() {
    console.log("[UI] SearchYoutube => query:", searchYoutube);
    const searchYoutubeRequest = {
      type: "video",
      videoEmbeddable: true,
      pageToken: "",
      q: searchYoutube,
    };

    postRequestSyncPromise("Together/YTSearchVideo", searchYoutubeRequest)
      .then((data) => {
        if (data.type === 0) {
          setYoutubeSearchResults(data.data[0].items);
          setNextPageToken(data.data[0].nextPageInformation);
          setPrevPageToken(data.data[0].prevPageToken);
          console.log("[UI] SearchYoutube results => ", data.data[0].items);
        }
      })
      .catch((error) => {
        console.error("[UI] SearchYoutube error:", error);
      });
  }

  // ============ PLAYER EVENTS ============
  const onPlayerReady = (event) => {
    console.log("[UI] onPlayerReady -> player is ready");
    playerRef.current = event.target;
    previousTimeRef.current = 0;
  };

  const onPlayerStateChange = (event) => {
    console.log("[UI] onPlayerStateChange => state:", event.data);
  };

  // Play event
  const onPlayerPlay = (event) => {
    console.log("[UI] onPlayerPlay => video is playing");
    setIsVideoPlaying(true);

    // Eğer bu local event ise (senkron event değil)
    if (!isSyncingRef.current) {
      const localTime = playerRef.current.getCurrentTime();
      const nowTimestamp = Date.now(); // milisaniye cinsinden
      const requestTimestamp = Math.floor(nowTimestamp / 1000); // veya direkt nowTimestamp

      signalConnection.current
        .invoke("VideoPlayPause", {
          RoomConnectionString: localStorage.getItem("roomConnectionString"),
          IsPlaying: true,
          Time: localTime,
          Timestamp: requestTimestamp
        })
        .then(() => console.log("[UI] VideoPlayPause -> isPlaying: true invoked, time:", localTime))
        .catch((err) => console.error("[UI] VideoPlayPause error:", err));
    }
  };

  // Pause event
  const onPlayerPause = (event) => {
    console.log("[UI] onPlayerPause => video is paused");
    setIsVideoPlaying(false);

    if (!isSyncingRef.current) {
      const localTime = playerRef.current.getCurrentTime();
      const nowTimestamp = Date.now();
      const requestTimestamp = Math.floor(nowTimestamp / 1000);

      signalConnection.current
        .invoke("VideoPlayPause", {
          RoomConnectionString: localStorage.getItem("roomConnectionString"),
          IsPlaying: false,
          Time: localTime,
          Timestamp: requestTimestamp
        })
        .then(() => console.log("[UI] VideoPlayPause -> isPlaying: false invoked, time:", localTime))
        .catch((err) => console.error("[UI] VideoPlayPause error:", err));
    }
  };

  const onPlayerEnd = (event) => {
    console.log("[UI] onPlayerEnd => video ended");
    // Örneğin video bitince "isPlaying=false" göndermek isterseniz yapabilirsiniz.
  };

  const onPlayerError = (event) => {
    console.error("[UI] onPlayerError => error code:", event.data);
    showErrorToast(`YouTube player error code: ${event.data}`);
  };

  // ============ CHANGE VIDEO ============
  const handleChangeVideo = (videoId) => {
    console.log("[UI] handleChangeVideo =>", videoId);
    setCurrentVideoId(videoId);

    signalConnection.current
      .invoke("ChangeVideo", {
        RoomConnectionString: localStorage.getItem("roomConnectionString"),
        VideoId: videoId,
      })
      .then(() => console.log("[UI] ChangeVideo invoked => videoId:", videoId))
      .catch((err) => console.error("[UI] ChangeVideo error:", err));
  };

  // ============ INPUT HANDLER ============
  function handleUserInputChange(e) {
    setUserInput(e.target.value);
  }

  // ============ SEEK TESPİT (Debounce’lu) ============
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!playerRef.current) return;

      const localTime = playerRef.current.getCurrentTime();
      const diff = Math.abs(localTime - previousTimeRef.current);

      // Kullanıcı 2sn ve üzeri bir farkla ileri/geri sardırdıysa => Seek
      if (!isSyncingRef.current && diff > 2) {
        console.log("[UI] local user seeking => newTime:", localTime);
        previousTimeRef.current = localTime;

        // Debounce mekanizması: önce varsa eski timer'ı iptal et
        if (seekDebounceTimerRef.current) {
          clearTimeout(seekDebounceTimerRef.current);
        }

        // 300ms sonra hâlâ yeni bir seek event'i yoksa server’a gönder
        seekDebounceTimerRef.current = setTimeout(() => {
          const nowTimestamp = Date.now(); // milisaniye cinsinden
          const requestTimestamp = Math.floor(nowTimestamp / 1000);

          signalConnection.current
            .invoke("VideoSeek", {
              RoomConnectionString: localStorage.getItem("roomConnectionString"),
              Time: localTime,
              Timestamp: requestTimestamp
            })
            .then(() => {
              console.log("[UI] VideoSeek invoked => time:", localTime, "timestamp:", requestTimestamp);
            })
            .catch((err) => console.error("[UI] VideoSeek error:", err));
        }, 300);
      } else {
        previousTimeRef.current = localTime;
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // ============ RENDER ============
  return (
    <div className="watch-together-container">
      <ToastContainer position="bottom-center" />

      {showIFramePanel ? (
        <div className="fullscreen-video">
          <YouTube
            videoId={currentVideoId}
            ref={playerRef}
            opts={{
              width: "100%",
              height: "100%",
              playerVars: {
                controls: 1,
                rel: 0,
                autoplay: 1,
              },
            }}
            style={{ width: "100%", height: "100%" }}
            onReady={onPlayerReady}
            onPlay={onPlayerPlay}
            onPause={onPlayerPause}
            onEnd={onPlayerEnd}
            onError={onPlayerError}
            onStateChange={onPlayerStateChange}
          />

          {/* --- DRAGGABLE BUTTONS (Sol Üst köşede vs) --- */}
          <Draggable>
            <div className="draggable-bar">
              <button className="kaminari-button" onClick={closeIFramePanel}>
                <FontAwesomeIcon icon={faArrowCircleLeft} />
              </button>
              <button className="kaminari-button" onClick={() => setYoutubePopupVisible(true)}>
                <FontAwesomeIcon icon={["fab", "youtube"]} />
              </button>
              <button
                className="kaminari-button"
                onClick={() => setIsUsersPanelVisible((prev) => !prev)}
              >
                <FontAwesomeIcon icon={faUsers} />
              </button>
            </div>
          </Draggable>

          {/* RoomUsers Draggable Panel */}
          {isUsersPanelVisible && (
            <Draggable>
              <div className="room-users-draggable">
                <RoomUsers currentRoom={currentRoom} userImages={userImages} />
              </div>
            </Draggable>
          )}

          {/* --- YOUTUBE SEARCH POPUP --- */}
          {isYoutubePopupVisible && (
            <div className="modal-overlay">
              <div className="youtube-popup">
                <button
                  className="close-button"
                  onClick={() => setYoutubePopupVisible(false)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
                <h2 className="note-title">YouTube'da Video Ara</h2>
                <div className="control-row">
                  <input
                    type="text"
                    value={searchYoutube}
                    onChange={(e) => setSearchYoutube(e.target.value)}
                    placeholder="Search for a video on YouTube"
                    className="kaminari-input"
                  />
                  <button className="kaminari-button" onClick={SearchYoutube}>
                    <FontAwesomeIcon icon={"fa-brands fa-youtube"} />
                  </button>
                </div>

                <div className="video-items-container">
                  {youtubeSearchResults.map((video) => {
                    const viewCount = new Intl.NumberFormat().format(
                      video.statistics.viewCount
                    );
                    const likeCount = new Intl.NumberFormat().format(
                      video.statistics.likeCount
                    );

                    return (
                      <div key={video.id} className="video-item">
                        <div className="video-thumbnail-container">
                          <img
                            src={video.snippet.thumbnails.high.url}
                            alt={video.snippet.title}
                            className="video-thumbnail"
                          />
                        </div>
                        <div className="video-info">
                          <h6 className="video-title">{video.snippet.title}</h6>
                          <p className="video-channel">{video.snippet.channelTitle}</p>
                          <div className="video-stats">
                            <span>{viewCount} views</span>
                            <span>{likeCount} likes</span>
                          </div>
                        </div>
                        <div className="video-actions">
                          <button
                            className="kaminari-button"
                            onClick={() => {
                              const textToCopy = `https://www.youtube.com/watch?v=${video.id}`;
                              const copyTextToClipboard = async (text) => {
                                if (navigator.clipboard && navigator.clipboard.writeText) {
                                  try {
                                    await navigator.clipboard.writeText(text);
                                    console.log("[UI] Copied text to clipboard:", text);
                                  } catch (err) {
                                    console.error("[UI] Could not copy text:", err);
                                  }
                                } else {
                                  console.warn("[UI] Clipboard API not available");
                                  const confirmed = window.confirm(
                                    "This browser does not support automatic clipboard copying. Click OK to copy manually."
                                  );
                                  if (confirmed) {
                                    const textArea = document.createElement("textarea");
                                    textArea.value = text;
                                    document.body.appendChild(textArea);
                                    textArea.select();
                                    try {
                                      document.execCommand("copy");
                                      console.log("[UI] Copied text via execCommand:", text);
                                    } catch (execErr) {
                                      console.error("[UI] Fallback: Unable to copy:", execErr);
                                    }
                                    document.body.removeChild(textArea);
                                  }
                                }
                              };
                              copyTextToClipboard(textToCopy);

                              // Video değiştir
                              handleChangeVideo(video.id);
                            }}
                          >
                            <FontAwesomeIcon icon={faVideo} />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="note-actions">
                  <button
                    className="kaminari-button"
                    onClick={() => {
                      const searchYoutubeRequest = {
                        type: "video",
                        videoEmbeddable: true,
                        pageToken: prevPageToken,
                        q: searchYoutube,
                      };
                      postRequestSyncPromise("Together/YTSearchVideo", searchYoutubeRequest)
                        .then((data) => {
                          if (data.type === 0) {
                            setYoutubeSearchResults(data.data[0].items);
                            setNextPageToken(data.data[0].nextPageInformation);
                            setPrevPageToken(data.data[0].prevPageToken);
                            console.log("[UI] SearchYoutube (prevPage) =>", data.data[0].items);
                          }
                        })
                        .catch((err) => console.error("[UI] SearchYoutube prev error:", err));
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <button
                    className="kaminari-button"
                    onClick={() => {
                      const searchYoutubeRequest = {
                        type: "video",
                        videoEmbeddable: true,
                        pageToken: nextPageToken,
                        q: searchYoutube,
                      };
                      postRequestSyncPromise("Together/YTSearchVideo", searchYoutubeRequest)
                        .then((data) => {
                          if (data.type === 0) {
                            setYoutubeSearchResults(data.data[0].items);
                            setNextPageToken(data.data[0].nextPageInformation);
                            setPrevPageToken(data.data[0].prevPageToken);
                            console.log("[UI] SearchYoutube (nextPage) =>", data.data[0].items);
                          }
                        })
                        .catch((err) => console.error("[UI] SearchYoutube next error:", err));
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        // Odaya girilmediyse
        <div className="notes-container-together">
          <div className="notes-header-together">
            <h2 className="neon-text">NOW +</h2>
            <button
              className="kaminari-button"
              onClick={CreateRoom}
              disabled={togetherRooms.length >= 3}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>

          <div className="notes-input">
            <input
              type="text"
              placeholder="Already Have Another Room"
              value={userInput}
              onChange={handleUserInputChange}
              className="kaminari-input"
            />
            <button
              className="kaminari-button"
              onClick={() => {
                if (isURLValid) {
                  enterRoom(userInput);
                }
              }}
            >
              <FontAwesomeIcon icon={faSignInAlt} />
            </button>
          </div>

          <div className="notes-list">
            {togetherRooms.map((room) => {
              const createDate = moment(room.createDate).format("YYYY-MM-DD HH:mm:ss");
              const expirationDate = moment(room.createDate).add(24, "hours");
              const duration = moment.duration(expirationDate.diff(moment()));
              const remainingTime = `${duration.hours()}h : ${duration.minutes()}m`;

              const hasActiveUsers = room.activeUsers && room.activeUsers.length > 0;

              return (
                <div key={room._id} className="note-item">
                  <div className="note-icon-container">
                    <FontAwesomeIcon
                      icon={faUsers}
                      className={`note-icon ${hasActiveUsers ? "note-icon-active" : ""}`}
                    />
                  </div>
                  <div className="note-preview">
                    <div className="note-info">
                      <span className="note-label">Created:</span>
                      <span className="note-value">{createDate}</span>
                    </div>
                    <div className="note-info">
                      <span className="note-label">Remaining Time:</span>
                      <span className="note-value">{remainingTime}</span>
                    </div>
                    <div className="note-info">
                      <span className="note-label">Active Users:</span>
                      <div className="active-users-panel">
                        {room.activeUsers?.map((userName) => {
                          const foundImage = userImages.find((u) => u.userName === userName)
                            ?.base64ImageString;

                          return (
                            <div key={userName} className="user-circle">
                              {foundImage ? (
                                <img
                                  src={`data:image/jpeg;base64,${foundImage}`}
                                  alt={userName}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                />
                              ) : (
                                userName.charAt(0).toUpperCase()
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="note-actions">
                    <button className="kaminari-button" onClick={GetAllRooms}>
                      <FontAwesomeIcon icon={faSync} />
                    </button>
                    <button
                      className="kaminari-button"
                      onClick={() => enterRoom(room.roomConnectionString)}
                    >
                      <FontAwesomeIcon icon={faDoorOpen} />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}