import React, { useContext, useEffect, useRef, useState, createContext } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import CustomNavbar from './components/CustomNavbar/CustomNavbar';
import Home from './pages/home/home';
import KaminariCord from './pages/kaminaricord/KaminariCord';
import Movie from './pages/movie/movie';
import TvShow from './pages/tvShow/TvShow';
import Notes from './pages/notes/notes';
import Profile from './pages/profile/profile';
import Together from './pages/together/together';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Anime from './pages/anime/anime';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthContext } from './context/AuthContext';
import { LoadingProvider, LoadingContext } from './context/LoadingContext';
import LoadingIndicator from './components/Indicators/LoadingIndicator';
import * as signalR from '@microsoft/signalr';
import { usePostRequestSyncPromise } from "./global/GlobalFetch";
// -----------------------------------------------------------------------------
// 1) UserContext: Tüm kullanıcılar (allUsers) ve (opsiyonel) aktif kullanıcılar
// -----------------------------------------------------------------------------
export const UserContext = createContext({
  allUsers: [],
  setAllUsers: () => { },
  activeUsers: [],
  setActiveUsers: () => { },
  friendShipRequests: [],
  setFriendShipRequests: () => { }
});

function generateSessionId() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function getCurrentLocation() {
  return window.location.pathname || '/';
}

// -----------------------------------------------------------------------------
// 2) UserProvider
//    - Uygulama ilk açıldığında tüm kullanıcıları backend'den çeker.
//    - isAuthenticated true ise SignalR bağlantısı kurar; canlı event'lerle
//      kullanıcı listesi güncellenir.
// -----------------------------------------------------------------------------
function UserProvider({ children }) {
  const { isAuthenticated } = useContext(AuthContext);

  const [allUsers, setAllUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [friendShipRequests, setFriendShipRequests] = useState([]);
  const activeUsersRef = useRef(activeUsers);
  useEffect(() => {
    activeUsersRef.current = activeUsers;
  }, [activeUsers]);


  const connectionRef = useRef(null);
  const postRequestSyncPromise = usePostRequestSyncPromise();

  // Uygulama yüklendiğinde bir kez çalışarak tüm kullanıcıları çekiyoruz.
  useEffect(() => {
    const userName = localStorage.getItem("userCode")

    let baseReq = {
      data: [userName]
    }

    let baseReq2 = {
      data: [{ userName: userName }]
    }

    postRequestSyncPromise('Auth/GetAllFriends', baseReq)
      .then(response => {
        console.log("All users fetched:", response?.data);
        if (response?.data) {
          setAllUsers(response.data);
        }
      })
      .catch(error => {
        console.error("Error fetching all users:", error);
      });

    postRequestSyncPromise('Friendship/GetFriendshipRequests', baseReq2)
      .then(response => {
        if (response?.data) {
          setFriendShipRequests(response.data);
        }
      })
      .catch(error => {
        console.error("Error fetching all users:", error);
      });
  }, [postRequestSyncPromise]);

  // Kullanıcı Auth olduktan sonra SignalR bağlantısını kurup, event'leri dinliyoruz.
  useEffect(() => {
    if (isAuthenticated) {
      const userName = localStorage.getItem("userCode") || "";
      const sessionId = generateSessionId();
      const location = getCurrentLocation();

      const connection = new signalR.HubConnectionBuilder()
        .withUrl(
          `https://api.kaminarivi.com.tr/useractivityhub?userName=${userName}&sessionId=${sessionId}&location=${encodeURIComponent(location)}`
        )
        .withAutomaticReconnect()
        .build();

      // 1) Yeni user kayıt (RegisterUser) sonrası
      connection.on("newUserArrived", (userHubResponse) => {
        console.log("New user arrived:", userHubResponse);
        setAllUsers(prevUsers => {
          const exists = prevUsers.some(u => u.UserName === userHubResponse.userName);
          if (!exists) {
            return [
              ...prevUsers,
              {
                userName: userHubResponse.userName,
                imageUrl: userHubResponse.imageUrl || "",
                mailAddress: userHubResponse.mailAddress || ""
              }
            ];
          }
          return prevUsers;
        });
      });

      // 2) Kullanıcı adını değiştirdiğinde (ChangeUserName)
      connection.on("userNameChanged", (userHubResponse) => {
        console.log("Username changed:", userHubResponse);
        setAllUsers(prevUsers =>
          prevUsers.map(u => {
            if (u.UserName === userHubResponse.oldUserName) {
              return {
                ...u,
                UserName: userHubResponse.userName
              };
            }
            return u;
          })
        );
      });

      // 3) Kullanıcı e-posta değiştirdiğinde (ChangeUserMailAsync)
      connection.on("userMailChanged", (userHubResponse) => {
        setAllUsers(prevUsers =>
          prevUsers.map(u => {
            if (u.UserName === userHubResponse.userName) {
              return {
                ...u,
                MailAddress: userHubResponse.mailAddress
              };
            }
            return u;
          })
        );
      });

      // 4) Kullanıcı profil resmini değiştirdiğinde (ChangeUserImageAsync)
      connection.on("userImageChanged", (userHubResponse) => {
        console.log("User image changed:", userHubResponse);
        setAllUsers(prevUsers =>
          prevUsers.map(u => {
            if (u.UserName === userHubResponse.userName) {
              return {
                ...u,
                ImageUrl: userHubResponse.imageUrl
              };
            }
            return u;
          })
        );
      });

      // (Opsiyonel) Aktif kullanıcı listesi
      connection.on("ActiveUsersUpdated", (updatedUsers) => {
        setActiveUsers(updatedUsers);
      });

      connection.on("FriendRequestInserted", (senderUserName, receiverUserName) => {
        // Kullanıcı receiver mı yoksa sender mı tespit et
        const isReceiver = receiverUserName === userName;
        const isSender = senderUserName === userName;

        if (isReceiver || isSender) {
          setFriendShipRequests(prevRequests => {
            const exists = prevRequests.some(r => r.senderUserName === senderUserName && r.receiverUserName === receiverUserName);
            if (!exists) {
              // Eğer kullanıcı receiver ise, "Size şu kullanıcıdan arkadaşlık isteği geldi" bildirimi göster
              if (isReceiver) {
                if (Notification.permission === "granted") {
                  new Notification("New Friend Request", {
                    body: `You received a friend request from ${senderUserName}.`,
                  });
                } else if (Notification.permission !== "denied") {
                  Notification.requestPermission().then(permission => {
                    if (permission === "granted") {
                      new Notification("New Friend Request", {
                        body: `You received a friend request from ${senderUserName}.`,
                      });
                    }
                  });
                }
              }

              // Arkadaşlık isteğini listeye ekle
              return [
                ...prevRequests,
                {
                  senderUserName: senderUserName,
                  receiverUserName: receiverUserName,
                  amISender: isSender
                }
              ];
            }
            return prevRequests;
          });
        }
      });

      connection.on("FriendRequestDeleted", (senderUserName, receiverUserName, isSuccess) => {
        console.log("FriendRequestDeleted event triggered", senderUserName, receiverUserName, isSuccess);

        setFriendShipRequests((prevRequests) => {
          console.log("Requests before filter:", prevRequests);
          const updated = prevRequests.filter(
            (request) =>
              !(request.senderUserName === senderUserName && request.receiverUserName === receiverUserName)
          );
          console.log("Requests after filter:", updated);
          return updated;
        });

        if (senderUserName === userName) {
          // Current user is the sender
          if (isSuccess) {
            // Friend request accepted by the receiver
            console.log("Friend request accepted, showing accepted notification.");
            if (Notification.permission === "granted") {
              new Notification("Friend Request Accepted", {
                body: `${receiverUserName} accepted your friend request.`
              });
            } else if (Notification.permission !== "denied") {
              Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                  new Notification("Friend Request Accepted", {
                    body: `${receiverUserName} accepted your friend request.`,
                  });
                }
              });
            }
          } else {
            // Friend request declined by the receiver
            console.log("Friend request declined, showing declined notification.");
            if (Notification.permission === "granted") {
              new Notification("Friend Request Declined", {
                body: `${receiverUserName} declined your friend request.`,
              });
            } else if (Notification.permission !== "denied") {
              Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                  new Notification("Friend Request Declined", {
                    body: `${receiverUserName} declined your friend request.`,
                  });
                }
              });
            }
          }
        } else if (receiverUserName === userName) {
          // Current user is the receiver; no notification needed
          console.log("Current user is the receiver; no notification displayed.");
        } else {
          console.log("User not involved in FriendRequestDeleted event");
        }
      });

      connection.on("FriendInserted", (friendsResponse) => {
        // User role tespiti: loggedInUserName sender mı receiver mı?
        if (friendsResponse.senderUserName === userName) {
          // Eğer sender'sa, receiver'ı allUsers listesine ekle
          setAllUsers(prevUsers => {
            const exists = prevUsers.some(u => u.userName === friendsResponse.receiverUserName);
            if (!exists) {
              return [
                ...prevUsers,
                {
                  userName: friendsResponse.receiverUserName,
                  imageUrl: friendsResponse.receiverImageUrl || "",
                  mailAddress: friendsResponse.receiverMailAddress || ""
                }
              ];
            }
            return prevUsers;
          });
        } else if (friendsResponse.receiverUserName === userName) {
          // Eğer receiver'sa, sender'ı allUsers listesine ekle
          setAllUsers(prevUsers => {
            const exists = prevUsers.some(u => u.userName === friendsResponse.senderUserName);
            if (!exists) {
              return [
                ...prevUsers,
                {
                  userName: friendsResponse.senderUserName,
                  imageUrl: friendsResponse.senderImageUrl || "",
                  mailAddress: friendsResponse.senderMailAddress || ""
                }
              ];
            }
            return prevUsers;
          });
        } else {
          console.log("FriendInserted event not relevant for the current user.");
        }
      });

      connection.on("FriendRemoved", (senderUserName, receiverUserName) => {
        // Kullanıcı sender mı yoksa receiver mı tespit et
        const isReceiver = receiverUserName === userName;
        const isSender = senderUserName === userName;

        if (isReceiver || isSender) {
          // Eğer kullanıcı receiver ise, sender'ı arkadaşlardan kaldır ve bildirim göster
          if (isReceiver) {
            setAllUsers(prevUsers =>
              prevUsers.filter(user => user.userName !== senderUserName)
            );

            // Bildirim göster
            if (Notification.permission === "granted") {
              new Notification("Friend Removed", {
                body: `${senderUserName} removed you from friends list.`,
              });
            } else if (Notification.permission !== "denied") {
              Notification.requestPermission().then(permission => {
                if (permission === "granted") {
                  new Notification("Friend Removed", {
                    body: `${senderUserName}removed you from friends list.`,
                  });
                }
              });
            }
          }

          // Eğer kullanıcı sender ise, receiver'ı arkadaşlardan kaldır
          if (isSender) {
            setAllUsers(prevUsers =>
              prevUsers.filter(user => user.userName !== receiverUserName)
            );
          }
        }
      });

      connection.on("ReceiveKaminariCordMessageNotification", (sendRequest) => {
        if (!sendRequest || !userName) return;
      
        // Eğer biz gönderici isek bildirimi yoksay.
        if (sendRequest.senderUserName === userName) return;
      
        // Biz alıcı listesinde miyiz kontrol et.
        if (sendRequest.receiverUserNames?.includes(userName)) {
          // Kullanıcı aktif mi?
          const isActive = activeUsersRef.current.some(user => user.userName === userName);
          if (isActive) {
            const notificationBody = `${sendRequest.channel} - ${sendRequest.senderUserName}`;

            if (Notification.permission === "granted") {
              new Notification("KaminariCord Mesaj", {
                body: notificationBody,
              });
            } else if (Notification.permission !== "denied") {
              Notification.requestPermission().then(permission => {
                if (permission === "granted") {
                  new Notification("KaminariCord Mesaj", {
                    body: notificationBody,
                  });
                }
              });
            }
          }
        }
      });
      
      connection.start()
        .then(() => {
          console.log("SignalR bağlantısı kuruldu (UserProvider).");
        })
        .catch(err => console.error("SignalR bağlantı hatası (UserProvider):", err));

      connectionRef.current = connection;

      // Cleanup: kullanıcı auth'dan çıkarsa, bağlantıyı kes.
      return () => {
        connection.stop()
          .then(() => console.log("SignalR bağlantısı kapatıldı (UserProvider)."));
      };
    } else {
      // Oturum kapandığında listeyi temizleyebilir veya eski halde bırakabilirsin:
      setAllUsers([]);
      setActiveUsers([]);
      setFriendShipRequests([]);

      if (connectionRef.current) {
        connectionRef.current.stop();
        connectionRef.current = null;
      }
    }
  }, [isAuthenticated]);

  // UserContext.Provider ile allUsers, activeUsers ve set'lerini paylaşıyoruz.
  return (
    <UserContext.Provider value={{ allUsers, setAllUsers, activeUsers, setActiveUsers, friendShipRequests, setFriendShipRequests }}>
      {children}
    </UserContext.Provider>
  );
}

// -----------------------------------------------------------------------------
// 3) PrivateRoute - Giriş yapılmadıysa /login'e yönlendiren yüksek seviyeli bileşen
// -----------------------------------------------------------------------------
function PrivateRoute({ children }) {
  const { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated ? children : <Navigate to="/login" />;
}

// -----------------------------------------------------------------------------
// 4) AppContent: Navbar + Route yapılandırması (değişiklik yok)
// -----------------------------------------------------------------------------
function AppContent() {
  const { isAuthenticated } = useContext(AuthContext);
  const { isLoading } = useContext(LoadingContext);

  return (
    <Router>
      <div className="app-container">
        {isLoading && <LoadingIndicator />}
        <header className="app-header">
          {isAuthenticated && <CustomNavbar />}
        </header>
        <main className="app-body">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/" element={<Home />} />

            <Route path="/anime" element={<PrivateRoute><Anime /></PrivateRoute>} />
            <Route path="/kaminaricord" element={<PrivateRoute><KaminariCord /></PrivateRoute>} />
            <Route path="/movie" element={<PrivateRoute><Movie /></PrivateRoute>} />
            <Route path="/tvshow" element={<PrivateRoute><TvShow /></PrivateRoute>} />
            <Route path="/notes" element={<PrivateRoute><Notes /></PrivateRoute>} />
            <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route path="/together" element={<PrivateRoute><Together /></PrivateRoute>} />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

// -----------------------------------------------------------------------------
// 5) App Bileşeni: En dış katmanda LoadingProvider ve UserProvider kullanımı
// -----------------------------------------------------------------------------
function App() {
  return (
    <LoadingProvider>
      <UserProvider>
        <AppContent />
      </UserProvider>
    </LoadingProvider>
  );
}

export default App;