import PropTypes from 'prop-types';
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext
} from 'react';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import { usePostRequestSyncPromise } from "../../global/GlobalFetch";
import ReactPaginate from 'react-paginate';
import Slider from 'react-slick';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Select from 'react-select';
import { FaTimes } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faVideoSlash,
  faSearch,
  faHistory,
  faFilm,
  faCheckCircle,
  faTimesCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
  faArrowLeft,
  faStar,
  faPlay,
  faClosedCaptioning,
  faSpinner,
  faClose
} from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Movie.css';
import * as signalR from '@microsoft/signalr';
import { ToastContainer, toast } from 'react-toastify';
import { CONFIG } from '../../CONFIG';
import useIsMobile from '../../components/mobile/IsMobile';
import { UserContext } from '../../App';

// -- Toast Functions --
const showSuccessToast = (message) => {
  toast.dark(
    <div>
      <FontAwesomeIcon icon={faCheckCircle} />
      {message}
    </div>
  );
};
const showErrorToast = (message) => {
  toast.dark(
    <div>
      <FontAwesomeIcon icon={faTimesCircle} />
      {message}
    </div>
  );
};
const showWarningToast = (message) => {
  toast.dark(
    <div>
      <FontAwesomeIcon icon={faExclamationTriangle} />
      {message}
    </div>
  );
};
const showInfoToast = (message) => {
  toast.dark(
    <div>
      <FontAwesomeIcon icon={faInfoCircle} />
      {message}
    </div>
  );
};

// -----------------------------------------------
// USER HISTORY POPUP
// -----------------------------------------------
const UserHistoryPopup = ({
  isOpen,
  toggle,
  allUserNames,
  allUsersHistory,
  setPopupVisible,
  setSelectedMovie,
  postRequestSyncPromise,
  setAllUsersHistory,
  isMobile
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userPages, setUserPages] = useState({});
  const [localHistories, setLocalHistories] = useState([]);
  const HISTORIES_PER_PAGE = 8;

  useEffect(() => {
    setLocalHistories(allUsersHistory);
  }, [allUsersHistory]);

  const handleUserChange = (selectedOptions) => {
    setSelectedUsers(selectedOptions ? selectedOptions.map((option) => option.value) : []);
    setUserPages({});
  };

  const handlePageChange = (user, newPage) => {
    setUserPages((prevPages) => ({
      ...prevPages,
      [user]: newPage,
    }));
  };

  const handleCardClick = (movie) => {
    setSelectedMovie(movie);
    setPopupVisible(true);
  };

  const handleDeleteHistory = (movieDetail, user) => {
    const userCode = localStorage.getItem('userCode');
    const baseRequest = {
      data: [{
        userName: userCode,
        id: movieDetail.id,
        historyType: 1
      }]
    };
    postRequestSyncPromise('UserHistory/DeleteUserHistory', baseRequest)
      .then(data => {
        if (data.type === 0) {
          // Silme başarılı ise gerisini SignalR handle edecek
        }
      })
      .catch(error => {
        console.error('Error deleting history:', error);
      });
  };

  // -- Masaüstü versiyonu --
  const renderDesktopVersion = () => {
    // Seçili kullanıcıların history'sini hesapla
    const userHistories = selectedUsers.map((user) => {
      const histories = localHistories
        .filter((history) => history.owner === user && history.historyType === 1)
        .sort((a, b) => {
          const dateA = new Date(a.updatedDate || a.createdDate);
          const dateB = new Date(b.updatedDate || b.createdDate);
          return dateB - dateA;
        });

      const totalPages = Math.ceil(histories.length / HISTORIES_PER_PAGE);
      const currentPage = userPages[user] || 1;
      const currentHistory = histories.slice(
        (currentPage - 1) * HISTORIES_PER_PAGE,
        currentPage * HISTORIES_PER_PAGE
      );

      return {
        user,
        history: currentHistory.map((history) => history.movieDetail),
        totalPages,
        currentPage,
      };
    });

    // -- customStyles (masaüstü + mobil aynı) --
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        backgroundColor: 'transparent',
        borderColor: state.isFocused ? 'var(--glow-color)' : 'var(--border-color)',
        boxShadow: state.isFocused ? `0 0 8px var(--glow-color)` : 'none',
        '&:hover': {
          borderColor: 'var(--glow-color)',
        },
        borderRadius: '8px',
        padding: '5px',
        color: 'var(--text-color)',
      }),
      multiValue: (provided) => ({
        ...provided,
        backgroundColor: 'var(--hover-gradient-2)',
        borderRadius: '4px',
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        color: 'var(--text-color)',
        fontWeight: 'bold',
      }),
      multiValueRemove: (provided) => ({
        ...provided,
        color: 'var(--text-color)',
        '&:hover': {
          backgroundColor: 'var(--hover-gradient-1)',
          color: 'var(--text-color)',
        },
      }),
      placeholder: (provided) => ({
        ...provided,
        color: 'rgba(255, 255, 255, 0.6)',
        fontStyle: 'italic',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'var(--glow-color)' : 'var(--text-color)',
        '&:hover': {
          color: 'var(--glow-color)',
        },
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: 'rgba(32, 43, 96, 0.9)',
        border: '1px solid var(--border-color)',
        boxShadow: `0 8px 16px var(--glow-color)`,
        borderRadius: '8px',
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected
          ? 'var(--hover-gradient-2)'
          : state.isFocused
            ? 'var(--hover-gradient-1)'
            : 'transparent',
        color: 'var(--text-color)',
        '&:active': {
          backgroundColor: 'var(--primary-gradient-2)',
        },
      }),
    };

    return (
      <CSSTransition in={isOpen} timeout={300} classNames="modal-animation" unmountOnExit>
        <Modal isOpen={isOpen} toggle={toggle} size="xl" centered className="custom-modal">
          <ModalBody className="modal-body-custom">
            <div className="sticky-select">
              <Select
                options={allUserNames.map((user) => ({ value: user, label: user }))}
                onChange={handleUserChange}
                placeholder="Select users..."
                isClearable
                isMulti
                styles={customStyles}
                classNamePrefix="select"
              />
            </div>
            <TransitionGroup component={Row} className="history-content">
              {userHistories.map(({ user, history, totalPages, currentPage }) => (
                <CSSTransition key={user} timeout={300} classNames="fade">
                  <Col xs="12">
                    <h5 className="history-header">{user}'s History</h5>
                    {history.length > 0 ? (
                      <>
                        <TransitionGroup component={Row}>
                          {history.map((movieDetail, idx) => (
                            <CSSTransition
                              key={`${user}-${idx}`}
                              timeout={300}
                              classNames="fade"
                            >
                              <Col xs="12" sm="6" md="4" lg="3" className="mb-3">
                                <div className="movie-card-wrapper">
                                  {user === localStorage.getItem('userCode') ? (
                                    <MovieCard
                                      movie={movieDetail}
                                      onClick={() => handleCardClick(movieDetail)}
                                      onDelete={() => handleDeleteHistory(movieDetail, user)}
                                      isMobile={false}
                                    />
                                  ) : (
                                    <MovieCard
                                      movie={movieDetail}
                                      onClick={() => handleCardClick(movieDetail)}
                                      isMobile={false}
                                    />
                                  )}
                                </div>
                              </Col>
                            </CSSTransition>
                          ))}
                        </TransitionGroup>
                        {totalPages > 1 && (
                          <Pagination className="justify-content-center custom-pagination">
                            <PaginationItem
                              className="animated-pagination-item"
                              disabled={currentPage === 1}
                            >
                              <PaginationLink
                                className="pagination-button"
                                previous
                                onClick={() => handlePageChange(user, currentPage - 1)}
                              />
                            </PaginationItem>
                            {[...Array(totalPages)].map((_, i) => (
                              <PaginationItem
                                active={i + 1 === currentPage}
                                key={`${user}-page-${i}`}
                                className={
                                  i + 1 === currentPage
                                    ? 'active-page'
                                    : 'animated-pagination-item'
                                }
                              >
                                <PaginationLink
                                  className="pagination-button"
                                  onClick={() => handlePageChange(user, i + 1)}
                                >
                                  {i + 1}
                                </PaginationLink>
                              </PaginationItem>
                            ))}
                            <PaginationItem
                              className="animated-pagination-item"
                              disabled={currentPage === totalPages}
                            >
                              <PaginationLink
                                className="pagination-button"
                                next
                                onClick={() => handlePageChange(user, currentPage + 1)}
                              />
                            </PaginationItem>
                          </Pagination>
                        )}
                      </>
                    ) : (
                      <p className="no-history-message">No history available for {user}.</p>
                    )}
                  </Col>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </ModalBody>
        </Modal>
      </CSSTransition>
    );
  };

  // -- Mobil versiyonu --
  const renderMobileVersion = () => {
    const customStyles = {
      // Aynı stil, mobil selectte de kullanılıyor
      ...{
        control: (provided, state) => ({
          ...provided,
          backgroundColor: 'transparent',
          borderColor: state.isFocused ? 'var(--glow-color)' : 'var(--border-color)',
          boxShadow: state.isFocused ? `0 0 8px var(--glow-color)` : 'none',
          '&:hover': {
            borderColor: 'var(--glow-color)',
          },
          borderRadius: '8px',
          padding: '5px',
          color: 'var(--text-color)',
        }),
        multiValue: (provided) => ({
          ...provided,
          backgroundColor: 'var(--hover-gradient-2)',
          borderRadius: '4px',
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          color: 'var(--text-color)',
          fontWeight: 'bold',
        }),
        multiValueRemove: (provided) => ({
          ...provided,
          color: 'var(--text-color)',
          '&:hover': {
            backgroundColor: 'var(--hover-gradient-1)',
            color: 'var(--text-color)',
          },
        }),
        placeholder: (provided) => ({
          ...provided,
          color: 'rgba(255, 255, 255, 0.6)',
          fontStyle: 'italic',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          color: state.isFocused ? 'var(--glow-color)' : 'var(--text-color)',
          '&:hover': {
            color: 'var(--glow-color)',
          },
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(32, 43, 96, 0.9)',
          border: '1px solid var(--border-color)',
          boxShadow: `0 8px 16px var(--glow-color)`,
          borderRadius: '8px',
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected
            ? 'var(--hover-gradient-2)'
            : state.isFocused
              ? 'var(--hover-gradient-1)'
              : 'transparent',
          color: 'var(--text-color)',
          '&:active': {
            backgroundColor: 'var(--primary-gradient-2)',
          },
        }),
      }
    };

    // Seçili kullanıcıların history'sini hesapla
    const userHistories = selectedUsers.map((user) => {
      const histories = localHistories
        .filter((history) => history.owner === user && history.historyType === 1)
        .sort((a, b) => {
          const dateA = new Date(a.updatedDate || a.createdDate);
          const dateB = new Date(b.updatedDate || b.createdDate);
          return dateB - dateA;
        });

      const totalPages = Math.ceil(histories.length / HISTORIES_PER_PAGE);
      const currentPage = userPages[user] || 1;
      const currentHistory = histories.slice(
        (currentPage - 1) * HISTORIES_PER_PAGE,
        currentPage * HISTORIES_PER_PAGE
      );

      return {
        user,
        history: currentHistory.map((history) => history.movieDetail),
        totalPages,
        currentPage,
      };
    });

    return (
      <div className="mobile-history-container">
        <div className="mobile-history-header">
          <button className="mobile-close-button" onClick={toggle}>
            <FontAwesomeIcon icon={faArrowLeft} />
            <span>Watch History</span>
          </button>
          <div className="mobile-user-select">
            <Select
              options={allUserNames.map((user) => ({ value: user, label: user }))}
              onChange={handleUserChange}
              placeholder="Select users..."
              isClearable
              isMulti
              styles={customStyles}
              classNamePrefix="select-mobile"
            />
          </div>
        </div>

        <div className="mobile-history-content">
          {userHistories.map(({ user, history, totalPages, currentPage }) => (
            <div key={user} className="mobile-user-history">
              <h3 className="mobile-user-title">{user}'s History</h3>
              {history.length > 0 ? (
                <div className="mobile-history-grid">
                  {history.map((movieDetail, idx) => (
                    <div key={`${user}-${idx}`} className="mobile-history-item">
                      <MovieCard
                        movie={movieDetail}
                        onClick={(m) => handleCardClick(m)}
                        onDelete={
                          user === localStorage.getItem('userCode')
                            ? () => handleDeleteHistory(movieDetail, user)
                            : undefined
                        }
                        isMobile={true}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="mobile-empty-state">
                  <FontAwesomeIcon icon={faHistory} size="2x" />
                  <p>No history available</p>
                </div>
              )}
              {totalPages > 1 && (
                <div className="mobile-pagination">
                  <button
                    className="mobile-page-button"
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(user, currentPage - 1)}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  <span className="mobile-page-info">
                    {currentPage} / {totalPages}
                  </span>
                  <button
                    className="mobile-page-button"
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(user, currentPage + 1)}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return isMobile ? renderMobileVersion() : renderDesktopVersion();
};

// -----------------------------------------------
// FILTER TYPE SELECT
// -----------------------------------------------
const FilterTypeSelect = ({ filterType, setFilterType, isMobile }) => {
  const options = [
    { value: 'AND', label: 'AND' },
    { value: 'OR', label: 'OR' }
  ];

  // Masaüstü ve mobil için benzer, sadece className farkları veya boyut farkı olabilir
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      borderColor: state.isFocused ? 'var(--glow-color)' : 'var(--border-color)',
      boxShadow: state.isFocused ? `0 0 8px var(--glow-color)` : 'none',
      '&:hover': {
        borderColor: 'var(--glow-color)',
      },
      borderRadius: '8px',
      padding: '5px',
      color: 'var(--text-color)',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'var(--text-color)',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'rgba(255, 255, 255, 0.6)',
      fontStyle: 'italic',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? 'var(--glow-color)' : 'var(--text-color)',
      '&:hover': {
        color: 'var(--glow-color)',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'rgba(32, 43, 96, 0.9)',
      border: '1px solid var(--border-color)',
      boxShadow: `0 8px 16px var(--glow-color)`,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? 'var(--hover-gradient-2)'
        : state.isFocused
          ? 'var(--hover-gradient-1)'
          : 'transparent',
      color: 'var(--text-color)',
      '&:active': {
        backgroundColor: 'var(--primary-gradient-2)',
      },
    }),
  };

  const renderDesktopVersion = () => {
    return (
      <Select
        options={options}
        value={options.find(option => option.value === filterType)}
        onChange={(selectedOption) => setFilterType(selectedOption.value)}
        placeholder="Select Filter..."
        styles={customStyles}
        className="filter-type-select"
        classNamePrefix="select"
        isSearchable={false}
      />
    );
  };

  const renderMobileVersion = () => {
    return (
      <Select
        options={options}
        value={options.find(option => option.value === filterType)}
        onChange={(selectedOption) => setFilterType(selectedOption.value)}
        placeholder="Select Filter..."
        styles={customStyles}
        className="filter-type-select-mobile"
        classNamePrefix="select"
        isSearchable={false}
      />
    );
  };

  return isMobile ? renderMobileVersion() : renderDesktopVersion();
};

// -----------------------------------------------
// GENRE SELECTOR
// -----------------------------------------------
const GenreSelector = ({ allMovieGenres, selectedGenres, setSelectedGenres, isMobile }) => {
  const genreOptions = allMovieGenres.map(genre => ({
    value: genre.id,
    label: genre.name
  }));

  const handleChange = (selectedOptions) => {
    setSelectedGenres(selectedOptions || []);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      borderColor: state.isFocused ? 'var(--glow-color)' : 'var(--border-color)',
      boxShadow: state.isFocused ? `0 0 8px var(--glow-color)` : 'none',
      '&:hover': {
        borderColor: 'var(--glow-color)',
      },
      borderRadius: '8px',
      padding: '5px',
      color: 'var(--text-color)',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'var(--hover-gradient-2)',
      borderRadius: '4px',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'var(--text-color)',
      fontWeight: 'bold',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'var(--text-color)',
      '&:hover': {
        backgroundColor: 'var(--hover-gradient-1)',
        color: 'var(--text-color)',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'rgba(255, 255, 255, 0.6)',
      fontStyle: 'italic',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? 'var(--glow-color)' : 'var(--text-color)',
      '&:hover': {
        color: 'var(--glow-color)',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'rgba(32, 43, 96, 0.9)',
      border: '1px solid var(--border-color)',
      boxShadow: `0 8px 16px var(--glow-color)`,
      borderRadius: '8px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? 'var(--hover-gradient-2)'
        : state.isFocused
          ? 'var(--hover-gradient-1)'
          : 'transparent',
      color: 'var(--text-color)',
      '&:active': {
        backgroundColor: 'var(--primary-gradient-2)',
      },
    }),
  };

  // Masaüstü versiyonu
  const renderDesktopVersion = () => {
    return (
      <Select
        options={genreOptions}
        isMulti
        value={selectedGenres}
        onChange={handleChange}
        placeholder="Select Genres..."
        className="genre-select"
        styles={customStyles}
        classNamePrefix="select"
        closeMenuOnSelect={false}
      />
    );
  };

  // Mobil versiyonu
  const renderMobileVersion = () => {
    return (
      <Select
        options={genreOptions}
        isMulti
        value={selectedGenres}
        onChange={handleChange}
        placeholder="Select Genres..."
        className="genre-select-mobile"
        styles={customStyles}
        classNamePrefix="select"
        closeMenuOnSelect={false}
      />
    );
  };

  return isMobile ? renderMobileVersion() : renderDesktopVersion();
};

// -----------------------------------------------
// MOVIE CARD (desktop + mobile)
// -----------------------------------------------
const MovieCard = ({ movie, onClick, onDelete, isMobile }) => {
  const imageUrl = `https://image.tmdb.org/t/p/w300_and_h450_bestv2${movie.poster_path}`;

  // Masaüstü versiyonu
  const renderDesktopVersion = () => {
    return (
      <div className="movie-card" onClick={() => onClick(movie)}>
        <div className="card-image">
          <img src={imageUrl} alt={movie.title} loading='eager' />
        </div>
        <div className="card-content">
          <h5 className="card-title">{movie.title}</h5>
          {onDelete && (
            <button
              className="close-icon"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDelete();
              }}
            >
              <FaTimes />
            </button>
          )}
        </div>
      </div>
    );
  };

  // Mobil versiyonu
  const renderMobileVersion = () => {
    return (
      <div className="movie-card-mobile" onClick={() => onClick(movie)}>
        <div className="card-image-mobile">
          <img src={imageUrl} alt={movie.title} loading='eager' />
        </div>
        <div className="card-content-mobile">
          <h5 className="card-title-mobile">{movie.title}</h5>
          {onDelete && (
            <button
              className="close-icon-mobile"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDelete();
              }}
            >
              <FaTimes />
            </button>
          )}
        </div>
      </div>
    );
  };

  return isMobile ? renderMobileVersion() : renderDesktopVersion();
};
MovieCard.propTypes = {
  movie: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  isMobile: PropTypes.bool
};

// -----------------------------------------------
// MOVIE DETAIL TAB
// -----------------------------------------------
const MovieDetailTab = ({
  data: movieId,
  onClose,
  allUsersHistory,
  setAllUsersHistory,
  isMobile
}) => {
  const postRequestSyncPromise = usePostRequestSyncPromise();
  const [details, setDetails] = useState(null);
  const [isWatchPopupOpen, setWatchPopupOpen] = useState(false);
  const [watchUrl, setWatchUrl] = useState('');
  const [similars, setSimilars] = useState([]);
  const [currentMovieId, setCurrentMovieId] = useState(movieId);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (movieId) {
      fetchMovieDetails(movieId);
      fetchSimilars(movieId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movieId]);

  const fetchSimilars = (id) => {
    const baseReq = {
      data: [`${id}`],
    };
    postRequestSyncPromise('Movie/GetSimilarMovies', baseReq)
      .then(data => {
        setSimilars(data.data[0].results);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const fetchMovieDetails = (id) => {
    const detailFilter = {
      id: `${id}`,
      language: 'tr-TR',
      append_to_response: 'videos'
    };
    const baseRequest = {
      requestId: 'unique_request_id',
      sender: 'client_tab_movie_details',
      data: [detailFilter]
    };
    postRequestSyncPromise('Movie/MovieDetail', baseRequest)
      .then(data => {
        setDetails(data.data[0]);
        setCurrentMovieId(id);
      })
      .catch(error => console.error('Fetch error:', error));
  };

  const postUserHistory = (details) => {
    const userCode = localStorage.getItem('userCode');
    const existingHistory = allUsersHistory.find(
      (history) =>
        history.owner === userCode && history?.movieDetail?.id === details.id
    );

    let historyData = {
      owner: userCode,
      historyType: 1,
      movieDetail: details
    };

    if (existingHistory) {
      historyData._id = existingHistory._id;
      historyData.createdDate = existingHistory.createdDate;
    }

    const baseRequest = { data: [historyData] };
    const endpoint = existingHistory
      ? 'UserHistory/UpdateUserHistory'
      : 'UserHistory/CreateUserHistory';

    postRequestSyncPromise(endpoint, baseRequest)
      .then(() => {
        // Başarılı işlem -> SignalR ile anlık güncelleme
      })
      .catch(error => {
        console.error(error);
      });
  };

  const renderItems = (items) => {
    return items && items.length > 0
      ? items.map((item) => item.name || item.english_name).join(', ')
      : 'N/A';
  };

  const toggleWatchPopup = (type) => {
    const baseVideoUrl = `https://vidsrc.to/embed/movie/${currentMovieId}`;
    const videoUrlWithSub = `https://vidsrc.xyz/embed/movie?tmdb=${currentMovieId}&ds_langs=en,tr`;
    setWatchUrl(type === 'withSub' ? videoUrlWithSub : baseVideoUrl);
    setWatchPopupOpen(true);
    if (details) {
      postUserHistory(details);
    }
  };

  const handleSimilarMovieClick = (similarMovieId) => {
    fetchMovieDetails(similarMovieId);
    fetchSimilars(similarMovieId);
  };

  // Slider ayarları (benzer filmler)
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    pauseOnHover: true
  };

  // -- Masaüstü versiyonu --
  const renderDesktopVersion = () => {
    return (
      <CSSTransition in={true} timeout={300} classNames="modal-animation" unmountOnExit>
        <Modal isOpen={true} toggle={onClose} size="xl" centered className="movie-detail-modal">
          <ModalBody className="modal-body-custom">
            {details ? (
              <div>
                <button className="modal-close-button" onClick={onClose}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
                <div className="movie-card-detail">
                  <img
                    src={`https://image.tmdb.org/t/p/w500${details.poster_path}`}
                    alt={details.title}
                    className="detail-poster"
                    loading='eager'
                  />
                  <div className="detail-info">
                    <h2 className="detail-title">{details.title}</h2>
                    <p className="detail-overview">{details.overview || 'N/A'}</p>

                    <div className="movie-details-grid">
                      <div className="detail-section">
                        <span className="detail-label">Genres:</span>
                        <span className="detail-value">{renderItems(details.genres)}</span>
                      </div>
                      <div className="detail-section">
                        <span className="detail-label">Release Date:</span>
                        <span className="detail-value">{details.release_date || 'N/A'}</span>
                      </div>
                      <div className="detail-section">
                        <span className="detail-label">Runtime:</span>
                        <span className="detail-value">{details.runtime || 'N/A'} minutes</span>
                      </div>
                      <div className="detail-section">
                        <span className="detail-label">Vote Average:</span>
                        <span className="detail-value">{details.vote_average || 'N/A'}</span>
                      </div>
                      <div className="detail-section">
                        <span className="detail-label">Popularity:</span>
                        <span className="detail-value">{details.popularity || 'N/A'}</span>
                      </div>
                      <div className="detail-section">
                        <span className="detail-label">Status:</span>
                        <span className="detail-value">{details.status || 'N/A'}</span>
                      </div>
                    </div>

                    <div className="movie-detail-actions">
                      <button className="stylish-button" onClick={() => toggleWatchPopup('noSub')}>
                        Watch Movie
                      </button>
                      <button className="stylish-button" onClick={() => toggleWatchPopup('withSub')}>
                        Watch Movie With Subtitles
                      </button>
                    </div>
                  </div>
                </div>

                <h2 className="similar-movies-title">Similar Movies</h2>
                <Slider {...sliderSettings} className="similar-movies-slider">
                  {similars.map((movie) => (
                    <div
                      key={movie.id}
                      className="similar-movie-card"
                      onClick={() => handleSimilarMovieClick(movie.id)}
                    >
                      <img
                        src={`https://image.tmdb.org/t/p/w200${movie.poster_path}`}
                        alt={movie.title}
                        className="similar-movie-poster"
                        loading='eager'
                      />
                      <div className="similar-movie-info">
                        <h6 className="similar-movie-title">{movie.title}</h6>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              <div className="loading">Loading...</div>
            )}
          </ModalBody>

          <Modal
            isOpen={isWatchPopupOpen}
            toggle={() => setWatchPopupOpen(false)}
            size="xl"
            centered
            className="custom-modal"
          >
            <ModalBody className="modal-body-watch">
              <div className="video-wrapper">
                <iframe
                  src={`${watchUrl}?autoplay=1`}
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title="Movie Watch"
                  className="video-iframe"
                />
              </div>
            </ModalBody>
          </Modal>
        </Modal>
      </CSSTransition>
    );
  };

  // -- Mobil versiyonu --
  const renderMobileVersion = () => {
    if (!details) {
      return <div className="loading-mobile">Loading...</div>;
    }
    const posterUrl = details.poster_path
      ? `https://image.tmdb.org/t/p/w500${details.poster_path}`
      : '/fallback-image.jpg';

    return (
      <div className="mobile-detail-container">
        <div
          className="mobile-detail-banner"
          style={{
            backgroundImage: `url(${posterUrl})`,
          }}
        >
          <div className="mobile-detail-banner-overlay">
            <button className="mobile-back-button" onClick={onClose}>
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>
        </div>

        <div className="mobile-detail-content">
          <div className="mobile-detail-header">
            <h1 className="mobile-title">{details.title || 'N/A'}</h1>
            <div className="mobile-meta">
              <span className="mobile-rating">
                <FontAwesomeIcon icon={faStar} /> {details.vote_average || 'N/A'}
              </span>
              <span className="mobile-year">
                {details.release_date
                  ? new Date(details.release_date).getFullYear()
                  : 'N/A'}
              </span>
            </div>
          </div>
          <div className="mobile-genres">
            {details.genres && details.genres.length > 0
              ? details.genres.map((genre) => (
                <span key={genre.id} className="mobile-genre-tag">
                  {genre.name}
                </span>
              ))
              : 'N/A'}
          </div>
          <div className="mobile-overview">
            <p>{details.overview || 'Overview not available.'}</p>
          </div>
          <div className="mobile-info-grid">
            <div className="mobile-info-item">
              <label>Status</label>
              <span>{details.status || 'N/A'}</span>
            </div>
            <div className="mobile-info-item">
              <label>Runtime</label>
              <span>{details.runtime || 'N/A'} min</span>
            </div>
          </div>
        </div>

        <div className="mobile-actions">
          <button
            className="mobile-action-button watch-primary"
            onClick={() => toggleWatchPopup('withSub')}
          >
            <FontAwesomeIcon icon={faPlay} className="action-icon" />
            <span>Watch with Subtitles</span>
          </button>
          <button
            className="mobile-action-button watch-secondary"
            onClick={() => toggleWatchPopup('noSub')}
          >
            <FontAwesomeIcon icon={faClosedCaptioning} className="action-icon" />
            <span>Without Subtitles</span>
          </button>
        </div>

        <Modal
          isOpen={isWatchPopupOpen}
          toggle={() => setWatchPopupOpen(false)}
          size={isMobile ? 'full' : 'xl'}
          centered={!isMobile}
          className={`video-modal ${isMobile ? 'video-modal-mobile' : ''}`}
        >
          <ModalBody className={`video-modal-body ${isMobile ? 'video-modal-body-mobile' : ''}`}>
            <button
              className="video-close-button"
              onClick={() => setWatchPopupOpen(false)}
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
            <div className="video-container-not">
              {isLoading && (
                <div className="video-loading">
                  <FontAwesomeIcon icon={faSpinner} spin />
                </div>
              )}
              <iframe
                src={`${watchUrl}&autoplay=1`}
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="Movie Watch"
                className="video-iframe"
                onLoad={() => setIsLoading(false)}
              />
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  };

  return isMobile ? renderMobileVersion() : renderDesktopVersion();
};
MovieDetailTab.propTypes = {
  data: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool
};

// -----------------------------------------------
// MOVIE WATCH (Örnek, projede kullanılabilir)
// -----------------------------------------------
const MovieWatch = ({ isOpen, toggle, movieId, isMobile }) => {
  const videoUrl = `https://vidsrc.to/embed/movie/${movieId}`;
  const videoUrlWithSub = `https://vidsrc.xyz/embed/movie?tmdb=${movieId}&ds_langs=en,tr`;

  const iframeRef1 = useRef(null);
  const iframeRef2 = useRef(null);

  useEffect(() => {
    const handleIframeEvents = (iframe) => {
      try {
        const iframeWindow = iframe.current?.contentWindow;
        if (iframeWindow) {
          iframeWindow.addEventListener('beforeunload', (event) => {
            event.preventDefault();
          });
          window.addEventListener('message', (event) => {
            if (
              event.origin === 'https://vidsrc.to' ||
              event.origin === 'https://vidsrc.xyz'
            ) {
              // message from iframe
            }
          });
        }
      } catch (error) {
        console.error('Error accessing iframe content:', error);
      }
    };
    handleIframeEvents(iframeRef1);
    handleIframeEvents(iframeRef2);

    return () => {
      window.removeEventListener('message', handleIframeEvents);
    };
  }, [isOpen]);

  // Masaüstü versiyonu
  const renderDesktopVersion = () => {
    return (
      <CSSTransition in={isOpen} timeout={300} classNames="modal-animation" unmountOnExit>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          size="xl"
          className="movie-watch-modal custom-modal"
          centered
        >
          <ModalHeader toggle={toggle} className="modal-header-custom">
            Watch Movie
          </ModalHeader>
          <ModalBody className="modal-body-custom">
            <div className="video-container-not">
              <div className="iframe-wrapper">
                <div className="video-wrapper">
                  <iframe
                    ref={iframeRef1}
                    src={`${videoUrl}?autoplay=1`}
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="Movie Watch"
                    className="video-iframe"
                    referrerPolicy="origin"
                  />
                </div>
                <div className="video-wrapper">
                  <iframe
                    ref={iframeRef2}
                    src={`${videoUrlWithSub}&autoplay=1`}
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="Movie Watch With Subtitles"
                    className="video-iframe"
                    referrerPolicy="origin"
                  />
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </CSSTransition>
    );
  };

  // Mobil versiyonu
  const renderMobileVersion = () => {
    return (
      <CSSTransition in={isOpen} timeout={300} classNames="modal-animation" unmountOnExit>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          size="xl"
          className="movie-watch-modal-mobile custom-modal-mobile"
          centered
        >
          <ModalHeader toggle={toggle} className="modal-header-custom-mobile">
            Watch Movie
          </ModalHeader>
          <ModalBody className="modal-body-custom-mobile">
            <div className="video-container-not-mobile">
              <div className="iframe-wrapper-mobile">
                <div className="video-wrapper-mobile">
                  <iframe
                    ref={iframeRef1}
                    src={`${videoUrl}?autoplay=1`}
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="Movie Watch"
                    className="video-iframe-mobile"
                    referrerPolicy="origin"
                  />
                </div>
                <div className="video-wrapper-mobile">
                  <iframe
                    ref={iframeRef2}
                    src={`${videoUrlWithSub}&autoplay=1`}
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="Movie Watch With Subtitles"
                    className="video-iframe-mobile"
                    referrerPolicy="origin"
                  />
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </CSSTransition>
    );
  };

  return isMobile ? renderMobileVersion() : renderDesktopVersion();
};
MovieWatch.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  movieId: PropTypes.number.isRequired,
  isMobile: PropTypes.bool
};

// -----------------------------------------------
// SLIDER CONTENT (anasayfa slider'i - Movie)
// -----------------------------------------------
const SliderContent = ({
  movieId,
  postRequestSyncPromise,
  allUsersHistory,
  isMobile
}) => {
  const [movieDetails, setMovieDetails] = useState(null);
  const [isWatchPopupOpen, setWatchPopupOpen] = useState(false);
  const [watchUrl, setWatchUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const postUserHistory = (details) => {
    const userCode = localStorage.getItem('userCode');
    const existingHistory = allUsersHistory.find(
      (history) =>
        history.owner === userCode && history?.movieDetail?.id === details.id
    );

    let historyData = {
      owner: userCode,
      historyType: 1,
      movieDetail: details
    };

    if (existingHistory) {
      historyData._id = existingHistory._id;
      historyData.createdDate = existingHistory.createdDate;
    }

    const baseRequest = { data: [historyData] };
    const endpoint = existingHistory
      ? 'UserHistory/UpdateUserHistory'
      : 'UserHistory/CreateUserHistory';

    fetch(CONFIG.BaseUrl + endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(baseRequest)
    })
      .then(response => response.json())
      .then(data => {
        if (data.type === 0) {
          console.log("User history posted successfully.");
        } else {
          console.log("Error in posting user history:", data);
        }
      })
      .catch(error => {
        console.error("Error in posting user history:", error);
      });
  };

  const fetchMovieDetails = useCallback(() => {
    const detailFilter = {
      id: `${movieId}`,
      language: 'tr-TR',
      append_to_response: 'videos'
    };
    const baseRequest = {
      requestId: 'unique_request_id',
      sender: 'client_tab_movie_details',
      data: [detailFilter]
    };
    postRequestSyncPromise('Movie/MovieDetail', baseRequest)
      .then(data => {
        setMovieDetails(data.data[0]);
      })
      .catch(error => console.error('Fetch error:', error));
  }, [movieId, postRequestSyncPromise]);

  useEffect(() => {
    fetchMovieDetails();
  }, [fetchMovieDetails]);

  const toggleWatchPopup = () => {
    const videoUrlWithSub = `https://vidsrc.xyz/embed/movie?tmdb=${movieId}&ds_langs=en,tr`;
    setWatchUrl(videoUrlWithSub);
    setWatchPopupOpen(true);
    if (movieDetails) {
      postUserHistory(movieDetails);
    }
  };

  if (!movieDetails) {
    return <div>Loading...</div>;
  }

  const renderItems = (items) => {
    return items && items.length > 0
      ? items.map((item) => item.name || item.english_name).join(', ')
      : 'N/A';
  };

  // Masaüstü versiyonu
  const renderDesktopVersion = () => {
    return (
      <div className="slider-content-wrapper">
        <div className="slider-content">
          <div
            className="slider-content__backdrop"
            style={{
              backgroundImage: `url(https://image.tmdb.org/t/p/original${movieDetails.backdrop_path})`
            }}
          >
            <div className="slider-content__overlay">
              <div className="slider-content__info">
                <h2 className="slider-content__title">{movieDetails.title}</h2>
                <div className="slider-content__meta">
                  <span className="release-date">{movieDetails.release_date}</span>
                  <span className="rating">
                    <i className="fas fa-star"></i> {movieDetails.vote_average}
                  </span>
                  <span className="genres">{renderItems(movieDetails.genres)}</span>
                </div>
                <p className="slider-content__overview">{movieDetails.overview}</p>
                <div className="slider-content__stats">
                  <div className="stat">
                    <span className="label">Runtime</span>
                    <span className="value">{movieDetails.runtime} min</span>
                  </div>
                  <div className="stat">
                    <span className="label">Language</span>
                    <span className="value">
                      {movieDetails.original_language?.toUpperCase()}
                    </span>
                  </div>
                  <div className="stat">
                    <span className="label">Status</span>
                    <span className="value">{movieDetails.status}</span>
                  </div>
                </div>
                <div className="slider-content__actions">
                  <button className="watch-button" onClick={toggleWatchPopup}>
                    <i className="fas fa-play"></i> Watch Now
                  </button>
                </div>
              </div>
            </div>
          </div>

          {isWatchPopupOpen && (
            <Modal
              isOpen={isWatchPopupOpen}
              toggle={() => setWatchPopupOpen(false)}
              size="xl"
              centered
              className="custom-modal"
            >
              <ModalBody className="modal-body-watch">
                <div className="video-wrapper">
                  <iframe
                    src={`${watchUrl}&autoplay=1`}
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="Movie Watch"
                    className="video-iframe"
                    onLoad={() => setIsLoading(false)}
                  />
                </div>
              </ModalBody>
            </Modal>
          )}
        </div>
      </div>
    );
  };

  // Mobil versiyonu
  const renderMobileVersion = () => {
    return (
      <div className="slider-content-wrapper-mobile">
        <div className="slider-content-mobile">
          <div
            className="slider-content-mobile__backdrop"
            style={{
              backgroundImage: `url(https://image.tmdb.org/t/p/original${movieDetails.backdrop_path})`
            }}
          >
            <div className="slider-content-mobile__overlay">
              <div className="slider-content-mobile__info">
                <h2 className="slider-content-mobile__title">{movieDetails.title}</h2>
                <div className="slider-content-mobile__meta">
                  <span className="release-date-mobile">{movieDetails.release_date}</span>
                  <span className="rating-mobile">
                    <i className="fas fa-star"></i> {movieDetails.vote_average}
                  </span>
                  <span className="genres-mobile">{renderItems(movieDetails.genres)}</span>
                </div>
                <div className="slider-content-mobile__stats">
                  <div className="stat-mobile">
                    <span className="label-mobile">Runtime</span>
                    <span className="value-mobile">{movieDetails.runtime} min</span>
                  </div>
                  <div className="stat-mobile">
                    <span className="label-mobile">Language</span>
                    <span className="value-mobile">
                      {movieDetails.original_language?.toUpperCase()}
                    </span>
                  </div>
                  <div className="stat-mobile">
                    <span className="label-mobile">Status</span>
                    <span className="value-mobile">{movieDetails.status}</span>
                  </div>
                </div>
                <div className="slider-content-mobile__actions">
                  <button className="watch-button-mobile" onClick={toggleWatchPopup}>
                    <i className="fas fa-play"></i> Watch Now
                  </button>
                </div>
              </div>
            </div>
          </div>

          {isWatchPopupOpen && (
            <Modal
              isOpen={isWatchPopupOpen}
              toggle={() => setWatchPopupOpen(false)}
              size={isMobile ? 'full' : 'xl'}
              centered={!isMobile}
              className={`video-modal ${isMobile ? 'video-modal-mobile' : ''}`}
            >
              <ModalBody className={`video-modal-body ${isMobile ? 'video-modal-body-mobile' : ''}`}>
                <button
                  className="video-close-button"
                  onClick={() => setWatchPopupOpen(false)}
                >
                  <FontAwesomeIcon icon={faClose} />
                </button>
                <div className="video-container-not">
                  {isLoading && (
                    <div className="video-loading">
                      <FontAwesomeIcon icon={faSpinner} spin />
                    </div>
                  )}
                  <iframe
                    src={`${watchUrl}&autoplay=1`}
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="Movie Watch"
                    className="video-iframe"
                    onLoad={() => setIsLoading(false)}
                  />
                </div>
              </ModalBody>
            </Modal>
          )}
        </div>
      </div>
    );
  };

  return isMobile ? renderMobileVersion() : renderDesktopVersion();
};

// -----------------------------------------------
// ANA MOVIE COMPONENT
// -----------------------------------------------
export default function Movie() {

  const { allUsers } = useContext(UserContext);

  // SIGNALR
  const signalConnection = useRef(null);

  // Ekran boyutu
  const { isMobile, orientation } = useIsMobile();

  // Sayfa/Arama state'leri
  const [pageCountInfo, setPageCountInfo] = useState(1);
  const [isSearchActive, setIsSearchActive] = useState(false);

  // Global fetch
  const postRequestSyncPromise = usePostRequestSyncPromise();

  // Movie Search
  const [movieSearchTextboxValue, setMovieSearchTextboxValue] = useState("");

  // Movie Data
  const [searchedMovieDataSource, setSearchedMovieDataSource] = useState([]);
  const [topMovies, setTopMovies] = useState([]);
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [allMovieGenres, setAllMovieGenres] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [filterType, setFilterType] = useState("AND");
  const [allUserNames, setAllUsers] = useState([]);
  const [allUsersHistory, setAllUsersHistory] = useState([]);
  const [isHistoryPopupVisible, setIsHistoryPopupVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  // İlk yüklemede Discover ve TopMovies
  useEffect(() => {
    DiscoverMovie(1);
    FetchTopMovies();
    fetchMovieGenres();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAllUsers(allUsers.map(user => user.userName));
  }, [allUsers]);

  useEffect(() => {
    initializeSignalR();
    // eslint-disable-next-line
  }, []);

  // Pagination
  const handlePageClick = (data) => {
    let selectedPage = data.selected + 1;
    setCurrentPage(selectedPage);
    setSearchedMovieDataSource([]);
    if (isSearchActive) {
      SearchMovie(selectedPage);
    } else {
      DiscoverMovie(selectedPage);
    }
  };

  // Discover
  const DiscoverMovie = useCallback((page = 1) => {
    if (page === 1) {
      setCurrentPage(1);
    }
    let pageInformation = page || 1;

    let withGenres = "";
    let genreIds = selectedGenres.map(genre => genre.value);
    if (filterType === "AND")
      withGenres = genreIds.join(',');
    else
      withGenres = genreIds.join('|');

    const discoverFilter = {
      include_adult: true,
      language: "tr-TR",
      page: pageInformation,
      with_genres: withGenres
    };

    const baseRequest = {
      RequestId: "unique_request_id",
      Sender: "client_discover_movie",
      Data: [discoverFilter]
    };

    postRequestSyncPromise("Movie/DiscoverMovie", baseRequest)
      .then(data => {
        setSearchedMovieDataSource(data.data[0].results);
        setPageCountInfo(data.data[0].total_pages || 500);
        window.scrollTo(0, 0);
      })
      .catch(error => {
        console.error(error);
      });
  }, [postRequestSyncPromise, selectedGenres, filterType]);

  // Search
  const SearchMovie = useCallback((page = 1) => {
    if (page === 1) {
      setCurrentPage(1);
    }
    const searchFilter = {
      query: movieSearchTextboxValue,
      language: "tr-TR",
      page: page
    };
    const baseRequest = {
      RequestId: "unique_request_id",
      Sender: "client_search_movie",
      Data: [searchFilter]
    };

    postRequestSyncPromise("Movie/SearchMovie", baseRequest)
      .then(data => {
        setSearchedMovieDataSource(data.data[0].results);
        setPageCountInfo(data.data[0].total_pages);
        window.scrollTo(0, 0);
      })
      .catch(error => {
        console.error(error);
      });
  }, [movieSearchTextboxValue, postRequestSyncPromise]);

  // TopMovies
  const FetchTopMovies = useCallback(() => {
    const discoverFilter = {
      include_adult: false,
      language: "tr-TR",
      page: 1
    };
    const baseRequest = {
      RequestId: "unique_request_id",
      Sender: "client_discover_movie",
      Data: [discoverFilter]
    };

    postRequestSyncPromise("Movie/DiscoverMovie", baseRequest)
      .then(data => {
        const sortedMovies = data.data[0].results.sort((a, b) => b.popularity - a.popularity);
        const topTen = sortedMovies.slice(0, 10);
        setTopMovies(topTen);
      })
      .catch(error => {
        console.error(error);
      });
  }, [postRequestSyncPromise]);

  // Genre
  const fetchMovieGenres = useCallback(() => {
    postRequestSyncPromise('Movie/GetAllMovieGenres', {})
      .then(data => {
        setAllMovieGenres(data.data[0].genres);
      })
      .catch(error => {
        console.error(error);
      });
  }, [postRequestSyncPromise]);


  // History
  const fetchUserHistory = useCallback(() => {
    const baseRequest = {
      data: allUserNames
    };
    return postRequestSyncPromise('UserHistory/GetSelectedUsersHistory', baseRequest)
      .then(data => {
        setAllUsersHistory(data.data);
      })
      .catch(error => {
        console.error("Error fetching user history:", error);
      });
  }, [allUserNames, postRequestSyncPromise]);


  // Kullanıcı isimleri gelince history'i çek
  useEffect(() => {
    if (allUserNames.length > 0) {
      fetchUserHistory();
    }
  }, [allUserNames, fetchUserHistory]);

  // SignalR
  const initializeSignalR = async () => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.kaminarivi.com.tr/movieHub")
      .withAutomaticReconnect([1000, 2000, 3000, 5000, 10000])
      .build();

    async function start(connection) {
      try {
        await connection.start();
        localStorage.setItem("connectionId", connection.connectionId);
      } catch (error) {
        console.error(error);
        setTimeout(() => start(connection), 5000);
      }
    }
    start(newConnection);
    signalConnection.current = newConnection;

    newConnection.onreconnected(() => {
      showSuccessToast("Connection Reestablished.");
    });
    newConnection.onclose(async (error) => {
      if (error) {
        console.error("SignalR connection closed with error: ", error);
      }
    });
    newConnection.on("InsertUserHistory", (updatedUserHistory) => {
      setAllUsersHistory((prevHistories) => {
        if (updatedUserHistory.historyType !== 1) {
          return prevHistories;
        }
        const existingIndex = prevHistories.findIndex(h => h._id === updatedUserHistory._id);
        if (existingIndex !== -1) {
          const newHistories = [...prevHistories];
          newHistories[existingIndex] = updatedUserHistory;
          return newHistories;
        } else {
          return [...prevHistories, updatedUserHistory];
        }
      });
    });
    newConnection.on("UpdateUserHistory", (updatedUserHistory) => {
      setAllUsersHistory((prevHistories) => {
        if (updatedUserHistory.historyType !== 1) {
          return prevHistories;
        }
        const existingIndex = prevHistories.findIndex(h => h._id === updatedUserHistory._id);
        if (existingIndex !== -1) {
          const newHistories = [...prevHistories];
          newHistories[existingIndex] = updatedUserHistory;
          return newHistories;
        } else {
          return [...prevHistories, updatedUserHistory];
        }
      });
    });
    newConnection.on("DeleteUserHistory", (deletedUserHistory) => {
      if (deletedUserHistory.historyType !== 1) {
        return;
      }
      setAllUsersHistory((prevHistories) => {
        const updatedHistories = prevHistories.filter(history => history._id !== deletedUserHistory._id);
        return updatedHistories;
      });
      if (deletedUserHistory.movieDetail?.title) {
        showInfoToast(`History for "${deletedUserHistory.movieDetail.title}" has been deleted.`);
      }
    });
  };

  // Kart tıklanınca
  const handleCardClick = (movie) => {
    setSelectedMovie(movie);
    setPopupVisible(true);
  };

  // Popup kapat
  const closePopup = () => {
    setPopupVisible(false);
    setSelectedMovie(null);
  };

  // Slider ayarları
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        }
      }
    ]
  };

  const renderSlider = () => {
    const sliderSettings = {
      ...settings,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      fade: true,
      arrows: !isMobile
    };
    return (
      <Slider {...sliderSettings}>
        {topMovies.map((movie) => (
          <div key={movie.id}>
            <SliderContent
              movieId={movie.id}
              postRequestSyncPromise={postRequestSyncPromise}
              allUsersHistory={allUsersHistory}
              isMobile={isMobile}
            />
          </div>
        ))}
      </Slider>
    );
  };

  const renderMovieCards = () => {
    if (isMobile) {
      return (
        <div className="mobile-shows-container">
          <div className="mobile-shows-scroll">
            {searchedMovieDataSource.map(movie => (
              <MovieCard
                key={movie.id}
                movie={movie}
                onClick={handleCardClick}
                isMobile={true}
              />
            ))}
          </div>
        </div>
      );
    }

    return (
      <div className="movie-list">
        {searchedMovieDataSource.map(movie => (
          <MovieCard
            key={movie.id}
            movie={movie}
            onClick={handleCardClick}
            isMobile={false}
          />
        ))}
      </div>
    );
  };

  // Masaüstü versiyonu
  const renderDesktopVersion = () => {
    return (
      <div>
        <div className="search-bar">
          <div className="control-row">
            <input
              type="text"
              value={movieSearchTextboxValue}
              onChange={(e) => setMovieSearchTextboxValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setIsSearchActive(true);
                  SearchMovie(1);
                }
              }}
              placeholder="Search Movie"
              className="movie-search-input"
            />
            <button
              className="kaminari-button"
              onClick={() => {
                setIsSearchActive(true);
                SearchMovie(1);
              }}
              aria-label="Search Movie"
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
            <button
              className="kaminari-button"
              onClick={() => setIsHistoryPopupVisible(true)}
              aria-label="User History"
            >
              <FontAwesomeIcon icon={faHistory} />
            </button>
          </div>
          <div className="control-row">
            <GenreSelector
              allMovieGenres={allMovieGenres}
              selectedGenres={selectedGenres}
              setSelectedGenres={setSelectedGenres}
              isMobile={false}
            />
            <FilterTypeSelect
              filterType={filterType}
              setFilterType={setFilterType}
              isMobile={false}
            />
            <button
              className="kaminari-button"
              onClick={() => {
                setIsSearchActive(false);
                setMovieSearchTextboxValue("");
                DiscoverMovie(1);
              }}
              aria-label="Discover Movie"
            >
              <FontAwesomeIcon icon={faFilm} />
            </button>
          </div>
        </div>

        <div className="movie-section">
          <h2 className="section-title">Top 10 Popular Movies</h2>
          {renderSlider()}
        </div>

        <div className="movie-section">
          <h2 className="section-title">
            {isSearchActive ? "Search Results" : "Discover Movies"}
          </h2>
          {renderMovieCards()}
        </div>
      </div>
    );
  };

  // Mobil versiyonu
  const renderMobileVersion = () => {
    return (
      <div className="mobile-container">
        {/* Floating Search Button */}
        <button
          className="mobile-search-fab"
          onClick={() => setIsSearchVisible(!isSearchVisible)}
        >
          <FontAwesomeIcon icon={isSearchVisible ? faTimes : faSearch} />
        </button>

        {/* Collapsible Search Panel */}
        <div className={`mobile-search-panel ${isSearchVisible ? 'visible' : ''}`}>
          <div className="search-bar-mobile">
            <div className="control-row-mobile">
              <input
                type="text"
                value={movieSearchTextboxValue}
                onChange={(e) => setMovieSearchTextboxValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setIsSearchActive(true);
                    SearchMovie(1);
                    setIsSearchVisible(false);
                  }
                }}
                placeholder="Search Movie"
                className="movie-search-input-mobile"
              />
              <button
                className="kaminari-button-mobile"
                onClick={() => {
                  setIsSearchActive(true);
                  SearchMovie(1);
                  setIsSearchVisible(false);
                }}
                aria-label="Search Movie"
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
              <button
                className="kaminari-button-mobile"
                onClick={() => {
                  setIsHistoryPopupVisible(true);
                  setIsSearchVisible(false);
                }}
                aria-label="User History"
              >
                <FontAwesomeIcon icon={faHistory} />
              </button>
            </div>
            <div className="control-row-mobile">
              <GenreSelector
                allMovieGenres={allMovieGenres}
                selectedGenres={selectedGenres}
                setSelectedGenres={setSelectedGenres}
                isMobile={true}
              />
              <FilterTypeSelect
                filterType={filterType}
                setFilterType={setFilterType}
                isMobile={true}
              />
              <button
                className="kaminari-button-mobile"
                onClick={() => {
                  setIsSearchActive(false);
                  setMovieSearchTextboxValue("");
                  DiscoverMovie(1);
                  setIsSearchVisible(false);
                }}
                aria-label="Discover Movies"
              >
                <FontAwesomeIcon icon={faFilm} />
              </button>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="mobile-content">
          {/* Top Movies Slider */}
          <section className="mobile-section">
            <h2 className="mobile-section-title">
              <span>Top 10 Popular Movies</span>
            </h2>
            {renderSlider()}
          </section>

          {/* Search Results or Discover */}
          <section className="mobile-section">
            <h2 className="mobile-section-title">
              <span>{isSearchActive ? "Search Results" : "Discover Movies"}</span>
              {searchedMovieDataSource.length > 0 && isSearchActive && (
                <span className="results-count">
                  ({searchedMovieDataSource.length} results)
                </span>
              )}
            </h2>
            {renderMovieCards()}
          </section>
        </div>
      </div>
    );
  };

  // Genel render
  const renderPageContent = () => {
    return isMobile ? renderMobileVersion() : renderDesktopVersion();
  };

  return (
    <div className={isMobile ? "movie-container-mobile" : "movie-container"}>
      <ToastContainer position='bottom-center' />
      {renderPageContent()}

      <div className={isMobile ? "pagination-container-mobile" : "pagination-container"}>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
          nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
          breakLabel={'•'}
          breakClassName={'break-me'}
          pageCount={pageCountInfo}
          onPageChange={handlePageClick}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          containerClassName={'pagination-mobile'}
          activeClassName={'active'}
          forcePage={currentPage - 1}
          pageClassName={'page-item-mobile'}
          pageLinkClassName={'page-link-mobile'}
          previousClassName={'prev-mobile'}
          nextClassName={'next-mobile'}
          previousLinkClassName={'nav-link-mobile'}
          nextLinkClassName={'nav-link-mobile'}
          disabledClassName={'disabled-mobile'}
        />
      </div>

      {isPopupVisible && selectedMovie && (
        <MovieDetailTab
          data={selectedMovie.id}
          onClose={closePopup}
          allUsersHistory={allUsersHistory}
          setAllUsersHistory={setAllUsersHistory}
          isMobile={isMobile}
        />
      )}
      {isHistoryPopupVisible && (
        <UserHistoryPopup
          isOpen={isHistoryPopupVisible}
          toggle={() => setIsHistoryPopupVisible(false)}
          allUserNames={allUserNames}
          allUsersHistory={allUsersHistory}
          setPopupVisible={setPopupVisible}
          setSelectedMovie={setSelectedMovie}
          postRequestSyncPromise={postRequestSyncPromise}
          setAllUsersHistory={setAllUsersHistory}
          isMobile={isMobile}
        />
      )}
    </div>
  );
}