import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { usePostRequestSyncPromise } from "../../global/GlobalFetch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import './Notes.css';
import useIsMobile from '../../components/mobile/IsMobile';

export default function Notes() {
    const {isMobile} = useIsMobile();
    const [userNotesDataSource, setUserNotesDataSource] = useState([]);
    const [showInsertModal, setShowInsertModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedNote, setSelectedNote] = useState(null);
    const [noteTitle, setNoteTitle] = useState("Yeni Not");
    const [noteContent, setNoteContent] = useState('');
    const postRequestSyncPromise = usePostRequestSyncPromise();

    const quillModules = {
        toolbar: [
            [{ 'font': [] }, { 'size': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'header': [1, 2, 3, false] }, { 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
    };

    const FillDataGridDataSource = () => {
        const userName = localStorage.getItem("userCode");

        const userNameRequestObject = {
            userName: userName
        };

        const baseRequest = {
            RequestId: "unique_request_id",
            Sender: "client_create_note_request",
            Data: [userNameRequestObject]
        };

        postRequestSyncPromise("Notes/GetUserNotesForUserName", baseRequest)
            .then(data => {
                setUserNotesDataSource(data.data);
            })
            .catch(error => {
                console.error(error);
            });
    };

    useEffect(() => {
        FillDataGridDataSource();
    }, []);

    const handleInsertNote = () => {
        const userName = localStorage.getItem("userCode");

        const noteObject = {
            userName: userName,
            note: noteContent,
            createdDate: new Date(),
            noteTitle: noteTitle
        };

        const baseRequest = {
            RequestId: "unique_request_id",
            Sender: "client_create_note_request",
            Data: [noteObject]
        };

        postRequestSyncPromise("Notes/CreateAUserNote", baseRequest)
            .then(() => {
                FillDataGridDataSource();
                setShowInsertModal(false);
                setNoteContent('');
                setNoteTitle('Yeni Not');
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handleUpdateNote = () => {
        const userName = localStorage.getItem("userCode");

        const noteObject = {
            _id: selectedNote._id,
            userName: userName,
            note: noteContent,
            createdDate: new Date(),
            noteTitle: noteTitle
        };

        const baseRequest = {
            RequestId: "unique_request_id",
            Sender: "client_create_note_request",
            Data: [noteObject]
        };

        postRequestSyncPromise("Notes/UpdateUserNote", baseRequest)
            .then(() => {
                FillDataGridDataSource();
                setShowEditModal(false);
                setNoteContent('');
                setNoteTitle('Yeni Not');
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handleDeleteNote = () => {
        const deleteRequest = {
            noteId: selectedNote._id
        };

        const baseRequest = {
            RequestId: "unique_request_id",
            Sender: "client_create_note_request",
            Data: [deleteRequest]
        };

        postRequestSyncPromise("Notes/DeleteUserNote", baseRequest)
            .then(() => {
                FillDataGridDataSource();
                setShowDeleteModal(false);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handleOpenInsertModal = () => {
        setNoteTitle('Yeni Not');
        setNoteContent('');
        setShowInsertModal(true);
    };

    // Masaüstü sürümü (Desktop) için render
    const renderDesktopLayout = () => {
        return (
            <div className="notes-container desktop">
                <div className="notes-header">
                    <h2 className="neon-text">Notlarım</h2>
                    <button className="kaminari-button" onClick={handleOpenInsertModal}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
                <div className="notes-list">
                    {userNotesDataSource.map(note => (
                        <div key={note._id} className="note-item">
                            <h3 title={note.noteTitle} className="note-title">{note.noteTitle}</h3>
                            <div
                                className="note-preview"
                                dangerouslySetInnerHTML={{
                                    __html: (() => {
                                        const previewText = note.note.slice(0, 500);
                                        const hasImage = previewText.includes("<img");

                                        if (hasImage) {
                                            const imgStartIndex = note.note.indexOf("<img");
                                            const imgEndIndex = note.note.indexOf(">", imgStartIndex) + 1;
                                            const imgTag = note.note.slice(imgStartIndex, imgEndIndex);
                                            return imgTag + (note.note.length > 500 ? '...' : '');
                                        } else {
                                            return previewText + (note.note.length > 500 ? '...' : '');
                                        }
                                    })()
                                }}
                            />
                            <div className="note-actions">
                                <button
                                    className="kaminari-button"
                                    onClick={() => {
                                        setSelectedNote(note);
                                        setNoteTitle(note.noteTitle);
                                        setNoteContent(note.note);
                                        setShowEditModal(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </button>
                                <button
                                    className="kaminari-button kaminari-button-danger"
                                    onClick={() => {
                                        setSelectedNote(note);
                                        setShowDeleteModal(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                {showInsertModal && (
                    <div className="modal-overlay">
                        <div className="modal-content-notes">
                            <h2>Yeni Not Oluştur</h2>
                            <input
                                type="text"
                                value={noteTitle}
                                onChange={(e) => setNoteTitle(e.target.value)}
                                placeholder="Not Başlığı"
                                className="modal-input"
                            />
                            <div className="editor-container" onWheel={(e) => e.stopPropagation()}>
                                <ReactQuill
                                    theme="snow"
                                    value={noteContent}
                                    onChange={setNoteContent}
                                    className="modal-editor"
                                    modules={quillModules}
                                />
                            </div>
                            <div className="modal-actions">
                                <button className="kaminari-button" onClick={handleInsertNote}>
                                    <FontAwesomeIcon icon={faSave} />
                                </button>
                                <button className="kaminari-button" onClick={() => setShowInsertModal(false)}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {showEditModal && (
                    <div className="modal-overlay">
                        <div className="modal-content-notes">
                            <h2>Notu Düzenle</h2>
                            <input
                                type="text"
                                value={noteTitle}
                                onChange={(e) => setNoteTitle(e.target.value)}
                                placeholder="Not Başlığı"
                                className="modal-input"
                            />
                            <div className="editor-container" onWheel={(e) => e.stopPropagation()}>
                                <ReactQuill
                                    theme="snow"
                                    value={noteContent}
                                    onChange={setNoteContent}
                                    className="modal-editor"
                                    modules={quillModules}
                                />
                            </div>
                            <div className="modal-actions">
                                <button className="kaminari-button" onClick={handleUpdateNote}>
                                    <FontAwesomeIcon icon={faSave} />
                                </button>
                                <button className="kaminari-button" onClick={() => setShowEditModal(false)}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {showDeleteModal && (
                    <div className="modal-overlay">
                        <div className="modal-content-notes delete-modal">
                            <h2>Notu Sil</h2>
                            <p>Bu notu silmek istediğinizden emin misiniz?</p>
                            <div className="modal-actions">
                                <button className="kaminari-button kaminari-button-danger" onClick={handleDeleteNote}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                                <button className="kaminari-button" onClick={() => setShowDeleteModal(false)}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    // Mobil sürümü (Mobile) için render
    const renderMobileLayout = () => {
        return (
            <div className="notes-container mobile">
                <div className="notes-header">
                    <h2 className="neon-text">Notes</h2>
                    <button className="kaminari-button" onClick={handleOpenInsertModal}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>

                {/* 
          Mobilde genellikle liste görünümü üst üste gelir. 
          Daha kompakt bir düzen için item'ları tek kolon yapıyoruz. 
        */}
                <div className="notes-list-mobile">
                    {userNotesDataSource.map(note => (
                        <div key={note._id} className="note-item-mobile">
                            <div className="note-header-mobile">
                                <h3 title={note.noteTitle} className="note-title-mobile">{note.noteTitle}</h3>
                                <div className="note-actions-mobile">
                                    <button
                                        className="kaminari-button"
                                        onClick={() => {
                                            setSelectedNote(note);
                                            setNoteTitle(note.noteTitle);
                                            setNoteContent(note.note);
                                            setShowEditModal(true);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                    <button
                                        className="kaminari-button kaminari-button-danger"
                                        onClick={() => {
                                            setSelectedNote(note);
                                            setShowDeleteModal(true);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            </div>
                            <div
                                className="note-preview-mobile"
                                dangerouslySetInnerHTML={{
                                    __html: (() => {
                                        const previewText = note.note.slice(0, 200);
                                        const hasImage = previewText.includes("<img");

                                        if (hasImage) {
                                            const imgStartIndex = note.note.indexOf("<img");
                                            const imgEndIndex = note.note.indexOf(">", imgStartIndex) + 1;
                                            const imgTag = note.note.slice(imgStartIndex, imgEndIndex);
                                            return imgTag + (note.note.length > 200 ? '...' : '');
                                        } else {
                                            return previewText + (note.note.length > 200 ? '...' : '');
                                        }
                                    })()
                                }}
                            />
                        </div>
                    ))}
                </div>

                {showInsertModal && (
                    <div className="modal-overlay">
                        <div className="modal-content-notes modal-content-notes-mobile">
                            <h2>Yeni Not</h2>
                            <input
                                type="text"
                                value={noteTitle}
                                onChange={(e) => setNoteTitle(e.target.value)}
                                placeholder="Not Başlığı"
                                className="modal-input"
                            />
                            <div className="editor-container" onWheel={(e) => e.stopPropagation()}>
                                <ReactQuill
                                    theme="snow"
                                    value={noteContent}
                                    onChange={setNoteContent}
                                    className="modal-editor"
                                    modules={quillModules}
                                />
                            </div>
                            <div className="modal-actions">
                                <button className="kaminari-button" onClick={handleInsertNote}>
                                    <FontAwesomeIcon icon={faSave} />
                                </button>
                                <button className="kaminari-button" onClick={() => setShowInsertModal(false)}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {showEditModal && (
                    <div className="modal-overlay">
                        <div className="modal-content-notes modal-content-notes-mobile">
                            <h2>Notu Düzenle</h2>
                            <input
                                type="text"
                                value={noteTitle}
                                onChange={(e) => setNoteTitle(e.target.value)}
                                placeholder="Not Başlığı"
                                className="modal-input"
                            />
                            <div className="editor-container" onWheel={(e) => e.stopPropagation()}>
                                <ReactQuill
                                    theme="snow"
                                    value={noteContent}
                                    onChange={setNoteContent}
                                    className="modal-editor"
                                    modules={quillModules}
                                />
                            </div>
                            <div className="modal-actions">
                                <button className="kaminari-button" onClick={handleUpdateNote}>
                                    <FontAwesomeIcon icon={faSave} />
                                </button>
                                <button className="kaminari-button" onClick={() => setShowEditModal(false)}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {showDeleteModal && (
                    <div className="modal-overlay">
                        <div className="modal-content-notes delete-modal">
                            <h2>Notu Sil</h2>
                            <p>Bu notu silmek istediğinizden emin misiniz?</p>
                            <div className="modal-actions">
                                <button className="kaminari-button kaminari-button-danger" onClick={handleDeleteNote}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                                <button className="kaminari-button" onClick={() => setShowDeleteModal(false)}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };
    return isMobile ? renderMobileLayout() : renderDesktopLayout();
}