import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faUpload,
  faKey,
  faImage,
  faTimes,
  faLock,
  faUserEdit,
  faFileImport,
  faCloudUploadAlt,
  faEnvelope,
  faCheckCircle,
  faTimesCircle,
  faExclamationTriangle,
  faInfoCircle,
  faArrowLeft,
  faCamera,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Settings.scss';
import { usePostRequestSyncPromise } from '../../global/GlobalFetch';
import { parseString } from 'xml2js';
import useIsMobile from '../mobile/IsMobile';

Modal.setAppElement('#root');

const showSuccessToast = (message) => {
  toast.dark(
    <div>
      <FontAwesomeIcon icon={faCheckCircle} />
      <span style={{ marginLeft: 8 }}>{message}</span>
    </div>
  );
};

const showErrorToast = (message) => {
  toast.dark(
    <div>
      <FontAwesomeIcon icon={faTimesCircle} />
      <span style={{ marginLeft: 8 }}>{message}</span>
    </div>
  );
};

const showWarningToast = (message) => {
  toast.dark(
    <div>
      <FontAwesomeIcon icon={faExclamationTriangle} />
      <span style={{ marginLeft: 8 }}>{message}</span>
    </div>
  );
};

const showInfoToast = (message) => {
  toast.dark(
    <div>
      <FontAwesomeIcon icon={faInfoCircle} />
      <span style={{ marginLeft: 8 }}>{message}</span>
    </div>
  );
};

export default function UserSettings({ isOpen, toggle }) {
  const { isMobile } = useIsMobile();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newUserName, setNewUserName] = useState('');
  const [newEmail, setNewEmail] = useState('');

  const profileImageInputRef = useRef(null);
  const malAnimeInputRef = useRef(null);

  const postRequestSyncPromise = usePostRequestSyncPromise();

  const openProfileImageSelector = () => {
    profileImageInputRef.current.click();
  };

  const openMalAnimeSelector = () => {
    malAnimeInputRef.current.click();
  };

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Image = reader.result.split(',')[1];
        const userCode = localStorage.getItem("userCode");

        const requestData = {
          data: [
            {
              Base64ImageString: base64Image,
              UserName: userCode,
            }
          ]
        };

        postRequestSyncPromise("Auth/ChangeUserImageAsync", requestData)
          .then(response => {
            if (response.type === 0) {
              showSuccessToast("Profile image updated successfully");
            } else {
              showErrorToast("Failed to update profile image : " + response.definitionLang);
            }
          })
          .catch(error => console.error("Error:", error));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangePassword = () => {
    let passwordInfo = {
      currentPassword: currentPassword,
      newPassword: newPassword,
      userName: localStorage.getItem("userCode")
    };
    postRequestSyncPromise("Auth/ChangePassword", passwordInfo)
      .then(data => {
        if (data.type === 0) {
          showSuccessToast("Password changed successfully");
          setNewPassword('');
          setCurrentPassword('');
        }
        else {
          showErrorToast("Failed to change password : " + data.definitionLang);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleChangeUserName = () => {
    let userNameInfo = {
      userId: localStorage.getItem("userId"),
      currentUserName: localStorage.getItem("userCode"),
      newUserName: newUserName
    };
    postRequestSyncPromise("Auth/ChangeUserName", userNameInfo)
      .then(data => {
        if (data.type === 0) {
          showSuccessToast("User Name changed successfully");
          let userProfile = JSON.parse(localStorage.getItem("userData"));
          userProfile.userName = newUserName;

          localStorage.setItem("userData", JSON.stringify(userProfile));
          localStorage.setItem("userCode", newUserName);

          setNewPassword('');
          setCurrentPassword('');
          setNewUserName('');
        } else {
          showErrorToast("Failed to change user name : " + data.definitionLang);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        parseString(e.target.result, { explicitArray: false }, (err, result) => {
          if (err) {
            showErrorToast("Error parsing XML");
            console.error(err);
          } else {
            showInfoToast("Uploading anime list to server");
            sendDataToServer(result.myanimelist);
          }
        });
      };
      reader.readAsText(file);
    }
  };

  const sendDataToServer = (myAnimeListData) => {
    const formattedData = {
      myanimelist: myAnimeListData,
      username: localStorage.getItem("userCode"),
      userId: localStorage.getItem("userId"),
    };
    postRequestSyncPromise("UserAnimeProfile/FetchMyAnimeList", formattedData)
      .then(data => {
        if (data.type === 0) {
          showSuccessToast("Anime list successfully uploaded");
        } else {
          showErrorToast("Failed to upload anime list: " + data.definitionLang);
        }
      })
      .catch(error => {
        console.error("Error uploading anime list:", error);
        showErrorToast("Error uploading anime list");
      });
  };

  const handleChangeEmail = () => {
    const emailInfo = {
      userName: localStorage.getItem("userCode"),
      newMail: newEmail,
    };

    const baseRequest = {
      data: [emailInfo],
    }
    postRequestSyncPromise("Auth/ChangeUserMailAsync", baseRequest)
      .then((data) => {
        if (data.type === 0) {
          showSuccessToast("Email address changed successfully");
          setNewEmail('');
        } else {
          showErrorToast("Failed to change email address : " + data.definitionLang);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (isMobile) {
    return (
      <div>
        <ToastContainer position='bottom-center' />
        <Modal
          isOpen={isOpen}
          onRequestClose={toggle}
          className="mobile-settings"
          overlayClassName="mobile-settings-overlay"
          style={{
            overlay: {
              zIndex: 9998
            },
            content: {
              zIndex: 9999
            }
          }}
        >
          <div className="mobile-settings-container">
            {/* Header */}
            <div className="mobile-settings-header">
              <button className="back-button" onClick={toggle}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <h1>Settings</h1>
            </div>
  
            {/* Profile Section */}
            <div className="profile-section">
              <div className="avatar-container" onClick={openProfileImageSelector}>
                <img 
                  src={`data:image/png;base64,${JSON.parse(localStorage.getItem('userData')).avatarUrl}`}
                  alt="Profile" 
                  className="profile-avatar"
                />
                <div className="avatar-overlay">
                  <FontAwesomeIcon icon={faCamera} />
                </div>
              </div>
              <span className="profile-name">{localStorage.getItem('userCode')}</span>
            </div>
  
            {/* Settings List */}
            <div className="settings-list">
              {/* Account Section */}
              <div className="settings-group">
                <div className="group-header">Account</div>
                
                <div className="settings-item" onClick={() => document.getElementById('username-input').focus()}>
                  <div className="item-content">
                    <FontAwesomeIcon icon={faUser} className="item-icon" />
                    <div className="item-details">
                      <div className="item-label">Username</div>
                      <input
                        id="username-input"
                        type="text"
                        value={newUserName}
                        onChange={(e) => setNewUserName(e.target.value)}
                        placeholder={localStorage.getItem('userCode')}
                        className="settings-input"
                      />
                    </div>
                    <button 
                      className="action-button"
                      onClick={handleChangeUserName}
                      disabled={!newUserName}
                    >
                      Save
                    </button>
                  </div>
                </div>
  
                <div className="settings-item" onClick={() => document.getElementById('email-input').focus()}>
                  <div className="item-content">
                    <FontAwesomeIcon icon={faEnvelope} className="item-icon" />
                    <div className="item-details">
                      <div className="item-label">Email</div>
                      <input
                        id="email-input"
                        type="email"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        placeholder="Update email"
                        className="settings-input"
                      />
                    </div>
                    <button 
                      className="action-button"
                      onClick={handleChangeEmail}
                      disabled={!newEmail}
                    >
                      Save
                    </button>
                  </div>
                </div>
  
                <div className="settings-item" onClick={() => document.getElementById('password-input').focus()}>
                  <div className="item-content">
                    <FontAwesomeIcon icon={faLock} className="item-icon" />
                    <div className="item-details">
                      <div className="item-label">Password</div>
                      <input
                        id="password-input"
                        type="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        placeholder="Current password"
                        className="settings-input"
                      />
                      <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="New password"
                        className="settings-input"
                      />
                    </div>
                    <button 
                      className="action-button"
                      onClick={handleChangePassword}
                      disabled={!currentPassword || !newPassword}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
  
              {/* MAL Integration */}
              <div className="settings-group">
                <div className="group-header">Integrations</div>
                <div className="settings-item" onClick={openMalAnimeSelector}>
                  <div className="item-content">
                    <FontAwesomeIcon icon={faFileImport} className="item-icon" />
                    <div className="item-details">
                      <div className="item-label">Import MAL List</div>
                      <div className="item-description">Import your MyAnimeList data</div>
                    </div>
                    <FontAwesomeIcon icon={faChevronRight} className="chevron-icon" />
                  </div>
                </div>
              </div>
            </div>
  
            {/* Hidden file inputs */}
            <input
              type="file"
              ref={profileImageInputRef}
              onChange={handleProfileImageChange}
              accept="image/*"
              style={{ display: 'none' }}
            />
            <input
              type="file"
              ref={malAnimeInputRef}
              onChange={handleFileChange}
              accept=".xml"
              style={{ display: 'none' }}
            />
          </div>
        </Modal>
      </div>
    );
  }
  else {
    return (
      <div>
        <ToastContainer position='bottom-center' />
        <Modal
          isOpen={isOpen}
          onRequestClose={toggle}
          className={{
            base: 'youtube-popup-profile updated-user-settings', // ek class
            afterOpen: 'youtube-popup-profile_open',
            beforeClose: 'youtube-popup-profile_close',
          }}
          overlayClassName={{
            base: 'modal-overlay',
            afterOpen: 'modal-overlay_open',
            beforeClose: 'modal-overlay_close',
          }}
          closeTimeoutMS={200}
        >
          <div className="modal-header">
            <h2 className="modal-title">User Settings</h2>
            <button className="close-button" onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>

          <div className="settings-container">
            {/* CHANGE PASSWORD */}
            <div className="settings-section">
              <div className="section-top">
                <h3 className="section-title">
                  <FontAwesomeIcon icon={faKey} /> Change Password
                </h3>
              </div>
              <div className="control-row">
                <input
                  type="password"
                  placeholder="Current Password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="kaminari-input"
                />
              </div>
              <div className="control-row">
                <input
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="kaminari-input"
                />
              </div>
              <div className="note-actions">
                <button
                  onClick={handleChangePassword}
                  className="kaminari-button"
                  title="Change Password"
                >
                  <FontAwesomeIcon icon={faLock} />
                </button>
              </div>
            </div>

            {/* CHANGE USERNAME */}
            <div className="settings-section">
              <div className="section-top">
                <h3 className="section-title">
                  <FontAwesomeIcon icon={faUser} /> Change User Name
                </h3>
              </div>
              <div className="control-row">
                <input
                  type="text"
                  placeholder="New User Name"
                  value={newUserName}
                  onChange={(e) => setNewUserName(e.target.value)}
                  className="kaminari-input"
                />
              </div>
              <div className="note-actions">
                <button
                  onClick={handleChangeUserName}
                  className="kaminari-button"
                  title="Change User Name"
                >
                  <FontAwesomeIcon icon={faUserEdit} />
                </button>
              </div>
            </div>

            {/* CHANGE EMAIL */}
            <div className="settings-section">
              <div className="section-top">
                <h3 className="section-title">
                  <FontAwesomeIcon icon={faEnvelope} /> Update Email Address
                </h3>
              </div>
              <div className="control-row">
                <input
                  type="email"
                  placeholder="New Email Address"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  className="kaminari-input"
                />
              </div>
              <div className="note-actions">
                <button
                  onClick={handleChangeEmail}
                  className="kaminari-button"
                  title="Update Email"
                >
                  <FontAwesomeIcon icon={faUserEdit} />
                </button>
              </div>
            </div>

            {/* MAL ANIME LIST */}
            <div className="settings-section">
              <div className="section-top">
                <h3 className="section-title">
                  <FontAwesomeIcon icon={faUpload} /> Get Your MAL Animes
                </h3>
              </div>
              <input
                type="file"
                style={{ display: 'none' }}
                ref={malAnimeInputRef}
                onChange={handleFileChange}
                accept=".xml"
              />
              <div className="note-actions">
                <button
                  onClick={openMalAnimeSelector}
                  className="kaminari-button"
                  title="Upload MAL XML"
                >
                  <FontAwesomeIcon icon={faFileImport} />
                </button>
              </div>
            </div>

            {/* CHANGE PROFILE IMAGE */}
            <div className="settings-section">
              <div className="section-top">
                <h3 className="section-title">
                  <FontAwesomeIcon icon={faImage} /> Change Profile Image
                </h3>
              </div>
              <input
                type="file"
                style={{ display: 'none' }}
                ref={profileImageInputRef}
                onChange={handleProfileImageChange}
                accept="image/*"
              />
              <div className="note-actions">
                <button
                  onClick={openProfileImageSelector}
                  className="kaminari-button"
                  title="Upload Profile Image"
                >
                  <FontAwesomeIcon icon={faCloudUploadAlt} />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}